import globalStyle from "index.style";

const useStyles = {
  selectableRow: {
    cursor: "pointer",
  },
  selectedRow: {
    border: "2px solid black",
  },
  wrapper: {
    background: "white",
    padding: 2,
    borderRadius: 2,
    boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 12,
    p: 4,
    button: {
      position: "absolute",
      top: 5,
      right: 5,
    },
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
    button: {
      fontSize: 12,
      textTransform: "inherit",
    },
  },
  filters: {
    width: 300,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
} as const;

export default { ...globalStyle, ...useStyles };
