import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Modal,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import style from "./index.style";
import useTurnkey from "./index.hooks";
import EditModalContent from "./EditModalContent";

type TurnkeyProps = {};

const Turnkey = memo(({}: TurnkeyProps) => {
  const {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    searchValue,
    handleChangeSearchValue,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    editingItem,
  } = useTurnkey();

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditModalContent
          item={editingItem}
          closeModal={handleCloseModal}
          getItems={getItems}
        />
      </Modal>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search"
              value={searchValue}
              onChange={handleChangeSearchValue}
              size="medium"
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>User Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Tax Id</TableCell>
                <TableCell>Vat Valid</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item) => (
                <TableRow key={item?._id}>
                  <TableCell>{item?.user?.email}</TableCell>
                  <TableCell>
                    {item?.celPrefix} - {item?.cel}
                  </TableCell>
                  <TableCell>{item?.type}</TableCell>
                  <TableCell>{item?.piva ? item?.piva : "---"}</TableCell>
                  <TableCell>{item?.validVat ? "Valid" : "Invalid"}</TableCell>
                  <TableCell>
                    {item?.addressee} - {item?.city} {item?.street} -{" "}
                    {item?.cap}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleOpenModal(item)}
                      >
                        <EditIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default Turnkey;
