import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import request from "redux/slices/ajax/request";

const initialState = {
  cronId: "",
  httpCode: "",
  startDate: null,
  endDate: null,
  url: "",
};

type UseCronJobsProps = {
  cronId: string;
  resetCronId: () => void;
};

const useCronJobs = ({ cronId, resetCronId }: UseCronJobsProps) => {
  const [page, setPage] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [newCron, setNewCron] = useState(false);
  const [shouldFilter, setShouldFilter] = useState(true);
  const filtersReducer = (state: any, action: any) => {
    switch (action.type) {
      case "cronId":
        return { ...state, cronId: action.payload };
      case "httpCode":
        return { ...state, httpCode: action.payload };
      case "url":
        return { ...state, url: action.payload };
      case "startDate":
        return { ...state, startDate: action.payload };
      case "endDate":
        return { ...state, endDate: action.payload[1] };
      case "reset":
        return initialState;
      default:
        return { [action.type]: action.payload };
    }
  };

  const [filters, dispatchFilters] = useReducer(filtersReducer, initialState);

  const handleFiltersModal = useCallback(() => {
    setFiltersModalOpen((prev) => !prev);
  }, []);

  const getItems = useCallback(async () => {
    let response: any = {};
    const q: any = {};
    if (filters.cronId) {
      q.cronId = filters.cronId;
    }
    if (filters.httpCode) {
      q.httpCode = filters.httpCode;
    }
    if (filters.url) {
      q.url = filters.url;
    }
    const date: any = {};

    if (filters?.startDate) {
      date.$gte = filters.startDate.toISOString();
    }

    if (filters?.endDate) {
      date.$lte = filters.endDate.toISOString();
    }

    if (date.$lte && date.$gte) {
      q.date = date;
    }
    response = await request({
      path: `${ENDPOINTS.userCronLogs}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q,
        sortings: {
          reqTime: -1,
        },
      },
    });
    setItems(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [
    filters.cronId,
    filters.endDate,
    filters.httpCode,
    filters.startDate,
    filters.url,
    page,
    pageSize,
  ]);

  useEffect(() => {
    if (cronId) {
      dispatchFilters({ type: "cronId", payload: cronId });
      resetCronId();
    }
  }, [cronId, getItems, resetCronId]);

  useEffect(() => {
    if (shouldFilter) {
      getItems().then();
    }
  }, [getItems, shouldFilter]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetFilters = useCallback(() => {
    dispatchFilters({ type: "reset" });
  }, []);

  return {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    handleFiltersModal,
    filtersModalOpen,
    filters,
    dispatchFilters,
    resetFilters,
    newCron,
    setNewCron,
    setShouldFilter,
  };
};

export default useCronJobs;
