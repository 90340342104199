import React, { memo } from "react";
import { Editor } from "react-draft-wysiwyg";
import CustomTabs from "components/CustomTabs";
import { Box, Button, Paper, Typography } from "@mui/material";
import usePlansDescription from "./index.hooks";
import style from "./index.style";

type PlansDescriptionProps = {
  model: any;
  descriptionEditorState: any;
  setDescriptionEditorState: any;
  secondDescriptionEditorState: any;
  setSecondDescriptionEditorState: any;
  thirdDescriptionEditorState: any;
  setThirdDescriptionEditorState: any;
  language: string;
  setLanguage: any;
  onSubmit: any;
};

const PlansDescription = memo(
  ({
    model,
    descriptionEditorState,
    setDescriptionEditorState,
    secondDescriptionEditorState,
    setSecondDescriptionEditorState,
    thirdDescriptionEditorState,
    setThirdDescriptionEditorState,
    language,
    setLanguage,
    onSubmit,
  }: PlansDescriptionProps) => {
    const {
      createMarkup,
      tab,
      handleChange,
      TABS,
      updateTextDescription,
      updateTextSecondDescription,
      updateTextThirdDescription,
    } = usePlansDescription({
      model,
      language,
      setDescriptionEditorState,
      setSecondDescriptionEditorState,
      setThirdDescriptionEditorState,
      setLanguage,
    });
    return (
      <Paper elevation={2} sx={{ p: 2 }}>
        <div>
          <Typography>
            <h6>Default value</h6>
            <div dangerouslySetInnerHTML={createMarkup} />
          </Typography>
          <div>
            <div>
              <CustomTabs
                value={tab}
                handleChange={handleChange}
                tabs={TABS}
                items={Object.keys(TABS).map((tabLanguage) => (
                  <Box>
                    <Box sx={style.wrapperClassName}>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={updateTextDescription}
                        editorState={descriptionEditorState}
                      />
                    </Box>
                    <Box sx={style.wrapperClassName}>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={updateTextSecondDescription}
                        editorState={secondDescriptionEditorState}
                      />
                    </Box>
                    <Box sx={style.wrapperClassName}>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={updateTextThirdDescription}
                        editorState={thirdDescriptionEditorState}
                      />
                    </Box>
                  </Box>
                ))}
              />
              <Button variant="contained" size="large" onClick={onSubmit}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
);

export default PlansDescription;
