import globalStyle from "index.style";

const useStyles = {
  wrapperClassName: {
    border: "solid thin #92a0ae",
    mb: "10px",
  },
} as const;

export default { ...globalStyle, ...useStyles };
