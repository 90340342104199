import React, { memo } from "react";
import CustomTabs from "components/CustomTabs";
import useVatManagement from "./index.hooks";
import VatRules from "./VatRules";
import VatSystems from "./VatSystems";
import Platforms from "./Platforms";

type VatManagementProps = {};

const VatManagement = memo(({}: VatManagementProps) => {
  const {
    tabIndex,
    handleChange,
    vatRulesPage,
    vatSystemsPage,
    platformsPage,
    vatRulesPageSize,
    searchPlatform,
    searchVat,
    vatRules,
    vatSystems,
    platforms,
    totalNumberOfVatRulesElements,
    totalNumberOfVatSystemsElements,
    totalNumberOfPlatformsElements,
    setVatRulesPage,
    setVatSystemsPage,
    setPlatformsPage,
    setVatRulesPageSize,
    getVatRules,
    getVatSystems,
    getPlatforms,
    setSearchPlatform,
    setSearchVat,
  } = useVatManagement();

  return (
    <CustomTabs
      value={tabIndex}
      handleChange={handleChange}
      tabs={["Vat Rules", "Vat Systems", "Platforms"]}
      items={[
        <VatRules
          page={vatRulesPage}
          setPage={setVatRulesPage}
          pageSize={vatRulesPageSize}
          setPageSize={setVatRulesPageSize}
          vatRules={vatRules}
          vatSystems={vatSystems}
          platforms={platforms}
          totalNumberOfElements={totalNumberOfVatRulesElements}
          getVatRules={getVatRules}
          searchPlatform={searchPlatform}
          searchVat={searchVat}
          setSearchPlatform={setSearchPlatform}
          setSearchVat={setSearchVat}
        />,
        <VatSystems />,
        <Platforms />,
      ]}
    />
  );
});

export default VatManagement;
