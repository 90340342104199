import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import request from "redux/slices/ajax/request";
import { sub } from "date-fns";
import { useSelector } from "react-redux";
import { useDebounce } from "../../utils/useDebounce";
import { TURNKEY_STATUSES } from "../../constants/contants";
import { getCountries } from "../../redux/slices/platform-data/platform-data.selectors";

const useTurnkey = () => {
  const [page, setPage] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const today = new Date();
  const lastYear = sub(today, { years: 1 });
  const [purchaseStartDate, setPurchaseStartDate] = useState<Date>(lastYear);
  const [purchaseEndDate, setPurchaseEndDate] = useState<Date>(today);
  const [updatedStartDate, setUpdatedStartDate] = useState<Date>(lastYear);
  const [updatedEndDate, setUpdatedEndDate] = useState<Date>(today);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTextValue = useDebounce(searchValue, 500);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<any>();
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingItem(null);
  }, []);
  const [searchStatusValue, setSearchStatusValue] = useState("All");
  const [searchCountryValue, setSearchCountryValue] = useState("All");
  const countries = useSelector(getCountries);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoItem, setInfoItem] = useState<any>(null);

  const handleInfoModal = (item: any) => {
    setInfoItem(item);
    setInfoModalOpen((prev) => !prev);
  };

  const getSelectCountries = useMemo(
    () =>
      countries
        ?.map((country: any) => ({
          countryName: country?.country,
          countryCode: country?.countrycode,
        }))
        .sort((a: any, b: any) => {
          if (a.countryName > b.countryName) return 1;
          if (a.countryName < b.countryName) return -1;
          return 0;
        }),
    [countries]
  );

  const getSelectCountriesValues = () => {
    const countries = [...getSelectCountries];
    countries.unshift({
      countryName: "All",
      countryCode: "All",
    });
    return countries;
  };

  const getSelectStatusValues = () => {
    const statuses = [...TURNKEY_STATUSES];
    statuses.unshift("All");
    return statuses;
  };

  const handleOpenModal = useCallback((category?: any) => {
    setEditingItem(category);
    setModalOpen((prevState) => !prevState);
  }, []);

  const getItems = useCallback(async () => {
    let response: any = {};
    let $or;
    if (debouncedSearchTextValue?.length >= 1) {
      $or = {
        email: { $regex: debouncedSearchTextValue },
      };
    }
    if (searchStatusValue !== "All") {
      $or = {
        ...$or,
        status: searchStatusValue,
      };
    }
    if (searchCountryValue !== "All") {
      $or = {
        ...$or,
        country_code: searchCountryValue,
      };
    }
    response = await request({
      path: `${ENDPOINTS.turnkey}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q: {
          ...$or,
          createdAt: {
            $gt: purchaseStartDate.toISOString(),
            $lt: purchaseEndDate.toISOString(),
          },
          updatedAt: {
            $gt: updatedStartDate.toISOString(),
            $lt: updatedEndDate.toISOString(),
          },
        },
        sortings: {
          createdAt: -1,
        },
        populate: [{ path: "user", select: ["email", "username"] }],
      },
    });
    setItems(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [
    debouncedSearchTextValue,
    searchStatusValue,
    searchCountryValue,
    page,
    pageSize,
    purchaseStartDate,
    purchaseEndDate,
    updatedStartDate,
    updatedEndDate,
  ]);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    getItems().then();
  }, [getItems]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangePurchaseDate = useCallback((newDate) => {
    setPurchaseStartDate(newDate[0]);
    setPurchaseEndDate(newDate[1]);
  }, []);

  const onChangeUpdatedDate = useCallback((newDate) => {
    setUpdatedStartDate(newDate[0]);
    setUpdatedEndDate(newDate[1]);
  }, []);

  const handleChangeSelectStatusValue = (value: string) => {
    setSearchStatusValue(value);
  };

  const handleChangeSelectCountryValue = (value: string) => {
    setSearchCountryValue(value);
  };

  return {
    page,
    searchValue,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    handleChangeSearchValue,
    purchaseStartDate,
    purchaseEndDate,
    onChangePurchaseDate,
    updatedStartDate,
    updatedEndDate,
    onChangeUpdatedDate,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    editingItem,
    searchStatusValue,
    handleChangeSelectStatusValue,
    getSelectStatusValues,
    getSelectCountries,
    searchCountryValue,
    handleChangeSelectCountryValue,
    getSelectCountriesValues,
    handleInfoModal,
    infoModalOpen,
    infoItem,
  };
};

export default useTurnkey;
