import React, { memo, useCallback, useEffect, useState } from "react";
import {
  getSidebarMain,
  getSidebarId,
  getSidebarOther,
} from "redux/slices/platform-data/platform-data.selectors";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import style from "./index.style";
import request, { HttpMethod } from "../../redux/slices/ajax/request";
import ENDPOINTS from "../../constants/endpoints";
import { actions } from "../../redux/slices";
import SidebarMain from "./SidebarMain";
import SidebarOther from "./SidebarOther";

const SidebarManagement = memo(({}) => {
  const dispatch = useDispatch();
  const sidebarMain = useSelector(getSidebarMain);
  const sidebarOther = useSelector(getSidebarOther);
  const sidebarId = useSelector(getSidebarId);
  const [tmpSidebarMain, setTmpSidebarMain] = useState<any>(null);
  const [tmpSidebarOther, setTmpSidebarOther] = useState<any>(null);

  useEffect(() => {
    if (sidebarMain && tmpSidebarMain == null) {
      setTmpSidebarMain([...sidebarMain]);
    }
  }, [sidebarMain, tmpSidebarMain]);

  useEffect(() => {
    if (sidebarOther && tmpSidebarOther === null) {
      setTmpSidebarOther([...sidebarOther]);
    }
  }, [sidebarOther, tmpSidebarOther]);

  const onSave = useCallback(async () => {
    const sidebar = {
      main: tmpSidebarMain,
      other: tmpSidebarOther,
    };
    try {
      await request({
        path: `${ENDPOINTS.settings}/${sidebarId}`,
        method: HttpMethod.PUT,
        body: {
          body: sidebar,
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
    } catch (e) {
      console.log(e);
    }
  }, [tmpSidebarMain, tmpSidebarOther, sidebarId, dispatch]);

  if (tmpSidebarMain === null || tmpSidebarOther === null) return null;

  return (
    <Box sx={{ paddingBottom: "100px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 2 }}
      >
        <Button variant="contained" size="large" onClick={onSave}>
          Salva
        </Button>
      </Box>
      <Box sx={style.background}>
        <SidebarMain sidebar={tmpSidebarMain} setSidebar={setTmpSidebarMain} />
      </Box>
      <Box sx={style.background}>
        <SidebarOther
          sidebar={tmpSidebarOther}
          setSidebar={setTmpSidebarOther}
        />
      </Box>
    </Box>
  );
});

export default SidebarManagement;
