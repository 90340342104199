import { createSlice } from "@reduxjs/toolkit";
import { SetUserAction, UserState } from "./user.interfaces";
import * as selectors from "./user.selectors";
import * as sagas from "./user.sagas";

export const userStore = createSlice({
  name: "user",
  initialState: {
    data: {},
  } as UserState,
  reducers: {
    setUser: (state: any, { payload }: SetUserAction) => {
      state.data = payload;
      return state;
    },
  },
});

export { selectors, sagas };
