import { useCallback, useState } from "react";

const useLogs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = useCallback((_, newValue: number) => {
    setTabIndex(newValue);
  }, []);
  return { tabIndex, handleChange };
};

export default useLogs;
