import { useCallback, useEffect, useMemo, useState } from "react";
import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";
import { useSelector } from "react-redux";
import { getCountries } from "../../../redux/slices/platform-data/platform-data.selectors";

type useEditModalContentProps = {
  item: any;
  getItems: any;
  closeModal: () => void;
};

const useEditModalContent = ({
  item,
  getItems,
  closeModal,
}: useEditModalContentProps) => {
  const [editingItem, setEditingItem] = useState<any>();
  const countries = useSelector(getCountries);

  useEffect(() => {
    setEditingItem(item);
  }, [item]);

  const handleChangeEmail = useCallback(
    (email: string) => {
      const tempItem = { ...editingItem };
      tempItem.user.email = email;
      setEditingItem(tempItem);
    },
    [editingItem]
  );

  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempItem = { ...editingItem };
      tempItem[key] = value;
      setEditingItem(tempItem);
    },
    [editingItem]
  );

  const updateItem = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.address}/${editingItem?._id}`,
      method: HttpMethod.PUT,
      body: {
        address_type: editingItem?.address_type,
        addressee: editingItem?.addressee,
        cap: editingItem?.cap,
        careof: editingItem?.careof,
        cel: editingItem?.cel,
        celPrefix: editingItem?.celPrefix,
        cfpiva: editingItem?.cfpiva,
        city: editingItem?.city,
        countryName: editingItem?.country.country,
        email: editingItem?.user?.email,
        number: editingItem?.number,
        prov: editingItem?.prov,
        street: editingItem?.street,
        type: editingItem?.type,
      },
    });
    if (response.status === 200) {
      // Apro feedback success
      closeModal();
    }
    await getItems();
  }, [
    closeModal,
    editingItem?._id,
    editingItem?.address_type,
    editingItem?.addressee,
    editingItem?.cap,
    editingItem?.careof,
    editingItem?.cel,
    editingItem?.celPrefix,
    editingItem?.cfpiva,
    editingItem?.city,
    editingItem?.country.country,
    editingItem?.number,
    editingItem?.prov,
    editingItem?.street,
    editingItem?.type,
    editingItem?.user?.email,
    getItems,
  ]);
  const getSelectCountries = useMemo(
    () =>
      countries?.map((country: any) => ({
        countryName: country?.country,
        countryCode: country?.countrycode,
      })),
    [countries]
  );
  const handleChangeSelectValue = useCallback(
    (value) => {
      const tempAddress = { ...editingItem };
      const newCountry = countries.find(
        (country: any) => country.countrycode === value
      );
      tempAddress.country = {
        country: newCountry.country,
        countrycode: newCountry.countrycode,
        _id: newCountry._id,
      };
      setEditingItem(tempAddress);
    },
    [countries, editingItem]
  );

  return {
    handleChangeValue,
    editingItem,
    updateItem,
    getSelectCountries,
    handleChangeSelectValue,
    handleChangeEmail,
  };
};

export default useEditModalContent;
