import globalStyle from "index.style";

const useStyles = {
  wrapper: {
    background: "white",
    padding: 1,
    borderRadius: 2,
    boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    marginBottom: "10px",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 12,
    p: 4,
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
    button: {
      fontSize: 12,
      textTransform: "inherit",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "20px",
    marginTop: "8px",
  },
  imageContent: {
    width: "100%",
  },
  brandTitleContainer: {
    marginBottom: "20px",
  },
} as const;

export default { ...globalStyle, ...useStyles };
