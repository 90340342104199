import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimedProductsBannerBody,
  getTimedProductsBannerId,
} from "redux/slices/platform-data/platform-data.selectors";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { actions } from "redux/slices";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateTimePicker } from "@mui/lab";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import style from "./index.style";
import { generateString } from "../../../utils";
import { LANGUAGES } from "../../../constants/contants";

function TimedProductsBanner() {
  const banners = useSelector(getTimedProductsBannerBody);
  const id = useSelector(getTimedProductsBannerId);
  const dispatch = useDispatch();
  const [editBanner, setEditBanner] = useState<[string, any]>([
    "",
    {
      status: true,
      link: "",
      banner: "",
      banner_mobile: "",
      title_it_IT: "",
      title_en_US: "",
      title_es_ES: "",
      title_fr_FR: "",
      colorTitle: "",
      subtitle_it_IT: "",
      subtitle_en_US: "",
      subtitle_es_ES: "",
      subtitle_fr_FR: "",
      colorSubtitle: "",
      bannerLink: false,
      bannerLink_it_IT: "",
      bannerLink_en_US: "",
      bannerLink_es_ES: "",
      bannerLink_fr_FR: "",
      align: "",
      content: "",
      startDate: new Date(),
      endDate: new Date(),
      timeZone: "+02:00",
      brand: "",
      canale: "",
      category: "",
      subcategory: "",
      sku: "",
      seoDescription: {},
    },
  ]);

  const containerRef = useRef(null);
  const [isNew, setIsNew] = useState(false);
  const [language, setLanguage] = useState("it_IT");
  const [descriptionEditorState, setDescriptionEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        // @ts-ignore
        htmlToDraft(editBanner[1]?.seoDescription?.[language] || "")
      )
    )
  );

  useEffect(() => {
    setDescriptionEditorState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          // @ts-ignore
          htmlToDraft(editBanner[1]?.seoDescription?.[language] || "")
        )
      )
    );
  }, [editBanner, language]);

  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempItem: [string, any] = [editBanner[0], { ...editBanner[1] }];
      tempItem[1][key] = value;
      setEditBanner(tempItem);
    },
    [editBanner]
  );

  const selectBanner = useCallback((banner: any) => {
    setEditBanner(banner);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(false);
  }, []);

  const onSave = useCallback(async () => {
    const data = convertToRaw(descriptionEditorState.getCurrentContent());
    const myHtml = draftToHtml(data);
    const descriptions: { [index: string]: any } = {};
    for (const l of Object.keys(LANGUAGES)) {
      descriptions[l] = editBanner[1]?.seoDescription?.[language] || "";
    }
    descriptions[language] = myHtml;
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          body: {
            banners: {
              ...banners,
              [editBanner[0]]: {
                ...editBanner[1],
                seoDescription: descriptions,
              },
            },
          },
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [banners, descriptionEditorState, dispatch, editBanner, id, language]);

  const onDelete = useCallback(async () => {
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          [`body.banners.${editBanner[0]}`]: {},
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, editBanner, id]);

  const createNew = useCallback(() => {
    const randomKey = generateString(20);
    setEditBanner([
      randomKey,
      {
        status: true,
        link: "",
        banner: "",
        banner_mobile: "",
        title_it_IT: "",
        title_en_US: "",
        title_es_ES: "",
        title_fr_FR: "",
        colorTitle: "",
        subtitle_it_IT: "",
        subtitle_en_US: "",
        subtitle_es_ES: "",
        subtitle_fr_FR: "",
        colorSubtitle: "",
        bannerLink: false,
        bannerLink_it_IT: "",
        bannerLink_en_US: "",
        bannerLink_es_ES: "",
        bannerLink_fr_FR: "",
        align: "",
        content: "",
        startDate: new Date(),
        endDate: new Date(),
        timeZone: "+02:00",
        brand: "",
        canale: "",
        category: "",
        subcategory: "",
        sku: "",
        seoDescription: "",
      },
    ]);
    setIsNew(true);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(true);
  }, []);

  return (
    <Box sx={style.bannersGallery}>
      <Box>
        <Button variant="contained" onClick={createNew} sx={{ mt: 3 }}>
          Create
        </Button>
      </Box>
      <Box sx={style.imagesContainer}>
        {banners &&
          Object.entries(banners).map((banner: [string, any]) => (
            <Box
              sx={[
                {
                  border:
                    banner[0] === editBanner[0] ? "3px solid #0F45FF" : "",
                },
                style.imageContainer,
              ]}
              onClick={() => selectBanner(banner)}
            >
              <img
                style={{
                  position: "absolute",
                  width: 300,
                }}
                src={banner[1].banner}
                alt=""
              />
              <Box>
                <Box sx={[style.bannerInfo, style.bannerName]}>
                  <Box>Canale: {banner[1].canale}</Box>
                </Box>
                <Box sx={[style.bannerInfo, style.bannerName]}>
                  <Box>Categoria: {banner[1].category}</Box>
                </Box>
                <Box sx={[style.bannerInfo, style.bannerName]}>
                  <Box>Sottocategoria: {banner[1].subcategory}</Box>
                </Box>
                <Box sx={[style.bannerInfo, style.bannerName]}>
                  <Box>Brand: {banner[1].brand}</Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={[style.bannerInfo, style.bannerStatus]}>
                  {banner[1].status ? "Enabled" : "Disabled"}
                </Box>
                <Box sx={[style.bannerInfo, style.bannerName, { bottom: 0 }]}>
                  <Box>sku: {banner[1].sku}</Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      {editBanner[0] && (
        <Paper sx={style.editContainer} ref={containerRef}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Start date"
                  inputFormat="dd-MM-yyyy hh:mm:ss"
                  onChange={(newValue) =>
                    handleChangeValue(newValue, "startDate")
                  }
                  value={editBanner[1]?.startDate}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End date"
                  inputFormat="dd-MM-yyyy hh:mm:ss"
                  onChange={(newValue) =>
                    handleChangeValue(newValue, "endDate")
                  }
                  value={editBanner[1]?.endDate}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                style={{
                  fontWeight: 600,
                  color: "orange",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Il banner comparirà solo se l&apos;url soddisfa tutti i
                parametri inseriti qui sotto.
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Canale"
                value={editBanner[1]?.canale}
                onChange={(ev) => handleChangeValue(ev.target.value, "canale")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Category"
                value={editBanner[1]?.category}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "category")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Subcategory"
                value={editBanner[1]?.subcategory}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "subcategory")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Brand"
                value={editBanner[1]?.brand}
                onChange={(ev) => handleChangeValue(ev.target.value, "brand")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Sku"
                value={editBanner[1]?.sku}
                onChange={(ev) => handleChangeValue(ev.target.value, "sku")}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editBanner[1]?.status as boolean}
                    onChange={() =>
                      handleChangeValue(!editBanner[1].status, "status")
                    }
                  />
                }
                label="Online"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                style={{
                  fontWeight: 600,
                  color: "blue",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Banner: immagine di sfondo, se omesso non ci sarà immagine di
                sfondo
              </div>
              <TextField
                fullWidth
                label="Banner"
                value={editBanner[1]?.banner}
                onChange={(ev) => handleChangeValue(ev.target.value, "banner")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Banner mobile"
                value={editBanner[1]?.banner_mobile}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "banner_mobile")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                style={{
                  fontWeight: 600,
                  color: "blue",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Titolo: h1 traducibile + Colore da inserire in{" "}
                <b>esadecimale o inglese (es. #fff o red)</b>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Titolo IT"
                value={editBanner[1]?.title_it_IT}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "title_it_IT")
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Titolo EN"
                value={editBanner[1]?.title_en_US}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "title_en_US")
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Titolo ES"
                value={editBanner[1]?.title_es_ES}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "title_es_ES")
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Titolo FR"
                value={editBanner[1]?.title_fr_FR}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "title_fr_FR")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Colore titolo"
                value={editBanner[1]?.colorTitle}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "colorTitle")
                }
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <div
                style={{
                  fontWeight: 600,
                  color: "blue",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Sottotitolo: paragrafo traducibile + Colore da inserire in{" "}
                <b>esadecimale o inglese (es. #fff o red)</b>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Sottotitolo IT"
                value={editBanner[1]?.subtitle_it_IT}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "subtitle_it_IT")
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Sottotitolo EN"
                value={editBanner[1]?.subtitle_en_US}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "subtitle_en_US")
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Sottotitolo ES"
                value={editBanner[1]?.subtitle_es_ES}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "subtitle_es_ES")
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Sottotitolo FR"
                value={editBanner[1]?.subtitle_fr_FR}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "subtitle_fr_FR")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Colore sottotitolo"
                value={editBanner[1]?.colorSubtitle}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "colorSubtitle")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ color: "red" }}>
                CTA momentaneamente tolta dal front end, eventuali trad non sono
                visibili sul sito
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={editBanner[1]?.bannerLink as boolean}
                    onChange={() =>
                      handleChangeValue(!editBanner[1].bannerLink, "bannerLink")
                    }
                  />
                }
                label="Attiva per tradurre cta"
              />
            </Grid>
            {editBanner[1]?.bannerLink && (
              <>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="CTA IT"
                    value={editBanner[1]?.bannerLink_it_IT}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "bannerLink_it_IT")
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="CTA EN"
                    value={editBanner[1]?.bannerLink_en_US}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "bannerLink_en_US")
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="CTA ES"
                    value={editBanner[1]?.bannerLink_es_ES}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "bannerLink_es_ES")
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="CTA FR"
                    value={editBanner[1]?.bannerLink_fr_FR}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "bannerLink_fr_FR")
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12}>
              <div
                style={{
                  fontWeight: 600,
                  color: "blue",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Allinemento testi
              </div>
            </Grid>

            <Grid item xs={5} sm={5}>
              <FormControl fullWidth>
                <InputLabel id="country-label">Allineamento testi</InputLabel>
                <Select
                  labelId="select-country"
                  id="select-country"
                  label="Allineamento testi"
                  value={editBanner[1]?.align}
                  onChange={(ev) => handleChangeValue(ev.target.value, "align")}
                >
                  <MenuItem value="flex-start">SINISTRA</MenuItem>
                  <MenuItem value="center">CENTRATO</MenuItem>
                  <MenuItem value="flex-end">DESTRA</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={5} sm={5}>
              <FormControl fullWidth>
                <InputLabel id="country-label">
                  Allineamento contenuto
                </InputLabel>
                <Select
                  labelId="select-country"
                  id="select-country"
                  label="Allineamento testi"
                  value={editBanner[1]?.content}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "content")
                  }
                >
                  <MenuItem value="flex-start">SOPRA</MenuItem>
                  <MenuItem value="center">CENTRATO</MenuItem>
                  <MenuItem value="flex-end">SOTTO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box sx={style.wrapperClassName}>
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={setDescriptionEditorState}
                  editorState={descriptionEditorState}
                  toolbar={{
                    blockType: {
                      inDropdown: true,
                      options: [
                        "Normal",
                        "H1",
                        "H2",
                        "H3",
                        "H4",
                        "H5",
                        "H6",
                        "Blockquote",
                        "Code",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                    fontFamily: {
                      options: [
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                        "Inter",
                        "Poppins",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={3} sm={3}>
              <Button variant="contained" color="primary" onClick={onSave}>
                Save
              </Button>
            </Grid>
            {!isNew && (
              <Grid item xs={3} sm={3}>
                <Button variant="contained" color="error" onClick={onDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default TimedProductsBanner;
