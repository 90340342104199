import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import useEditModalContent from "./index.hooks";
import style from "./index.style";
import { CRON_JOB_INTERVALS } from "../../../../constants/contants";

type EditModalContentProps = {
  item: any;
  closeModal: () => void;
  getItems: any;
  newCron: boolean;
  setNewCron: (isNew: boolean) => void;
};

const EditModalContent = memo(
  ({
    item,
    closeModal,
    getItems,
    newCron,
    setNewCron,
  }: EditModalContentProps) => {
    const { handleChangeValue, editingItem, updateItem, deleteItem } =
      useEditModalContent({
        item,
        getItems,
        closeModal,
        newCron,
        setNewCron,
      });

    return (
      <div>
        {editingItem && (
          <Box sx={style.modal}>
            <Grid container spacing={3}>
              <>
                <IconButton sx={style.modal.closeButton} onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={editingItem?.name}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "name")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    value={editingItem?.description}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "description")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="URL"
                    value={editingItem?.url}
                    onChange={(ev) => handleChangeValue(ev.target.value, "url")}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel id="interval-label">Interval</InputLabel>
                    <Select
                      labelId="interval-label"
                      id="interval-status"
                      label="Interval"
                      value={editingItem?.interval}
                      onChange={(ev) =>
                        handleChangeValue(ev.target.value, "interval")
                      }
                    >
                      <MenuItem key="all" value="">
                        ALL
                      </MenuItem>
                      {CRON_JOB_INTERVALS.map((interval: any) => (
                        <MenuItem key={interval.id} value={interval.id}>
                          {interval.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editingItem?.status === 1}
                        onChange={(ev) =>
                          handleChangeValue(ev.target.checked ? 1 : 0, "status")
                        }
                      />
                    }
                    label="Status"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editingItem?.saveResponse}
                        onChange={(ev) =>
                          handleChangeValue(ev.target.checked, "saveResponse")
                        }
                      />
                    }
                    label="Save response"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", gap: 3 }}
                >
                  <Button variant="contained" size="large" onClick={updateItem}>
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="error"
                    onClick={deleteItem}
                  >
                    Delete
                  </Button>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
      </div>
    );
  }
);

export default EditModalContent;
