import React, { memo } from "react";
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { getFormattedAmount } from "../../../utils";

type ProductsTableProps = {
  data: any;
};

const InfoTables = memo(({ data }: ProductsTableProps) => {
  const totalUsers = data.enabledUsersCount + data.pendingUsersCount;
  const totalUsersSubscriptions =
    data.newSubscriptionCount + data.renewSubscriptionCount;
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Total New Users</TableCell>
              <TableCell align="right">{totalUsers}</TableCell>
              <TableCell align="right">%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Pending</TableCell>
              <TableCell align="right">{data.pendingUsersCount}</TableCell>
              <TableCell align="right">
                {((data.pendingUsersCount / totalUsers) * 100).toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Enabled</TableCell>
              <TableCell align="right">{data.enabledUsersCount}</TableCell>
              <TableCell align="right">
                {((data.enabledUsersCount / totalUsers) * 100).toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead />
          <TableBody>
            <TableRow>
              <TableCell>Total billed product</TableCell>
              <TableCell align="right">
                {getFormattedAmount(data.totalBilledProducts)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total billed service</TableCell>
              <TableCell align="right">
                {getFormattedAmount(data.totalBilledService)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Total Active Users</TableCell>
              <TableCell align="right">{totalUsersSubscriptions}</TableCell>
              <TableCell align="right">%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>New</TableCell>
              <TableCell align="right">{data.newSubscriptionCount}</TableCell>
              <TableCell align="right">
                {(
                  (data.newSubscriptionCount / totalUsersSubscriptions) *
                  100
                ).toFixed(2) || 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Renew</TableCell>
              <TableCell align="right">{data.renewSubscriptionCount}</TableCell>
              <TableCell align="right">
                {(
                  (data.renewSubscriptionCount / totalUsersSubscriptions) *
                  100
                ).toFixed(2) || 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Connection</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.channels
              ?.sort((a: any, b: any) => {
                if (a.count > b.count) return -1;
                if (a.count < b.count) return 1;
                return 0;
              })
              .map((channel: any) => (
                <TableRow>
                  <TableCell>
                    {channel.service !== channel.component && channel.component
                      ? `${channel.service}_${channel.component}`
                      : channel.service}
                  </TableCell>
                  <TableCell align="right">{channel.count}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default InfoTables;
