import React, { memo, useCallback, useMemo } from "react";
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { getFormattedAmount } from "../../../utils";
import { getCountries } from "../../../redux/slices/platform-data/platform-data.selectors";

type ProductsTableProps = {
  products: any;
  // eslint-disable-next-line react/require-default-props
  type?: string;
};

type RowProps = {
  row: any;
  // eslint-disable-next-line react/require-default-props
  type?: string;
};

type CountryRowProps = {
  country: any;
  row: any;
};

type UserRowProps = {
  user: any;
  row: any;
  // eslint-disable-next-line react/require-default-props
  type?: string;
};

type ProductRowProps = {
  product: any;
  row: any;
};

function ProductRow({ product, row }: ProductRowProps) {
  const [userOpen, setUserOpen] = React.useState(false);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
        onClick={() => setUserOpen((prev) => !prev)}
      >
        {row?.countries && (
          <TableCell padding="none">
            <IconButton aria-label="expand row" size="small">
              {userOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          {row?.name || product || "OTHER"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.count}
        </TableCell>
        <TableCell component="th" scope="row">
          {getFormattedAmount(row?.sum.toFixed(2))}
        </TableCell>
      </TableRow>
      {row?.countries && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={userOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Countries
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Count</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(row.countries).map((country: any, index) => (
                      <TableRow
                        sx={{
                          "& > *": { borderBottom: "unset" },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {country || "OTHER"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.countries[country].count}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {getFormattedAmount(
                            row.countries[country].sum.toFixed(2)
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function CountryRow({ country, row }: CountryRowProps) {
  const [userOpen, setUserOpen] = React.useState(false);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
        onClick={() => setUserOpen((prev) => !prev)}
      >
        <TableCell padding="none">
          <IconButton aria-label="expand row" size="small">
            {userOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {country || "OTHER"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.countries[country].count}
        </TableCell>
        <TableCell component="th" scope="row">
          {getFormattedAmount(row.countries[country].sum.toFixed(2))}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={userOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Users
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(row.countries[country].emails).map(
                    (email: any, index) => (
                      <TableRow
                        sx={{
                          "& > *": { borderBottom: "unset" },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {email || "OTHER"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.countries[country].emails[email].count}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {getFormattedAmount(
                            row.countries[country].emails[email].sum.toFixed(2)
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function UserRow({ user, row, type = "" }: UserRowProps) {
  const [userOpen, setUserOpen] = React.useState(false);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
        onClick={() => setUserOpen((prev) => !prev)}
      >
        <TableCell padding="none">
          <IconButton aria-label="expand row" size="small">
            {userOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {user || "OTHER"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.users[user].count}
        </TableCell>
        <TableCell component="th" scope="row">
          {getFormattedAmount(row.users[user].sum.toFixed(2))}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={userOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {type === "countriesProducts" ? "Products" : "Users"}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(row.users[user].items).map(
                    (item: any, index) => (
                      <TableRow
                        sx={{
                          "& > *": { borderBottom: "unset" },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item || "OTHER"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.users[user].items[item].count}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {getFormattedAmount(
                            row.users[user].items[item].sum.toFixed(2)
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function Row({ row, type = "" }: RowProps) {
  const [open, setOpen] = React.useState(false);
  const countries = useSelector(getCountries);

  const getCountryFull = useCallback(
    (countrycode) => {
      const country = countries.find(
        (country: any) => country.countrycode === countrycode
      )?.country;
      if (country) {
        return ` - ${country}`;
      }
      return "";
    },
    [countries]
  );

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          cursor: row?.countries || row?.products ? "pointer" : "default",
        }}
        onClick={() => setOpen(!open)}
      >
        {(row?.countries || row?.products || row?.users) && (
          <TableCell padding="none">
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell align="left">{`${row.name || "OTHER"} ${getCountryFull(
          row?.name
        )}`}</TableCell>
        <TableCell align="right">{row.count}</TableCell>
        <TableCell align="right">
          {getFormattedAmount(row?.taxable.toFixed(2))}
        </TableCell>
      </TableRow>
      {(row.countries || row.products || row?.users) && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {row.countries && "Countries"}
                  {row.products && "Products"}
                  {row.users && "Users"}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {(row?.countries ||
                        row?.products?.products ||
                        row?.users) && <TableCell padding="none" />}
                      <TableCell>Name</TableCell>
                      <TableCell>Count</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.countries &&
                      Object.keys(row.countries).map((country: any, index) => (
                        <CountryRow country={country} key={country} row={row} />
                      ))}
                    {row?.users &&
                      Object.keys(row.users).map((user: any, index) => (
                        <UserRow user={user} key={user} row={row} type={type} />
                      ))}
                    {row?.products &&
                      Object.keys(row.products).map((product: any, index) => (
                        <ProductRow
                          product={product}
                          key={product}
                          row={row.products[product]}
                        />
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

const ProductsTable = memo(({ products, type = "" }: ProductsTableProps) => {
  const orderedProducts = useMemo(
    () =>
      products?.sort((a: any, b: any) => {
        if (a.taxable > b.taxable) return -1;
        if (a.taxable < b.taxable) return 1;
        return 0;
      }) || [],
    [products]
  );
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {products?.some(
              (product: any) =>
                product.countries || product.products || product.users
            ) && <TableCell padding="none" />}
            <TableCell>Name</TableCell>
            <TableCell align="right">Count</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedProducts.map((product: any) => (
            <Row key={product.name} row={product} type={type} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default ProductsTable;
