import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsCarouselBody,
  getProductsCarouselId,
} from "redux/slices/platform-data/platform-data.selectors";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  Typography,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Edit } from "@mui/icons-material/";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { actions } from "redux/slices";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateTimePicker } from "@mui/lab";
import { generateString } from "../../../utils";
import style from "./index.style";

interface Product {
  price: string;
  promoPrice: string;
  image: string;
  link: string;
  title_IT: string;
  title_EN: string;
  title_FR: string;
  title_ES: string;
  brand: string;
  cashback: string;
  priority: string;
}

const initialFormState = {
  price: "",
  promoPrice: "",
  image: "",
  link: "",
  title_IT: "",
  title_EN: "",
  title_FR: "",
  title_ES: "",
  brand: "",
  cashback: "",
  priority: "1",
};

const selectValues = [2, 3, 4, 6];

function ProductsCarousel() {
  const banners = useSelector(getProductsCarouselBody);
  const id = useSelector(getProductsCarouselId);
  const dispatch = useDispatch();
  const [editBanner, setEditBanner] = useState<[string, any]>([
    "",
    {
      key: "",
      status: true,
      link: "",
      banner: "",
      bannerActive: false,
      textWhite: false,
      reverse: false,
      priority: "",
      startDate: new Date(),
      endDate: new Date(),
      timeZone: "+02:00",
      brand: "",
      title_IT: "",
      title_EN: "",
      title_FR: "",
      title_ES: "",
      sub_IT: "",
      sub_EN: "",
      sub_FR: "",
      sub_ES: "",
      background: "",
      backgroundCarousel: "",
      productCarousel: "",
      carouselActive: false,
      isCarousel: false,
      isProduct: false,
      gridCol: 3,
      isStretch: false,
      colorCashback: "",
      colorTextCashback: "",
    },
  ]);
  const LETSELL = process.env.REACT_APP_ENV === "Letsell";
  const containerRef = useRef(null);
  const [isNew, setIsNew] = useState(false);

  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempItem: [string, any] = [editBanner[0], { ...editBanner[1] }];
      tempItem[1][key] = value;
      setEditBanner(tempItem);
    },
    [editBanner]
  );

  const selectBanner = useCallback((banner: any) => {
    setEditBanner(banner);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(false);
  }, []);

  const [products, setProducts] = useState<Product[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexProduct, setIndexProduct] = useState(0);
  const [formValues, setFormValues] = useState(initialFormState);
  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newProduct: Product = {
      price: formValues.price,
      promoPrice: formValues.promoPrice,
      image: formValues.image,
      link: formValues.link,
      title_IT: formValues.title_IT,
      title_EN: formValues.title_EN,
      title_FR: formValues.title_FR,
      title_ES: formValues.title_ES,
      brand: formValues.brand,
      cashback: formValues.cashback,
      priority: formValues.priority,
    };
    setProducts([...products, newProduct]);
    setShowForm(false);
    setFormValues(initialFormState);
  };
  const handleEditFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newProduct: Product = {
      price: formValues.price,
      promoPrice: formValues.promoPrice,
      image: formValues.image,
      link: formValues.link,
      title_IT: formValues.title_IT,
      title_EN: formValues.title_EN,
      title_FR: formValues.title_FR,
      title_ES: formValues.title_ES,
      brand: formValues.brand,
      cashback: formValues.cashback,
      priority: formValues.priority,
    };
    const newProducts = [...products];
    newProducts.splice(indexProduct, 1, newProduct);
    setProducts(newProducts);
    setShowForm(false);
    setIsEdit(false);
    setFormValues(initialFormState);
  };

  const clearFormValue = (event: React.FormEvent) => {
    event.preventDefault();
    setFormValues(initialFormState);
    setShowForm(false);
    setIsEdit(false);
  };
  const handleDelete = (index: number) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleEdit = (index: number) => {
    setShowForm(true);
    setIsEdit(true);
    const productValues = {
      price: products[index].price,
      promoPrice: products[index].promoPrice,
      image: products[index].image,
      link: products[index].link,
      title_IT: products[index].title_IT,
      title_EN: products[index].title_EN,
      title_FR: products[index].title_FR,
      title_ES: products[index].title_ES,
      brand: products[index].brand,
      cashback: products[index].cashback,
      priority: products[index].priority,
    };
    setIndexProduct(index);
    setFormValues(productValues);
  };

  const onSave = useCallback(async () => {
    let tempEditbanner = { ...editBanner[1] };
    if (products) {
      tempEditbanner = { ...editBanner[1], productCarousel: products };
    }
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          body: {
            banners: {
              ...banners,
              [editBanner[0]]: tempEditbanner,
            },
          },
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [banners, dispatch, editBanner, id, products]);

  const onDelete = useCallback(async () => {
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          [`body.banners.${editBanner[0]}`]: {},
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, editBanner, id]);

  const createNew = useCallback(() => {
    const randomKey = generateString(20);
    setProducts([]);
    setEditBanner([
      randomKey,
      {
        key: "",
        status: true,
        link: "",
        banner: "",
        bannerActive: false,
        textWhite: false,
        reverse: false,
        priority: "",
        startDate: new Date(),
        endDate: new Date(),
        timeZone: "+02:00",
        brand: "",
        title_IT: "",
        title_EN: "",
        title_FR: "",
        title_ES: "",
        sub_IT: "",
        sub_EN: "",
        sub_FR: "",
        sub_ES: "",
        background: "",
        backgroundCarousel: "",
        productCarousel: "",
        carouselActive: false,
        isCarousel: false,
        isProduct: false,
        gridCol: 3,
        isStretch: false,
        colorCashback: "",
        colorTextCashback: "",
      },
    ]);
    setIsNew(true);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(true);
  }, []);

  useEffect(() => {
    if (editBanner[1]?.productCarousel?.length > 0) {
      setProducts(editBanner[1]?.productCarousel);
    } else {
      setProducts([]);
    }
  }, [editBanner]);

  return (
    <Box sx={style.bannersGallery}>
      <Box>
        <Button variant="contained" onClick={createNew} sx={{ mt: 3 }}>
          Create
        </Button>
      </Box>
      <Box sx={style.imagesContainer}>
        {banners &&
          Object.entries(banners)
            .sort(([keya, a]: [string, any], [keyb, b]: [string, any]) => {
              if (Number(a.priority) < Number(b.priority)) return 1;
              if (Number(a.priority) > Number(b.priority)) return -1;
              return 0;
            })
            .map((banner: [string, any]) => (
              <Box sx={style.imageContainer}>
                <Box sx={[style.bannerInfo, style.bannerName]}>
                  {banner[1]?.title_IT || banner[1].brand}
                </Box>
                <Box sx={[style.bannerInfo, style.bannerStatus]}>
                  {banner[1].status ? "Enabled" : "Disabled"}
                </Box>
                <Box sx={[style.bannerInfo, style.bannerPriority]}>
                  {banner[1].priority}
                </Box>
                <IconButton
                  sx={[style.bannerEditIcon, style.bannerInfo]}
                  onClick={() => selectBanner(banner)}
                >
                  <Edit />
                </IconButton>
                <img
                  style={{
                    width: 300,
                    border:
                      banner[0] === editBanner[0] ? "3px solid #0F45FF" : "",
                  }}
                  src={
                    banner[1]?.banner || banner[1]?.productCarousel[0]?.image
                  }
                  alt="banner"
                />
              </Box>
            ))}
      </Box>
      {editBanner[0] && (
        <Paper sx={style.editContainer} ref={containerRef}>
          <div
            style={{
              padding: 10,
              background: "aliceblue",
              borderRadius: 10,
              marginBottom: 30,
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            Ricordati di salvare le impostazioni dopo avere inserito i prodotti
            del carosello!!
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Start date"
                  inputFormat="dd-MM-yyyy hh:mm:ss"
                  onChange={(newValue) =>
                    handleChangeValue(newValue, "startDate")
                  }
                  value={editBanner[1]?.startDate}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End date"
                  inputFormat="dd-MM-yyyy hh:mm:ss"
                  onChange={(newValue) =>
                    handleChangeValue(newValue, "endDate")
                  }
                  value={editBanner[1]?.endDate}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editBanner[1]?.status as boolean}
                    onChange={() =>
                      handleChangeValue(!editBanner[1].status, "status")
                    }
                  />
                }
                label="Online"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Priority"
                value={editBanner[1]?.priority}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "priority")
                }
              />
            </Grid>
            <Grid xs={12} sm={12} item>
              <div style={{ display: "flex", gap: 20 }}>
                <Typography variant="h3" sx={{ margin: "3px 0" }}>
                  Banner
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editBanner[1]?.bannerActive as boolean}
                      onChange={() =>
                        handleChangeValue(
                          !editBanner[1].bannerActive,
                          "bannerActive"
                        )
                      }
                    />
                  }
                  label="Online"
                />
              </div>
              <div style={{ fontWeight: 600, color: "grey" }}>
                BACKGROUND: Nella sezione background inserire colore in ingles
                (es red, blue, green) oppure esadecimale (es #f2f2f2)
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Link Banner"
                value={editBanner[1]?.link}
                onChange={(ev) => handleChangeValue(ev.target.value, "link")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Link Banner Image"
                value={editBanner[1]?.banner}
                onChange={(ev) => handleChangeValue(ev.target.value, "banner")}
              />
            </Grid>
            {!LETSELL && (
              <Grid item xs={12} sm={12}>
                <span style={{ fontWeight: 600, color: "grey" }}>
                  Titolo banner, se non trova il testo nella lingua del sito
                  mostra il Valore fallback
                </span>
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label={LETSELL ? "Brand" : "Valore fallback"}
                value={editBanner[1]?.brand}
                onChange={(ev) => handleChangeValue(ev.target.value, "brand")}
              />
            </Grid>
            {!LETSELL && (
              <>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Titolo IT"
                    value={editBanner[1]?.title_IT}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "title_IT")
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Titolo EN"
                    value={editBanner[1]?.title_EN}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "title_EN")
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Titolo FR"
                    value={editBanner[1]?.title_FR}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "title_FR")
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Titolo ES"
                    value={editBanner[1]?.title_ES}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "title_ES")
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Background"
                value={editBanner[1]?.background}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "background")
                }
              />
            </Grid>
            <Grid item xs={LETSELL ? 12 : 6} sm={LETSELL ? 12 : 6}>
              <TextField
                fullWidth
                label="Sottotitolo IT"
                value={editBanner[1]?.sub_IT}
                onChange={(ev) => handleChangeValue(ev.target.value, "sub_IT")}
              />
            </Grid>
            {!LETSELL && (
              <>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Sottotitolo EN"
                    value={editBanner[1]?.sub_EN}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "sub_EN")
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Sottotitolo FR"
                    value={editBanner[1]?.sub_FR}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "sub_FR")
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Sottotitolo ES"
                    value={editBanner[1]?.sub_ES}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "sub_ES")
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6} sm={6}>
              <div style={{ margin: "20px 0px" }}>
                In caso di background scuro
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={editBanner[1]?.textWhite as boolean}
                    onChange={() =>
                      handleChangeValue(!editBanner[1].textWhite, "textWhite")
                    }
                  />
                }
                label="Attiva colore testo bianco"
              />
              <div style={{ margin: "20px 0px" }}>
                Attiva per avere immagine a destra e testo a sinistra. Default:
                immagine sx testo a dx
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={editBanner[1]?.reverse as boolean}
                    onChange={() =>
                      handleChangeValue(!editBanner[1].reverse, "reverse")
                    }
                  />
                }
                label="Attiva per reverse column"
              />
            </Grid>
            <Grid xs={12} sm={12} item>
              <Divider />
            </Grid>
            <Grid xs={12} sm={12} item>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", gap: 20 }}>
                  <Typography variant="h3" sx={{ margin: "3px 0" }}>
                    Blocco prodotti
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editBanner[1]?.carouselActive as boolean}
                        onChange={() =>
                          handleChangeValue(
                            !editBanner[1].carouselActive,
                            "carouselActive"
                          )
                        }
                      />
                    }
                    label="Online"
                  />
                </div>
                <Button
                  onClick={() => setShowForm(true)}
                  variant="outlined"
                  color="primary"
                >
                  Add Product
                </Button>
              </Box>
              <div style={{ fontWeight: 600, color: "grey" }}>
                INFO: per vedere blocco attiva lo switch
              </div>
              <div style={{ marginTop: 15 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editBanner[1]?.isCarousel as boolean}
                      onChange={() =>
                        handleChangeValue(
                          !editBanner[1].isCarousel,
                          "isCarousel"
                        )
                      }
                    />
                  }
                  label="Attiva per carosello"
                />
              </div>
              <div style={{ fontWeight: 600, color: "grey" }}>
                INFO: Attiva per visualizzare Carosello, default blocchi
                affiancati
              </div>
              {LETSELL && (
                <div style={{ marginTop: 15 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editBanner[1]?.isStretch as boolean}
                        onChange={() =>
                          handleChangeValue(
                            !editBanner[1].isStretch,
                            "isStretch"
                          )
                        }
                      />
                    }
                    label="Stretch section max 800px"
                  />
                </div>
              )}
            </Grid>
            {LETSELL && (
              <>
                <Grid xs={6} sm={6} item>
                  <FormControl fullWidth>
                    <InputLabel id="carrier-label">Grid</InputLabel>
                    <Select
                      labelId="select-carrier"
                      id="carrier-label"
                      label="Grid"
                      value={editBanner[1]?.gridCol}
                      onChange={(ev) =>
                        handleChangeValue(ev.target.value, "gridCol")
                      }
                    >
                      {selectValues.map((grid: any) => (
                        <MenuItem key={grid} value={grid}>
                          {grid}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editBanner[1]?.isProduct as boolean}
                        onChange={() =>
                          handleChangeValue(
                            !editBanner[1].isProduct,
                            "isProduct"
                          )
                        }
                      />
                    }
                    label="Attiva per card prodotto"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12}>
              <div style={{ margin: "10px 0px" }}>
                Default background è quello del banner
              </div>
              <TextField
                fullWidth
                label="Background carosello"
                value={editBanner[1]?.backgroundCarousel}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "backgroundCarousel")
                }
              />
            </Grid>
            <>
              <Grid item xs={6} sm={6}>
                <div style={{ margin: "10px 0px" }}>
                  Colore fascetta cashback
                </div>
                <TextField
                  fullWidth
                  label="Color cashback"
                  value={editBanner[1]?.colorCashback}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "colorCashback")
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <div style={{ margin: "10px 0px" }}>Colore testo cashback</div>
                <TextField
                  fullWidth
                  label="Color text cashback"
                  value={editBanner[1]?.colorTextCashback}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "colorTextCashback")
                  }
                />
              </Grid>
            </>
            {showForm && (
              <>
                <Grid item xs={4} sm={4}>
                  <TextField
                    label="Brand"
                    type="text"
                    fullWidth
                    value={formValues.brand}
                    onChange={(e) =>
                      setFormValues({ ...formValues, brand: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Image URL"
                    fullWidth
                    type="text"
                    value={formValues.image}
                    onChange={(e) =>
                      setFormValues({ ...formValues, image: e.target.value })
                    }
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    label="Categoria IT"
                    fullWidth
                    type="text"
                    value={formValues.title_IT}
                    onChange={(e) =>
                      setFormValues({ ...formValues, title_IT: e.target.value })
                    }
                    variant="outlined"
                    required
                  />
                </Grid>
                {!LETSELL && (
                  <>
                    <Grid item xs={4} sm={4}>
                      <TextField
                        label="Categoria EN"
                        fullWidth
                        type="text"
                        value={formValues.title_EN}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            title_EN: e.target.value,
                          })
                        }
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <TextField
                        label="Categoria FR"
                        fullWidth
                        type="text"
                        value={formValues.title_FR}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            title_FR: e.target.value,
                          })
                        }
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <TextField
                        label="Categoria ES"
                        fullWidth
                        type="text"
                        value={formValues.title_ES}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            title_ES: e.target.value,
                          })
                        }
                        variant="outlined"
                        required
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={4} sm={4}>
                  <TextField
                    label="Prezzo o percentuale sconto"
                    type="text"
                    fullWidth
                    value={formValues.price}
                    onChange={(e) =>
                      setFormValues({ ...formValues, price: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    fullWidth
                    label="Prezzo barrato"
                    type="text"
                    value={formValues.promoPrice}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        promoPrice: e.target.value,
                      })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    fullWidth
                    label="Cashback"
                    type="text"
                    value={formValues.cashback}
                    onChange={(e) =>
                      setFormValues({ ...formValues, cashback: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    fullWidth
                    label="Link puntamento"
                    type="text"
                    value={formValues.link}
                    onChange={(e) =>
                      setFormValues({ ...formValues, link: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    fullWidth
                    label="Priorità"
                    type="text"
                    value={formValues.priority}
                    onChange={(e) =>
                      setFormValues({ ...formValues, priority: e.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {!isEdit && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleFormSubmit}
                      >
                        Add
                      </Button>
                    )}
                    {isEdit && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleEditFormSubmit}
                      >
                        Confirm
                      </Button>
                    )}
                    <Button
                      type="submit"
                      variant="text"
                      color="error"
                      onClick={clearFormValue}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                flexWrap: "wrap",
                margin: 3,
              }}
            >
              {products?.map((product, index) => (
                <Grid
                  item
                  sx={{
                    padding: 2,
                    border: "solid thin #ccc",
                    maxWidth: "200px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 2,
                      right: 2,
                      cursor: "pointer",
                      color: "blue",
                    }}
                  >
                    <Button
                      onClick={() => handleEdit(index)}
                      variant="outlined"
                      color="primary"
                    >
                      Edit
                    </Button>
                  </div>
                  <img
                    src={product?.image}
                    width={130}
                    alt={product?.title_IT}
                    loading="lazy"
                  />
                  <Typography>{product.title_IT}</Typography>
                  {product.price && <Typography>{product.price} €</Typography>}
                  {product.promoPrice && (
                    <Typography sx={{ textDecoration: "line-through" }}>
                      {product.promoPrice} €
                    </Typography>
                  )}
                  {product.cashback && (
                    <Typography sx={{ color: "orange" }}>
                      {product.cashback} €
                    </Typography>
                  )}
                  <Typography sx={{ color: "blue" }}>
                    Priority: {product.priority}
                  </Typography>
                  <Button
                    onClick={() => handleDelete(index)}
                    variant="text"
                    color="error"
                  >
                    Delete
                  </Button>
                </Grid>
              ))}
            </Box>

            <Grid item xs={12} sm={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button variant="contained" color="primary" onClick={onSave}>
                  Save
                </Button>
                {!isNew && (
                  <Button variant="contained" color="error" onClick={onDelete}>
                    Delete
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default ProductsCarousel;
