import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Drawer,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import style from "./index.style";
import useCronJobs from "./index.hooks";
import { getFormattedDateWithTime } from "../../../utils";

type CronJobsProps = {
  cronId: string;
  resetCronId: () => void;
};

const CronJobs = memo(({ cronId, resetCronId }: CronJobsProps) => {
  const {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    handleFiltersModal,
    filtersModalOpen,
    filters,
    dispatchFilters,
    resetFilters,
    setShouldFilter,
  } = useCronJobs({ cronId, resetCronId });

  return (
    <>
      <Drawer
        anchor="right"
        open={filtersModalOpen}
        onClose={() => {
          setShouldFilter(true);
          handleFiltersModal();
        }}
      >
        <Box sx={style.filters}>
          <TextField
            label="Cron Id"
            value={filters?.cronId}
            onChange={(ev) =>
              dispatchFilters({ type: "cronId", payload: ev.target.value })
            }
            fullWidth
          />
          <TextField
            label="Http Code"
            value={filters?.httpCode}
            onChange={(ev) =>
              dispatchFilters({ type: "httpCode", payload: ev.target.value })
            }
            fullWidth
          />
          <TextField
            label="Url"
            value={filters?.url}
            onChange={(ev) =>
              dispatchFilters({ type: "url", payload: ev.target.value })
            }
            fullWidth
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start date"
              value={filters.startDate}
              onChange={(newDate) =>
                dispatchFilters({
                  type: "startDate",
                  payload: newDate,
                })
              }
              inputFormat="dd-MM-yyyy"
              mask="__-__-____"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            <DatePicker
              label="End date"
              value={filters.endDate}
              onChange={(newDate) =>
                dispatchFilters({
                  type: "endDate",
                  payload: newDate,
                })
              }
              inputFormat="dd-MM-yyyy"
              mask="__-__-____"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={() => {
              handleFiltersModal();
              setShouldFilter(true);
            }}
          >
            Filter
          </Button>
          <Button variant="outlined" onClick={resetFilters}>
            Reset
          </Button>
        </Box>
      </Drawer>
      <Box sx={style.user.wrapper}>
        <Box sx={style.user.action}>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => {
              setShouldFilter(false);
              handleFiltersModal();
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Box>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Cron Id</TableCell>
                <TableCell>Req Time</TableCell>
                <TableCell>Http Code</TableCell>
                <TableCell>URL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item) => (
                <TableRow key={item?._id}>
                  <TableCell>{item?.cronId}</TableCell>
                  <TableCell>
                    {getFormattedDateWithTime(item?.reqTime)}
                  </TableCell>
                  <TableCell>{item?.httpCode}</TableCell>
                  <TableCell>{item?.url}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default CronJobs;
