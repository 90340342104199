import { useCallback, useState } from "react";
import request from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { format } from "date-fns";

const useMerchantInvoice = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [merchantId, setMerchantId] = useState(0);
  const [creditNote, setCreditNote] = useState(false);

  const onChangeDate = useCallback((newDate) => {
    setStartDate(newDate[0]);
    setEndDate(newDate[1]);
  }, []);

  const onSubmit = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.getMerchantInvoice,
      pathParams: {
        merchantId,
      },
      query: {
        startTime: format(startDate, "yyyy-MM-dd"),
        endTime: format(endDate, "yyyy-MM-dd"),
        credit_note: creditNote,
      },
      responseType: "blob",
    });
    const filename =
      response?.headers?.["content-disposition"]
        .split("filename=")?.[1]
        .replace(/"/g, "") || "merchantInvoice.csv";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }, [creditNote, endDate, merchantId, startDate]);

  const handleCreditNoteCheck = useCallback(() => {
    setCreditNote((prevState) => !prevState);
  }, []);
  return {
    startDate,
    endDate,
    onChangeDate,
    onSubmit,
    merchantId,
    setMerchantId,
    handleCreditNoteCheck,
    creditNote,
  };
};

export default useMerchantInvoice;
