import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { format } from "date-fns";
import style from "./index.style";
import usePaypal from "./index.hooks";

type PaypalProps = {};

const Paypal = memo(({}: PaypalProps) => {
  const {
    page,
    logs,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getLogs,
    searchValue,
    handleChangeSearchValue,
    startDate,
    endDate,
    onChangeDate,
    today,
    lastYear,
  } = usePaypal();

  return (
    <>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search"
              value={searchValue}
              onChange={handleChangeSearchValue}
              size="medium"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                startText="Start date"
                endText="End date"
                value={[startDate, endDate]}
                onChange={onChangeDate}
                inputFormat="dd-MM-yyyy"
                mask="__-__-____"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 3 }}> to </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>BDroppy Email</TableCell>
                <TableCell>Paypal Email</TableCell>
                <TableCell>Payment time</TableCell>
                <TableCell>Paypal Link</TableCell>
                <TableCell>Amount Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs?.map((log) => (
                <TableRow key={log?._id}>
                  <TableCell>{log?.res?.metadata?.userEmail}</TableCell>
                  <TableCell>{log?.res?.receipt_email}</TableCell>
                  <TableCell>{log?.resTime}</TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.paypal.com/activities/?fromDate=${format(
                        lastYear,
                        "yyyy-MM-dd"
                      )}&toDate=${format(
                        today,
                        "yyyy-MM-dd"
                      )}&searchType=ANY&searchKeyword=${
                        log?.res?.id
                      }&transactiontype=ALL_TRANSACTIONS&archive=ACTIVE_TRANSACTIONS&amount_lower=0&amount_upper=0&currency_value=ALL_TRANSACTIONS_CURRENCY&currency_label=ALL_TRANSACTIONS_CURRENCY`}
                    >
                      {log?.res?.id}
                    </a>
                  </TableCell>
                  <TableCell>
                    {(Number(log?.res?.amount) / 100)?.toFixed(2)} €
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default Paypal;
