import { useCallback, useEffect, useState } from "react";
import { format, sub } from "date-fns";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";

const useStatisticsTable = () => {
  const today = new Date();
  const lastWeek = sub(today, { weeks: 1 });
  const [startDate, setStartDate] = useState<Date>(lastWeek);
  const [endDate, setEndDate] = useState<Date>(today);
  const [data, setData] = useState<any>({});

  const getInvoicesStatistics = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.getInvoiceStatisticsInfos,
      method: HttpMethod.GET,
      query: {
        startTime: format(startDate, "yyyy-MM-dd"),
        endTime: format(endDate, "yyyy-MM-dd"),
      },
    });
    setData(response?.data || {});
  }, [endDate, startDate]);

  useEffect(() => {
    if (startDate && endDate) {
      getInvoicesStatistics().then();
    }
  }, [endDate, getInvoicesStatistics, startDate]);

  const onChangeDate = useCallback((newDate) => {
    setStartDate(newDate[0]);
    setEndDate(newDate[1]);
  }, []);

  const downloadCsv = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.downloadCsvStatisticsInfos,
      method: HttpMethod.POST,
      body: {
        startTime: startDate,
        endTime: endDate,
      },
      responseType: "blob",
    });
    console.log("response", response);
    const filename =
      response?.headers?.["content-disposition"]
        ?.split("filename=")?.[1]
        ?.replace(/"/g, "") || "statistics_table.xlsx";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }, [endDate, startDate]);

  const downloadFounderPath = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.downloadFounderPath,
      method: HttpMethod.POST,
      responseType: "blob",
    });
    console.log("response", response);
    const filename =
      response?.headers?.["content-disposition"]
        ?.split("filename=")?.[1]
        ?.replace(/"/g, "") || "founderpath.csv";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }, []);

  return {
    data,
    startDate,
    endDate,
    onChangeDate,
    downloadCsv,
    downloadFounderPath,
  };
};

export default useStatisticsTable;
