import { createSlice } from "@reduxjs/toolkit";
import {
  PlatformDataState,
  SetPlatformDataAction,
  SetSettings,
} from "./platform-data.interfaces";
import * as selectors from "./platform-data.selectors";

export const platformDataStore = createSlice({
  name: "platformData",
  initialState: {
    countries: [],
    settings: [],
    categories: [],
    subCategories: [],
    brands: [],
  } as PlatformDataState,
  reducers: {
    setPlatformData: (state: any, { payload }: SetPlatformDataAction) => {
      state.countries = payload.countries.sort((a: any, b: any) => {
        if (a?.country > b?.country) return 1;
        if (a?.country < b?.country) return -1;
        return 0;
      });
      state.settings = payload.settings;
      state.categories = payload.categories;
      state.subCategories = payload.subCategories;
      state.brands = payload.brands.sort((a: any, b: any) => {
        if (a?.name > b?.name) return 1;
        if (a?.name < b?.name) return -1;
        return 0;
      });
      return state;
    },
    setSettings: (state: any, { payload }: SetSettings) => {
      state.settings = payload.settings;
      return state;
    },
  },
});

export { selectors };
