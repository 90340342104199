export const TOKEN_LIFE = 7 * 24 * 60 * 60; // 7 days
export const ADMIN_TOKEN_LIFE = 7 * 24 * 60 * 60; // 7 days
export const TOKEN_NAME = "token";
export const ADMIN_TOKEN_NAME = "admin_token";

export const LANGUAGES: { [index: string]: any } = {
  en_US: { title: "English", src: "/en_US.png" },
  it_IT: { title: "Italiana", src: "/it_IT.png" },
  fr_FR: { title: "Français", src: "/fr_FR.png" },
  es_ES: { title: "Español", src: "/es_ES.png" },
};

export const FORCED_PAGE_MAPPING = {
  payment: {
    completed: "payment",
    error: "payment",
    authorize: "payment",
  },
  payment_plan: {
    completed: "payment_plan",
    error: "payment_plan",
    authorize: "payment_plan",
  },
  profile: {
    title: "profile",
    tab: "profile",
    plan: "profile",
    user: "profile",
    list: "profile",
    password: "profile",
    address: "profile",
    invoice: "profile",
    ecredit: "profile",
  },
  channel: {
    settings: "channel.edit",
  },
  auth: {
    howDidYouDiscoverBDroppy: "auth",
    alreadySell: "auth",
  },
};

export const STATUSES = [
  { label: "PENDING", value: 5 },
  { label: "ENABLED", value: 1 },
  { label: "TRIAL", value: 2 },
  { label: "DISABLED", value: 3 },
  { label: "EXPIRED", value: 4 },
  { label: "VALIDATING_COMPANY", value: 4 },
];

export const ORDER_STATUSES = [
  { label: "PENDING", value: "0" },
  { label: "PAYMENT WAITING", value: "1" },
  { label: "CONFIRMED", value: "2" },
  { label: "DISPATCHED", value: "3" },
  { label: "BOOKED", value: "5" },
  { label: "CANCELED", value: "2000" },
  { label: "NO AVAILABLE", value: "2001" },
  { label: "UNKNOWN", value: "2002" },
  { label: "API ERROR", value: "2003" },
  { label: "WORKING ON", value: "3001" },
  { label: "READY", value: "3002" },
];

export const ORDER_PAYMENT = [
  { label: "ALL", value: "" },
  { label: "PAID", value: true },
  { label: "NOT PAID", value: false },
];

export const ROLES = [
  "free",
  "dropfile",
  "droppro",
  "dropplus",
  "dropeasy",
  "dropeasyplus",
  "dropeasyenterprise",
  "shopify",
  "facebook",
  "logistic",
  "admin",
  "photomanager",
  "easydroppy",
  "droppylight",
  "droppybasic",
  "droppyplus",
  "droppystarter",
  "bsocial",
  "dropeasysite",
  "shopify-trial",
];

export const ORDER_RETURN_STATUSES: { [index: number]: any } = {
  0: { label: "Request sent, waiting for approval", className: "pending" },
  1: {
    label: "Request authorized, shipping changed to customer",
    className: "authorized_c",
  },
  2: {
    label: "Request authorized, shipping payed by supplier",
    className: "authorized",
  },
  3: { label: "Return received in good condition", className: "received_ok" },
  4: { label: "Return received in poor condition", className: "received_nok" },
  5: { label: "Return not received", className: "not_received" },
  7: { label: "Return not authorized", className: "refused" },
  9: { label: "Return processing completed", className: "completed" },
};

export const TURNKEY_STATUSES = [
  "FORM_MISSING",
  "DNS_WAITING",
  "WORKING_ON",
  "DELIVERED",
  "REFUNDED",
  "RECURRING_CHARGE_FAIL",
];

export const CHANNEL_COMPONENT = [
  "woocommercePlugin",
  "turnkey",
  "prestashopPlugin",
  // "shopifyPlugin",
  "ebay",
  "amazon",
  "WIX",
  "SQUARESPACE",
  "ECWID",
  "socialEcommerce",
  "SHOPIFYBRANDSSYNC",
  "SHOPIFY",
];

export const REFUND_STATUS_LABELS = [
  "pending",
  "processing",
  "not_received",
  "received",
];

export const TIMEZONES = [
  {
    zone: "Pacific/Midway",
    gmt: "(GMT-11:00)",
    value: "-11:00",
    name: "Midway Island",
    dif: +(11 * 60 * 60),
  },
  {
    zone: "US/Hawaii",
    gmt: "(GMT-10:00)",
    value: "-10:00",
    name: "Hawaii",
    dif: +(10 * 60 * 60),
  },
  {
    zone: "US/Alaska",
    gmt: "(GMT-09:00)",
    value: "-09:00",
    name: "Alaska",
    dif: +(9 * 60 * 60),
  },
  {
    zone: "US/Pacific",
    gmt: "(GMT-08:00)",
    value: "-08:00",
    name: "Pacific Time (US &amp; Canada)",
    dif: -(8 * 60 * 60),
  },
  {
    zone: "America/Mazatlan",
    gmt: "(GMT-07:00)",
    value: "-07:00",
    name: "Mazatlan",
    dif: -(7 * 60 * 60),
  },
  {
    zone: "US/Central",
    gmt: "(GMT-06:00)",
    value: "-06:00",
    name: "Central Time (US &amp; Canada)",
    dif: -(6 * 60 * 60),
  },
  {
    zone: "America/Lima",
    gmt: "(GMT-05:00)",
    value: "-05:00",
    name: "Lima",
    dif: +(5 * 60 * 60),
  },
  {
    zone: "America/Caracas",
    gmt: "(GMT-04:30)",
    value: "-04:30",
    name: "Caracas",
    dif: +(4.5 * 60 * 60),
  },
  {
    zone: "America/Santiago",
    gmt: "(GMT-04:00)",
    value: "-04:00",
    name: "Santiago",
    dif: +(4 * 60 * 60),
  },
  {
    zone: "Canada/Newfoundland",
    gmt: "(GMT-03:30)",
    value: "-03:30",
    name: "Newfoundland",
    dif: +(3.5 * 60 * 60),
  },
  {
    zone: "Greenland",
    gmt: "(GMT-03:00)",
    value: "-03:00",
    name: "Greenland",
    dif: +(3 * 60 * 60),
  },
  {
    zone: "Atlantic/Stanley",
    gmt: "(GMT-02:00)",
    value: "-02:00",
    name: "Stanley",
    dif: +(2 * 60 * 60),
  },
  {
    zone: "Atlantic/Cape_Verde",
    gmt: "(GMT-01:00)",
    value: "-01:00",
    name: "Cape Verde Is.",
    dif: +(1 * 60 * 60),
  },
  {
    zone: "Africa/Monrovia",
    gmt: "(GMT)",
    value: "+00:00",
    name: "Monrovia",
    dif: 0,
  },
  {
    zone: "Europe/Zagreb",
    gmt: "(GMT+01:00)",
    value: "+01:00",
    name: "Zagreb",
    dif: -(1 * 60 * 60),
  },
  {
    zone: "Europe/Vilnius",
    gmt: "(GMT+02:00)",
    value: "+02:00",
    name: "Vilnius",
    dif: -(2 * 60 * 60),
  },
  {
    zone: "Asia/Riyadh",
    gmt: "(GMT+03:00)",
    value: "+03:00",
    name: "Riyadh",
    dif: -(3 * 60 * 60),
  },
  {
    zone: "Asia/Tehran",
    gmt: "(GMT+03:30)",
    value: "+03:30",
    name: "Tehran",
    dif: -(3.5 * 60 * 60),
  },
  {
    zone: "Asia/Yerevan",
    gmt: "(GMT+04:00)",
    value: "+04:00",
    name: "Yerevan",
    dif: -(4 * 60 * 60),
  },
  {
    zone: "Asia/Kabul",
    gmt: "(GMT+04:30)",
    value: "+04:30",
    name: "Kabul",
    dif: -(4.5 * 60 * 60),
  },
  {
    zone: "Asia/Tashkent",
    gmt: "(GMT+05:00)",
    value: "+05:00",
    name: "Tashkent",
    dif: -(5 * 60 * 60),
  },
  {
    zone: "Asia/Kolkata",
    gmt: "(GMT+05:30)",
    value: "+05:30",
    name: "Kolkata",
    dif: -(5.5 * 60 * 60),
  },
  {
    zone: "Asia/Kathmandu",
    gmt: "(GMT+05:45)",
    value: "+05:45",
    name: "Kathmandu",
    dif: -(5.75 * 60 * 60),
  },
  {
    zone: "Asia/Dhaka",
    gmt: "(GMT+06:00)",
    value: "+06:00",
    name: "Dhaka",
    dif: -(6 * 60 * 60),
  },
  {
    zone: "Asia/Jakarta",
    gmt: "(GMT+07:00)",
    value: "+07:00",
    name: "Jakarta",
    dif: -(7 * 60 * 60),
  },
  {
    zone: "Asia/Urumqi",
    gmt: "(GMT+08:00)",
    value: "+08:00",
    name: "Urumqi",
    dif: -(8 * 60 * 60),
  },
  {
    zone: "Asia/Tokyo",
    gmt: "(GMT+09:00)",
    value: "+09:00",
    name: "Tokyo",
    dif: -(9 * 60 * 60),
  },
  {
    zone: "Australia/Darwin",
    gmt: "(GMT+09:30)",
    value: "+09:30",
    name: "Darwin",
    dif: -(9.5 * 60 * 60),
  },
  {
    zone: "Australia/Sydney",
    gmt: "(GMT+10:00)",
    value: "+10:00",
    name: "Sydney",
    dif: -(10 * 60 * 60),
  },
  {
    zone: "Asia/Vladivostok",
    gmt: "(GMT+11:00)",
    value: "+11:00",
    name: "Vladivostok",
    dif: -(11 * 60 * 60),
  },
  {
    zone: "Pacific/Fiji",
    gmt: "(GMT+12:00)",
    value: "+12:00",
    name: "Fiji",
    dif: -(12 * 60 * 60),
  },
];

export const CRON_JOB_INTERVALS = [
  { disabled: true, id: 1, value: 1, name: "5 minute" },
  { disabled: false, id: 2, value: 2, name: "10 minutes" },
  { disabled: false, id: 3, value: 3, name: "15 minutes" },
  { disabled: false, id: 4, value: 4, name: "20 minutes" },
  { disabled: false, id: 5, value: 5, name: "25 minutes" },
  { disabled: false, id: 6, value: 6, name: "30 minutes" },
  { disabled: false, id: 7, value: 7, name: "40 minutes" },
  { disabled: false, id: 8, value: 8, name: "50 minutes" },
  { disabled: false, id: 9, value: 9, name: "1 hour" },
];

export const LOCALES: { [index: string]: any } = {
  it_IT: "Italiano",
  en_US: "English",
  fr_FR: "Français",
  de_DE: "Deutsch",
  el_GR: "Ελληνικά",
  es_ES: "Español",
  pl_PL: "Polski",
  pt_PT: "Português",
  ro_RO: "Română",
  nl_NL: "Nederlands",
  cs_CZ: "Čeština",
  et_EE: "Eesti",
  hu_HU: "Magyar",
  sv_SE: "Svenska",
  sk_SK: "Slovenčina",
  ru_RU: "Русский",
  lt_LT: "Lietuvių",
  fi_FI: "Suomi",
  bg_BG: "Български",
  da_DK: "Dansk",
};

export const MERCHANT_IDS = [
  {
    value: 0,
    label: "IDT",
  },
  {
    value: 60,
    label: "Casio",
  },
];
