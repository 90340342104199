import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format, sub } from "date-fns";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { roundTo } from "utils";

const useStatistics = () => {
  const today = new Date();
  const lastWeek = sub(today, { weeks: 1 });
  const [startDate, setStartDate] = useState<Date>(lastWeek);
  const [endDate, setEndDate] = useState<Date>(today);
  const [items, setItems] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [tab, setTab] = useState(0);
  const [services, setServices] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [countriesProducts, setCountriesProducts] = useState<any[]>([]);
  const [countriesServices, setCountriesServices] = useState<any[]>([]);
  const [connections, setConnections] = useState<any[]>([]);
  const [socials, setSocials] = useState<any[]>([]);
  const [merchants, setMerchants] = useState<any[]>([]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTab(newValue);
    },
    []
  );

  const getInvoicesStatistics = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.getInvoiceStatistics,
      method: HttpMethod.GET,
      query: {
        startTime: format(startDate, "yyyy-MM-dd"),
        endTime: format(endDate, "yyyy-MM-dd"),
      },
    });
    setItems(response?.data?.items);
    setProducts(response?.data?.products);
    setServices(response?.data?.services);
    setBrands(response?.data?.brands);
    setUsers(response?.data?.users);
    setCountriesProducts(response?.data?.countriesProducts);
    setCountriesServices(response?.data?.countriesServices);
    setConnections(response?.data?.connections);
    setSocials(response?.data?.socialResults);
    setMerchants(response?.data?.merchants);
  }, [endDate, startDate]);

  useEffect(() => {
    if (startDate && endDate) {
      getInvoicesStatistics().then();
    }
  }, [endDate, getInvoicesStatistics, startDate]);

  const onChangeDate = useCallback((newDate) => {
    setStartDate(newDate[0]);
    setEndDate(newDate[1]);
  }, []);

  const options = useMemo(() => {
    const categories: any = [];
    const servicesSeries: any = [];
    const productsSeries: any = [];
    const miraviaSeries: any = [];
    const LETSELL = process.env.REACT_APP_ENV === "Letsell";

    items?.forEach((item) => {
      const date = `${item._id.day}-${item._id.month}-${item._id.year}`;
      categories.push(date);
      servicesSeries.push(roundTo(item.total_services));
      productsSeries.push(roundTo(item.total_products));
      if (!LETSELL) miraviaSeries.push(roundTo(item.total_miravia));
    });
    const chart = {
      chart: {
        type: "spline",
      },
      title: {
        text: `Purchase Date ${
          startDate && format(startDate, "dd-MM-yyyy")
        } - ${endDate && format(endDate, "dd-MM-yyyy")}`,
      },
      yAxis: {
        title: {
          text: "Price ( EUR )",
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: "#808080",
          },
        ],
      },
      xAxis: {
        categories,
        type: "datetime",
      },
      tooltip: {
        valueSuffix: "EUR",
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        borderWidth: 0,
      },
      series: [
        {
          name: "Services",
          data: servicesSeries,
        },
        {
          name: "Products",
          data: productsSeries,
        },
      ],
    };
    if (!LETSELL) {
      chart.series.push({
        name: "Miravia",
        data: miraviaSeries,
      });
    }
    return chart;
  }, [endDate, startDate, items]);

  const downloadCsv = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.downloadCsvStatistics,
      method: HttpMethod.GET,
      query: {
        startTime: format(startDate, "yyyy-MM-dd"),
        endTime: format(endDate, "yyyy-MM-dd"),
      },
      responseType: "blob",
    });
    console.log("response", response);
    const filename =
      response?.headers?.["content-disposition"]
        ?.split("filename=")?.[1]
        ?.replace(/"/g, "") || "statistics_table.xlsx";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }, [endDate, startDate]);

  return {
    options,
    startDate,
    endDate,
    onChangeDate,
    downloadCsv,
    products,
    tab,
    handleChange,
    services,
    brands,
    users,
    countriesProducts,
    countriesServices,
    connections,
    socials,
    merchants,
  };
};

export default useStatistics;
