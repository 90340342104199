import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Box, Container } from "@mui/material";
import { ConnectedRouter } from "connected-react-router";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Profile from "scenes/Profile";
import Service from "scenes/Service";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import style from "./index.style";
import { history, RootState } from "./redux/store";
import AddressManagement from "./scenes/AddressManagement";
import BillsAndCustomers from "./scenes/BillsAndCustomers";
import InvoicesManagement from "./scenes/InvoicesManagement";
import Login from "./scenes/Login";
import Logs from "./scenes/Logs";
import Orders from "./scenes/Orders";
import Plans from "./scenes/Plans";
import RateManagement from "./scenes/RateManagement";
import ReturnsManagement from "./scenes/ReturnsManagement";
import Statistics from "./scenes/Statistics";
import TagsManagement from "./scenes/TagsManagement";
import Translations from "./scenes/Translations";
import Turnkey from "./scenes/Turnkey";
import UserManagement from "./scenes/UserManagement";
import VatManagement from "./scenes/VatManagement";
import OrdersServices from "./scenes/OrdersServices";
import StatisticsTable from "./scenes/StatisticsTable";
import Settings from "./scenes/Settings";
import CronJob from "./scenes/CronJob";
import LightsailManagement from "./scenes/LightsailManagement";
import Ecredit from "./scenes/Ecredit";
import CatalogsManagement from "./scenes/CatalogsManagement";
import PhotoDownload from "./scenes/PhotoDownload";
import Vouchers from "./scenes/Vouchers";
import SidebarManagement from "./scenes/SidebarManagement";

function App() {
  const dispatch = useDispatch();
  const location = useSelector(
    (state: RootState) => state.router.location.pathname
  );
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  useEffect(() => {
    dispatch({
      type: "GET_USER_SAGA",
    });
  }, [dispatch]);
  return (
    <ConnectedRouter history={history}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        {location !== "/login" && (
          <>
            <Header handleDrawer={handleDrawerToggle} />
            <Sidebar
              mobileOpen={mobileOpen}
              handleDrawer={handleDrawerToggle}
            />
          </>
        )}
        <Container sx={style.mainContainer}>
          <Box>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/translations">
                <Translations />
              </Route>
              <Route path="/statistics">
                <Statistics />
              </Route>
              <Route path="/statistics-table">
                <StatisticsTable />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/users">
                <UserManagement />
              </Route>
              <Route path="/bills">
                <BillsAndCustomers />
              </Route>
              <Route path="/plans">
                <Plans />
              </Route>
              <Route path="/service">
                <Service />
              </Route>
              <Route path="/returns">
                <ReturnsManagement />
              </Route>
              <Route path="/rate-management">
                <RateManagement />
              </Route>
              <Route path="/vat-management">
                <VatManagement />
              </Route>
              <Route path="/tags">
                <TagsManagement />
              </Route>
              <Route path="/logs">
                <Logs />
              </Route>
              <Route path="/turnkey">
                <Turnkey />
              </Route>
              <Route path="/orders">
                <Orders />
              </Route>
              <Route path="/orders-services">
                <OrdersServices />
              </Route>
              <Route path="/address-management">
                <AddressManagement />
              </Route>
              <Route path="/invoices-management">
                <InvoicesManagement />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/cron-job">
                <CronJob />
              </Route>
              <Route path="/lightsail-management">
                <LightsailManagement />
              </Route>
              <Route path="/ecredit">
                <Ecredit />
              </Route>
              <Route path="/catalogs">
                <CatalogsManagement />
              </Route>
              <Route path="/photo-download">
                <PhotoDownload />
              </Route>
              <Route path="/vouchers">
                <Vouchers />
              </Route>
              <Route path="/sidebar">
                <SidebarManagement />
              </Route>
              <Route path="/">
                <Statistics />
              </Route>
            </Switch>
          </Box>
        </Container>
      </Box>
    </ConnectedRouter>
  );
}

export default App;
