import React, { memo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import useStatistics from "./index.hooks";
import ProductsTable from "./ProductsTable";
import CustomTabs from "../../components/CustomTabs";
import SocialsTable from "./SocialsTable";

type StatisticsProps = {};

const Statistics = memo(({}: StatisticsProps) => {
  const {
    options,
    startDate,
    endDate,
    onChangeDate,
    downloadCsv,
    products,
    tab,
    handleChange,
    services,
    brands,
    users,
    countriesProducts,
    countriesServices,
    connections,
    socials,
    merchants,
  } = useStatistics();

  return (
    <Box sx={{ gap: 4, display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="Start date"
            endText="End date"
            value={[startDate, endDate]}
            onChange={onChangeDate}
            inputFormat="dd-MM-yyyy"
            mask="__-__-____"
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
        <Button variant="contained" onClick={downloadCsv}>
          Download csv
        </Button>
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <CustomTabs
        value={tab}
        handleChange={handleChange}
        tabs={[
          "Merchants",
          "Socials",
          "Products",
          "Services",
          "Brands",
          "Users",
          "Countries products",
          "Countries services",
          "Connections",
        ]}
        items={[
          <ProductsTable products={merchants} />,
          <SocialsTable socials={socials} />,
          <ProductsTable products={products} />,
          <ProductsTable products={services} />,
          <ProductsTable products={brands} />,
          <ProductsTable products={users} />,
          <ProductsTable
            products={countriesProducts}
            type="countriesProducts"
          />,
          <ProductsTable products={countriesServices} />,
          <ProductsTable products={connections} />,
        ]}
      />
    </Box>
  );
});

export default Statistics;
