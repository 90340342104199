import { useCallback, useEffect, useState } from "react";

import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";

type useUseEcreditModalContentProps = {
  user: any;
  closeModal: () => void;
};

const useUseEcreditModalContent = ({
  user,
  closeModal,
}: useUseEcreditModalContentProps) => {
  const [editingUser, setEditingUser] = useState<any>();
  const [addCashback, setAddCashback] = useState<boolean>(false);
  useEffect(() => {
    setEditingUser(user);
  }, [user]);
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempUser = { ...editingUser };
      tempUser[key] = value;
      setEditingUser(tempUser);
    },
    [editingUser]
  );

  const handleAddCashback = useCallback(() => {
    setAddCashback((prevState) => !prevState);
  }, []);

  const addEcredit = useCallback(async () => {
    await request({
      path: `${ENDPOINTS.user}/${user._id}/ecredit_transaction`,
      method: HttpMethod.POST,
      body: {
        amount: editingUser?.amount,
        fullNumber: editingUser?.fullNumber,
        description: editingUser?.description,
        addCashback,
      },
    });
    closeModal();
  }, [
    user._id,
    editingUser?.amount,
    editingUser?.fullNumber,
    editingUser?.description,
    addCashback,
    closeModal,
  ]);

  return {
    handleChangeValue,
    editingUser,
    addEcredit,
    addCashback,
    handleAddCashback,
  };
};

export default useUseEcreditModalContent;
