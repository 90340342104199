import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  Button,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import useEditModalContent from "./index.hooks";
import style from "./index.style";

type EditModalContentProps = {
  carrier: any;
  closeModal: () => void;
  getCarriers: any;
  isEdit: boolean;
};

const EditModalContent = memo(
  ({ carrier, closeModal, getCarriers, isEdit }: EditModalContentProps) => {
    const { handleChangeValue, editingCarrier, updateCarrier, deleteCarrier } =
      useEditModalContent({ carrier, getCarriers, closeModal, isEdit });
    return (
      <Box sx={style.modal}>
        <Grid container spacing={3}>
          <>
            <IconButton sx={style.modal.closeButton} onClick={closeModal}>
              <CloseIcon />
            </IconButton>
            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit carrier
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                value={editingCarrier?.name}
                onChange={(ev) => handleChangeValue(ev.target.value, "name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Logo"
                value={editingCarrier?.logo}
                onChange={(ev) => handleChangeValue(ev.target.value, "logo")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Note"
                value={editingCarrier?.note}
                onChange={(ev) => handleChangeValue(ev.target.value, "note")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Days Literal"
                value={editingCarrier?.daysLiteral}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "daysLiteral")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Days"
                value={editingCarrier?.days}
                onChange={(ev) => handleChangeValue(ev.target.value, "days")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Ref ID"
                value={editingCarrier?.refId}
                onChange={(ev) => handleChangeValue(ev.target.value, "refId")}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editingCarrier?.online as boolean}
                    onChange={() =>
                      handleChangeValue(!editingCarrier?.online, "online")
                    }
                  />
                }
                label="Online"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editingCarrier?.cod as boolean}
                    onChange={() =>
                      handleChangeValue(!editingCarrier?.cod, "cod")
                    }
                  />
                }
                label="Cash on delivery"
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" size="large" onClick={updateCarrier}>
                {isEdit ? "Save" : "Create"}
              </Button>
            </Grid>
            <Grid item xs={3}>
              {isEdit && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={deleteCarrier}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </>
        </Grid>
      </Box>
    );
  }
);

export default EditModalContent;
