import axios, { AxiosError, ResponseType } from "axios";
import { Action } from "redux";
import { getCookie } from "utils/cookie";
import { TOKEN_NAME } from "constants/contants";
import { getQueryString } from "utils";
import store, { actions } from "../../store";
import { FeedbackTypes } from "../../../components/Feedback";

export enum HttpMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

interface RequestInterface {
  method?: HttpMethod;
  absolutePath?: boolean;
  body?: object;
  query?: object;
  responseType?: ResponseType;
  path: string;
  pathParams?: any;
  additionalHeaders?: any;
  disableSuccessFeedback?: boolean;
}

interface ApiSuccessData<T> {
  status: number;
  data: T;
}

export interface ApiSuccessAction<T> extends Action {
  payload: ApiSuccessData<T>;
  data?: any;
}

export default async function request({
  method = HttpMethod.GET,
  responseType = "json",
  absolutePath,
  body,
  query,
  path,
  pathParams,
  additionalHeaders = null,
  disableSuccessFeedback = false,
}: RequestInterface) {
  let response;
  const token = getCookie(TOKEN_NAME);
  let headers: any = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (additionalHeaders) {
    headers = {
      ...headers,
      ...additionalHeaders,
    };
  }
  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      path = path.replace(`{${param}}`, pathParams[param]);
    });
  }
  store.dispatch(
    actions.setApiLoading({
      api: path,
      isLoading: true,
    })
  );
  try {
    response = await axios({
      url: absolutePath
        ? path
        : `${process.env.REACT_APP_REPROXY_URL}/${path}${getQueryString(
            query
          )}`,
      method,
      data: body,
      headers,
      responseType,
    });

    if (response) {
      store.dispatch({
        type: `${path}/SUCCESS`,
        payload: {
          status: response?.status,
          data: response?.data,
        },
      });
      store.dispatch(
        actions.setApiLoading({
          api: path,
          isLoading: false,
        })
      );
      if (
        (method === HttpMethod.DELETE ||
          method === HttpMethod.PUT ||
          method === HttpMethod.POST) &&
        !disableSuccessFeedback
      ) {
        store.dispatch(
          actions.setFeedback({
            type: FeedbackTypes.Success,
            message: "Operazione eseguita con successo",
          })
        );
      }
    }
  } catch (e) {
    const axiosError = e as AxiosError;
    if (!axios.isCancel(axiosError)) {
      const status = axiosError?.response?.status || 500;
      const message: string =
        axiosError?.response?.data?.message ||
        axiosError?.response?.data?.code ||
        axiosError.message;
      store.dispatch({
        type: `${path}/FAIL`,
        payload: {
          status,
          message,
        },
      });
      store.dispatch(
        actions.setApiLoading({
          api: path,
          isLoading: false,
        })
      );
      store.dispatch(
        actions.setFeedback({
          type: FeedbackTypes.Error,
          message,
        })
      );
      response = {
        status,
        message,
      };
    }
  }
  return response;
}
