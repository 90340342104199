import { useCallback, useEffect, useState } from "react";
import request, { HttpMethod } from "../../../redux/slices/ajax/request";
import ENDPOINTS from "../../../constants/endpoints";

type useOrderActionsProps = {
  order: any;
  updateOrders: () => void;
  closeParentModal: () => void;
};

export enum OrderAction {
  CONFIRM = "confirm",
  CANCEL = "cancel",
  RETURN = "return",
}

const useOrderActions = ({
  order,
  updateOrders,
  closeParentModal,
}: useOrderActionsProps) => {
  const { paid } = order;
  const hasInvoice = !!paid;
  const [modalOpen, setModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState("0");
  useEffect(() => {
    setEditingItem(order.status);
  }, [order.status]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    closeParentModal();
  }, [closeParentModal]);
  const statuses: { [key: string]: any } = {
    PENDING: "0",
    CANCELLED: "2000",
    WORKING_ON: "3001",
    READY: "3002",
    DISPATCHED: "3",
  };
  const updateStatus = useCallback(async () => {
    await request({
      path: `${ENDPOINTS.orders}/${order._id}`,
      method: HttpMethod.PUT,
      body: {
        status: editingItem,
      },
    });
    updateOrders();
    handleCloseModal();
  }, [editingItem, handleCloseModal, order._id, updateOrders]);

  const downloadInvoice = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.downloadOrderInvoice,
      method: HttpMethod.GET,
      additionalHeaders: {
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      responseType: "blob",
      pathParams: {
        orderId: order._id,
      },
    });
    const filename =
      response?.headers?.["content-disposition"]
        ?.split("filename=")?.[1]
        .replace(/"/g, "") || "Bill.pdf";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    closeParentModal();
    handleCloseModal();
  }, [closeParentModal, handleCloseModal, order._id]);

  return {
    setModalOpen,
    modalOpen,
    handleCloseModal,
    hasInvoice,
    downloadInvoice,
    editingItem,
    setEditingItem,
    statuses,
    updateStatus,
  };
};

export default useOrderActions;
