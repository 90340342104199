import globalStyle from "index.style";

const useStyles = {
  background: {
    background: "white",
    padding: "30px 58px 30px 10px",
    borderRadius: 2,
    boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    marginBottom: "30px",
  },
} as const;

export default { ...globalStyle, ...useStyles };
