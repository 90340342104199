import { useCallback, useEffect, useState } from "react";
import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";

type useEditModalContentProps = {
  item: any;
  getItems: any;
  closeModal: () => void;
  newCron: boolean;
  setNewCron: (isNew: boolean) => void;
};

const useEditModalContent = ({
  item,
  getItems,
  closeModal,
  newCron,
  setNewCron,
}: useEditModalContentProps) => {
  const [editingItem, setEditingItem] = useState<any>();

  useEffect(() => {
    setEditingItem(item);
  }, [item]);
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempItem = { ...editingItem };
      tempItem[key] = value;
      setEditingItem(tempItem);
    },
    [editingItem]
  );

  const deleteItem = useCallback(async () => {
    await request({
      path: `${ENDPOINTS.userCron}/${editingItem?._id}`,
      method: HttpMethod.DELETE,
    });
    setNewCron(false);
    closeModal();
    await getItems();
  }, [closeModal, editingItem?._id, getItems, setNewCron]);

  const updateItem = useCallback(async () => {
    let response: any = {};
    if (newCron) {
      response = await request({
        path: `${ENDPOINTS.userCron}`,
        method: HttpMethod.POST,
        body: {
          description: editingItem?.description,
          interval: editingItem?.interval,
          name: editingItem?.name,
          saveResponse: editingItem?.saveResponse,
          status: editingItem?.status,
          url: editingItem?.url,
        },
      });
    } else {
      response = await request({
        path: `${ENDPOINTS.userCron}/${editingItem?._id}`,
        method: HttpMethod.PUT,
        body: {
          description: editingItem?.description,
          interval: editingItem?.interval,
          name: editingItem?.name,
          saveResponse: editingItem?.saveResponse,
          status: editingItem?.status,
          url: editingItem?.url,
        },
      });
    }
    if (response.status === 200) {
      // Apro feedback success
      setNewCron(false);
      closeModal();
    }
    await getItems();
  }, [
    closeModal,
    editingItem?._id,
    editingItem?.description,
    editingItem?.interval,
    editingItem?.name,
    editingItem?.saveResponse,
    editingItem?.status,
    editingItem?.url,
    getItems,
    newCron,
    setNewCron,
  ]);
  return {
    handleChangeValue,
    editingItem,
    updateItem,
    deleteItem,
  };
};

export default useEditModalContent;
