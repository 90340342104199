import React, { useCallback, useEffect, useMemo, useState } from "react";
import ENDPOINTS from "constants/endpoints";
import request from "redux/slices/ajax/request";

const usePlans = () => {
  const [plans, setPlans] = useState<any>([]);
  const [tab, setTab] = useState(0);
  const [onlineTab, setOnlineTab] = useState(0);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTab(newValue);
    },
    []
  );

  const getTabs = useMemo(() => plans.map((plan: any) => plan.name), [plans]);

  useEffect(() => {
    (async () => {
      let response: any = {};
      response = await request({
        path: ENDPOINTS.getPlans,
        query: {
          acceptedlocales: "it_IT",
          page: 1,
          pageSize: 1000,
          type: "S",
          online: onlineTab === 0 ? "true" : "false",
          category: "servizi",
          subcategory: "subscription",
        },
      });
      const data = response?.data;
      const _data = {
        items: [],
      };
      await Promise.all(
        data.items.map(async (d: any) => {
          response = await request({
            path: `${ENDPOINTS.products}/${d._id}`,
          });
          // @ts-ignore
          _data.items.push(response?.data);
        })
      );
      _data.items = _data.items.sort((d1: any, d2: any) => {
        if (d1._id > d2._id) return -1;
        if (d1._id < d2._id) return 1;
        return 0;
      });
      setPlans(_data.items || []);
    })();
  }, [onlineTab]);
  return { plans, handleChange, getTabs, tab, onlineTab, setOnlineTab };
};

export default usePlans;
