import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Drawer,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import style from "./index.style";
import useCronJobs from "./index.hooks";
import { getFormattedDateWithTime } from "../../../utils";
import { CRON_JOB_INTERVALS } from "../../../constants/contants";
import EditModalContent from "./EditModalContent";

type CronJobsProps = {
  setCronId: (cronId: string) => void;
};

const CronJobs = memo(({ setCronId }: CronJobsProps) => {
  const {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    modalOpen,
    handleOpenModal,
    handleFiltersModal,
    filtersModalOpen,
    filters,
    dispatchFilters,
    resetFilters,
    handleCloseModal,
    editingItem,
    newCron,
    setNewCron,
    setShouldFilter,
  } = useCronJobs();

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <EditModalContent
          item={editingItem}
          closeModal={handleCloseModal}
          getItems={getItems}
          newCron={newCron}
          setNewCron={setNewCron}
        />
      </Modal>
      <Drawer
        anchor="right"
        open={filtersModalOpen}
        onClose={() => {
          setShouldFilter(true);
          handleFiltersModal();
        }}
      >
        <Box sx={style.filters}>
          <TextField
            label="Name"
            value={filters?.name}
            onChange={(ev) =>
              dispatchFilters({ type: "name", payload: ev.target.value })
            }
            fullWidth
          />
          <TextField
            label="Description"
            value={filters?.description}
            onChange={(ev) =>
              dispatchFilters({ type: "description", payload: ev.target.value })
            }
            fullWidth
          />
          <TextField
            label="Url"
            value={filters?.url}
            onChange={(ev) =>
              dispatchFilters({ type: "url", payload: ev.target.value })
            }
            fullWidth
          />
          <TextField
            label="User email"
            value={filters?.email}
            onChange={(ev) =>
              dispatchFilters({ type: "email", payload: ev.target.value })
            }
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="interval-label">Interval</InputLabel>
            <Select
              labelId="interval-label"
              id="interval-status"
              label="Interval"
              value={filters?.interval}
              onChange={(ev) =>
                dispatchFilters({ type: "interval", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                ALL
              </MenuItem>
              {CRON_JOB_INTERVALS.map((interval: any) => (
                <MenuItem key={interval.id} value={interval.id}>
                  {interval.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-status"
              label="Status"
              value={filters?.status}
              onChange={(ev) =>
                dispatchFilters({ type: "status", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                ALL
              </MenuItem>
              <MenuItem key="enabled" value={1}>
                Enabled
              </MenuItem>
              <MenuItem key="disabled" value={0}>
                Disabled
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="save-response-label">Save Response</InputLabel>
            <Select
              labelId="save-response-label"
              id="save-response-status"
              label="Save Response"
              value={filters?.saveResponse}
              onChange={(ev) =>
                dispatchFilters({
                  type: "saveResponse",
                  payload: ev.target.value,
                })
              }
            >
              <MenuItem key="all" value="">
                ALL
              </MenuItem>
              <MenuItem key="enabled" value={true as any}>
                Enabled
              </MenuItem>
              <MenuItem key="disabled" value={false as any}>
                Disabled
              </MenuItem>
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start date"
              value={filters.startDate}
              onChange={(newDate) =>
                dispatchFilters({
                  type: "startDate",
                  payload: newDate,
                })
              }
              inputFormat="dd-MM-yyyy"
              mask="__-__-____"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            <DatePicker
              label="End date"
              value={filters.endDate}
              onChange={(newDate) =>
                dispatchFilters({
                  type: "endDate",
                  payload: newDate,
                })
              }
              inputFormat="dd-MM-yyyy"
              mask="__-__-____"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={() => {
              handleFiltersModal();
              setShouldFilter(true);
            }}
          >
            Filter
          </Button>
          <Button variant="outlined" onClick={resetFilters}>
            Reset
          </Button>
        </Box>
      </Drawer>
      <Box sx={style.user.wrapper}>
        <Box sx={style.user.action}>
          <Button
            variant="contained"
            size="large"
            onClick={() => handleOpenModal(true)}
          >
            <AddIcon />
            <Typography>Create</Typography>
          </Button>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => {
              setShouldFilter(false);
              handleFiltersModal();
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Box>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>User Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Interval</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Save Response</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, index) => (
                <TableRow key={item?._id}>
                  <TableCell>{item?.user?.email}</TableCell>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>
                    {getFormattedDateWithTime(item?.createdAt)}
                  </TableCell>
                  <TableCell>{item?.description}</TableCell>
                  <TableCell>
                    {
                      CRON_JOB_INTERVALS?.find(
                        (cron) => cron.id === item?.interval
                      )?.name
                    }
                  </TableCell>
                  <TableCell>
                    {item?.status === 1 ? "Enabled" : "Disabled"}
                  </TableCell>
                  <TableCell>
                    {item?.saveResponse ? "Enabled" : "Disabled"}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleOpenModal(false, item)}
                      >
                        <EditIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setCronId(item?._id)}
                      >
                        Logs
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default CronJobs;
