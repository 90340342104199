import globalStyle from "index.style";

const useStyles = {
  wrapperClassName: {
    border: "solid thin #92a0ae",
    mb: "10px",
  },
  bannersGallery: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  imagesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 3,
  },
  img: {
    width: 230,
  },
  imageContainer: {
    position: "relative",
    width: 300,
    background: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  bannerInfo: {
    opacity: 0.8,
    background: "black",
    color: "white",
    border: "1px solid white",
    borderRadius: 3,
    padding: "4px",
    fontSize: 12,
  },
  bannerName: {
    top: 8,
    left: 8,
  },
  bannerStatus: {
    height: 30,
  },
  bannerPriority: {
    bottom: 14,
    left: 8,
  },
  bannerEditIcon: {
    right: 8,
    bottom: 14,
  },
  editContainer: {
    padding: "30px",
  },
} as const;

export default { ...globalStyle, ...useStyles };
