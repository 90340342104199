import globalStyle from "index.style";

const useStyles = {
  background: {
    background: "white",
    padding: "30px 58px 30px 10px",
    borderRadius: 2,
    boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    marginBottom: "30px",
  },
  mainContainer: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  },
  childContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: 0,
    marginTop: 10,
    gap: "5px",
  },
  arrowForward: {
    cursor: "pointer",
    transition: "transform 0.2s",
    fontSize: 18,
  },
  nameBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 16px",
    backgroundColor: "white",
  },
  cardContent: {
    backgroundColor: "#dadcdf",
    display: "flex",
    alignItems: "center",
    color: "white",
    height: "48px",
    width: "48px",
    justifyContent: "center",
  },
  cardContainer: {
    display: "flex",
    cursor: "grab",
    alignItems: "center",
    width: "100%",
    border: "1px solid #dadcdf",
    borderRadius: "10px",
    overflow: "hidden",
    background: "white",
  },
  childCardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    paddingLeft: "48px",
  },
  icon: {
    cursor: "pointer",
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  newItemContainer: {
    display: "flex",
    marginLeft: "48px",
    border: "1px solid #dadcdf",
    borderRadius: "10px",
    padding: "16px 22px",
    marginTop: "10px",
  },
  editItemContainer: {
    display: "flex",
    borderRadius: "10px",
    padding: "16px 22px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    paddingLeft: "48px",
    gap: "10px",
    marginBottom: "30px",
  },
} as const;

export default { ...globalStyle, ...useStyles };
