import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "redux/slices";
import { useEffect } from "react";
import { FeedbackTypes } from "./index";

const useFeedback = () => {
  const open = useSelector(selectors.getFeedbackOpen);
  const type = useSelector(selectors.getFeedbackType);
  const message = useSelector(selectors.getFeedbackMessage);
  const dispatch = useDispatch();
  useEffect(() => {
    let timer: any;
    if (open) {
      timer = setTimeout(() => {
        dispatch(actions.setFeedback({}));
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open, dispatch]);

  const getSeverity = () => {
    switch (type) {
      case FeedbackTypes.Success:
        return "success";
      case FeedbackTypes.Info:
        return "info";
      default:
        return "error";
    }
  };

  return { open, getSeverity, message };
};

export default useFeedback;
