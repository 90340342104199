import React, { memo, useCallback, useMemo, useState } from "react";

import { Box } from "@mui/system";
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import { DragHandle, ArrowForwardIos } from "@mui/icons-material";
import { Switch } from "@mui/material";
import style from "./index.style";

interface SidebarCardProps {
  item: any;
  expand: any;
  openItems: any;
  onUpdate: any;
}

const SidebarCard = memo(
  ({ item, expand, openItems, onUpdate }: SidebarCardProps) => {
    const isVisible = useMemo(
      () => openItems.includes(`${item.tag}-${item.value}`),
      [item.tag, item.value, openItems]
    );

    const handleChangeHidden = useCallback(() => {
      const tmpItem = { ...item };
      tmpItem.hidden = !tmpItem.hidden;
      onUpdate(tmpItem);
    }, [item, onUpdate]);

    const handleDrop = useCallback(
      // @ts-ignore
      ({ removedIndex, addedIndex }) => {
        if (item?.children) {
          const updatedChildren = arrayMoveImmutable(
            item.children,
            removedIndex,
            addedIndex
          );
          const updatedItem = {
            ...item,
            children: updatedChildren,
          };
          onUpdate(updatedItem);
        }
      },
      [item, onUpdate]
    );

    const onChildDrop = useCallback(
      (updatedChild) => {
        const updatedIndex = item.children.findIndex(
          (childItem: any) => childItem.value === updatedChild.value
        );
        if (updatedIndex !== -1) {
          const updatedChildren = [...item.children]; // Crea una copia dell'array
          updatedChildren[updatedIndex] = updatedChild; // Modifica l'elemento nella copia
          const updatedTmpItem = {
            ...item,
            children: updatedChildren, // Aggiorna la copia con i nuovi figli
          };
          onUpdate(updatedTmpItem); // Passa la copia aggiornata alla funzione onDrop
        }
      },
      [item, onUpdate]
    );

    return (
      <Box
        sx={[style.childCardContainer, { display: item?.active ? "" : "none" }]}
      >
        <Box sx={[style.cardContainer, !item?.active && style.itemOffline]}>
          <Box sx={style.cardContent}>
            <DragHandle sx={{ fontSize: "32px" }} />
          </Box>
          <Box sx={style.cardActions}>
            <Box sx={style.nameBox}>
              {!!item?.children?.length && (
                <ArrowForwardIos
                  onClick={() => expand(item)}
                  sx={[
                    style.arrowForward,
                    {
                      transform: isVisible
                        ? "rotate(90deg)"
                        : "translate(0deg)",
                    },
                  ]}
                />
              )}
              <Box>{item?.labels?.it_IT || item?.value}</Box>
            </Box>
            <Switch
              checked={!item?.hidden}
              onChange={handleChangeHidden}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </Box>
        <Container
          lockAxis="y"
          onDrop={handleDrop}
          getChildPayload={(i) => item.children[i]}
          style={style.childContainer}
        >
          {isVisible &&
            item?.children?.map((child: any) => (
              <Draggable>
                <SidebarCard
                  key={`${item.tag}-${item.value}`}
                  item={child}
                  onUpdate={onChildDrop}
                  expand={expand}
                  openItems={openItems}
                />
              </Draggable>
            ))}
        </Container>
      </Box>
    );
  }
);

interface SidebarMainProps {
  sidebar: any;
  setSidebar: any;
}

const SidebarMain = memo(({ sidebar, setSidebar }: SidebarMainProps) => {
  const [openItems, setOpenItems] = useState([]);
  const expand = useCallback(
    (item: any) => {
      const value = `${item.tag}-${item.value}`;
      const tmpOpenItems = [...openItems];
      // @ts-ignore
      if (tmpOpenItems.includes(value)) {
        tmpOpenItems.splice(
          tmpOpenItems.findIndex((el: any) => el === value),
          1
        );
      } else {
        // @ts-ignore
        tmpOpenItems.push(value);
      }
      setOpenItems(tmpOpenItems);
    },
    [openItems, setOpenItems]
  );

  // @ts-ignore
  const onSidebarDrop = ({ removedIndex, addedIndex }) => {
    setSidebar((items: any) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  // @ts-ignore
  const onChildUpdate = useCallback(
    (updatedItem) => {
      const updatedIndex = sidebar.findIndex(
        (item: any) => item.value === updatedItem.value
      );
      const updatedTmpSidebar = [...sidebar];
      updatedTmpSidebar[updatedIndex] = updatedItem;
      setSidebar(updatedTmpSidebar);
    },
    [sidebar, setSidebar]
  );

  if (!sidebar.length) return null;

  return (
    <Container
      lockAxis="y"
      onDrop={onSidebarDrop}
      getChildPayload={(i) => sidebar[i]}
      style={style.mainContainer}
    >
      {sidebar.map((item: any) => (
        <Draggable>
          <SidebarCard
            key={`${item.tag}-${item.value}`}
            item={item}
            onUpdate={onChildUpdate}
            expand={expand}
            openItems={openItems}
          />
        </Draggable>
      ))}
    </Container>
  );
});

export default SidebarMain;
