import { takeEvery, fork, take, put, call, all } from "redux-saga/effects";
import { removeCookie, setCookie } from "utils/cookie";
import { TOKEN_LIFE, TOKEN_NAME } from "constants/contants";
import { push } from "connected-react-router";
import ENDPOINTS from "constants/endpoints";
import request, { ApiSuccessAction, HttpMethod } from "../ajax/request";
import { actions } from "../index";
import { LoginAction } from "./user.interfaces";
import { FeedbackTypes } from "../../../components/Feedback";

function* getUser() {
  yield fork(request, { path: ENDPOINTS.getUser });
  const resultAction: ApiSuccessAction<any> = yield take([
    `${ENDPOINTS.getUser}/SUCCESS`,
    `${ENDPOINTS.getUser}/FAIL`,
  ]);
  if (resultAction.type === `${ENDPOINTS.getUser}/SUCCESS`) {
    if (resultAction.payload.data.roles[0].name === "admin") {
      // const adminToken = getCookie(ADMIN_TOKEN_NAME);
      yield put(actions.setUser({ ...resultAction.payload.data }));
      const [
        countriesResponse,
        settingsResponse,
        categoriesResponse,
        subCategoriesResponse,
        brandsResponse,
      ]: ApiSuccessAction<any>[] = yield all([
        call(request, { path: ENDPOINTS.getCountries }),
        call(request, { path: ENDPOINTS.settings }),
        call(request, { path: ENDPOINTS.getCategories }),
        call(request, { path: ENDPOINTS.getSubCategories }),
        call(request, { path: ENDPOINTS.getBrands }),
      ]);
      if (
        countriesResponse &&
        settingsResponse &&
        categoriesResponse &&
        subCategoriesResponse &&
        brandsResponse
      ) {
        yield put(
          actions.setPlatformData({
            countries: countriesResponse.data,
            settings: settingsResponse.data,
            categories: categoriesResponse.data,
            subCategories: subCategoriesResponse.data,
            brands: brandsResponse.data,
          })
        );
      } else {
        console.log("ERRORE!!!!!!!");
      }
    } else {
      console.log("asdasdasd");
      yield put({ type: "USER_LOGOUT" });
      removeCookie(TOKEN_NAME);
      yield put(push("/login"));
      yield put(
        actions.setFeedback({
          type: FeedbackTypes.Error,
          message: "Unauthorized",
        })
      );
    }
  }
  if (resultAction.type === `${ENDPOINTS.getUser}/FAIL`) {
    yield put(push("/login"));
  }
}

function* login(action: LoginAction) {
  yield fork(request, {
    path: ENDPOINTS.login,
    method: HttpMethod.POST,
    body: {
      email: action.payload.email,
      password: action.payload.password,
    },
  });
  const resultAction: ApiSuccessAction<any> = yield take([
    `${ENDPOINTS.login}/SUCCESS`,
    `${ENDPOINTS.login}/FAIL`,
  ]);
  if (resultAction.type === `${ENDPOINTS.login}/SUCCESS`) {
    setCookie(TOKEN_NAME, resultAction.payload.data.token, TOKEN_LIFE);
    yield put(push("/"));
    yield call(getUser);
  }
  if (resultAction.type === `${ENDPOINTS.login}/FAIL`) {
    // TODO gestione messaggi feedback
    console.log("errore login");
  }
}

export function* getUserSaga() {
  yield takeEvery("GET_USER_SAGA", getUser);
}

export function* loginSaga() {
  yield takeEvery("LOGIN_SAGA", login);
}
