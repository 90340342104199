import React, { memo, useCallback, useState } from "react";

import { Delete, DragHandle } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid, TextField } from "@mui/material";
import style from "./index.style";
import { generateString } from "../../../utils";

interface SidebarOtherProps {
  sidebar: any;
  setSidebar: any;
}

const SidebarOther = memo(({ sidebar, setSidebar }: SidebarOtherProps) => {
  const [editItem, setEditItem] = useState({
    labels: {
      it_IT: "",
    },
    to: "",
    color: "",
    id: "",
  });
  const [newItem, setNewItem] = useState({
    labels: {
      it_IT: "",
    },
    to: "",
    color: "",
    id: generateString(20),
  });
  const titleItem = sidebar.find((item: any) => item?.to === "title");
  const handleEdit = (
    item: React.SetStateAction<{
      labels: { it_IT: string };
      to: string;
      color: string;
      id: string;
    }>
  ) => {
    setEditItem(item);
  };

  const handleDelete = (id: any) => {
    const tmpSidebar = [...sidebar];
    const deletedItemIndex = tmpSidebar.findIndex((item) => item?.id === id);
    tmpSidebar.splice(deletedItemIndex, 1);
    setSidebar(tmpSidebar);
  };

  // @ts-ignore
  const onItemDrop = ({ removedIndex, addedIndex }) => {
    let tmpSidebar = [...sidebar.filter((item: any) => item?.to !== "title")];
    tmpSidebar = arrayMoveImmutable(tmpSidebar, removedIndex, addedIndex);
    const sidebarTitle = sidebar.find((item: any) => item?.to === "title");
    tmpSidebar.unshift(sidebarTitle);
    setSidebar(tmpSidebar);
  };

  const onEdit = useCallback((item) => {
    setEditItem(item);
  }, []);

  const onSave = useCallback(() => {
    const tmpSidebar = [...sidebar];
    const updatedItemIndex = tmpSidebar.findIndex(
      (item) => item?.id === editItem?.id
    );
    tmpSidebar[updatedItemIndex] = editItem;
    setSidebar(tmpSidebar);
    setEditItem({
      labels: {
        it_IT: "",
      },
      to: "",
      color: "",
      id: generateString(20),
    });
  }, [editItem, setSidebar, sidebar]);

  const onEditNew = useCallback((item) => {
    setNewItem(item);
  }, []);

  const onSaveNew = useCallback(() => {
    const updatedData = [...sidebar];
    updatedData.push(newItem);
    setSidebar(updatedData);
    setNewItem({
      labels: {
        it_IT: "",
      },
      to: "",
      color: "",
      id: generateString(20),
    });
  }, [newItem, setSidebar, sidebar]);

  return (
    <Box>
      <Box sx={style.titleContainer}>
        {editItem?.to !== "title" ? (
          <>
            <Box sx={{ color: titleItem?.color }}>
              {titleItem?.labels?.it_IT || titleItem?.value}
            </Box>
            <Box sx={style.iconsContainer}>
              <EditIcon sx={style.icon} onClick={() => handleEdit(titleItem)} />
            </Box>
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={5} sm={5}>
              <TextField
                label="Titolo"
                value={editItem?.labels?.it_IT}
                onChange={(e) =>
                  onEdit({
                    ...editItem,
                    labels: { it_IT: e.target.value },
                  })
                }
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={5} sm={5}>
              <TextField
                label="Colore"
                value={editItem?.color}
                onChange={(e) =>
                  onEdit({
                    ...editItem,
                    color: e.target.value,
                  })
                }
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <Button variant="contained" size="large" onClick={onSave}>
                Salva
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
      <Container
        lockAxis="y"
        onDrop={onItemDrop}
        getChildPayload={(i) => sidebar[i]}
        style={style.mainContainer}
      >
        {sidebar
          .filter((item: any) => item.to !== "title")
          .map((item: any) => (
            <Draggable>
              <Box sx={style.childCardContainer}>
                <Box sx={style.cardContainer}>
                  <Box
                    sx={[
                      style.cardContent,
                      { height: editItem?.id !== item?.id ? "48px" : "187px" },
                    ]}
                  >
                    <DragHandle sx={{ fontSize: "32px" }} />
                  </Box>
                  {editItem?.id !== item?.id ? (
                    <Box sx={style.cardActions}>
                      <Box sx={{ color: item?.color }}>
                        {item?.labels?.it_IT || item?.value}
                      </Box>
                      <Box sx={style.iconsContainer}>
                        <EditIcon
                          sx={style.icon}
                          onClick={() => handleEdit(item)}
                        />
                        <Delete
                          sx={style.icon}
                          onClick={() => handleDelete(item?.id)}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={style.editItemContainer}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            label="Link"
                            value={editItem?.to}
                            onChange={(e) =>
                              onEdit({
                                ...editItem,
                                to: e.target.value,
                              })
                            }
                            fullWidth
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <TextField
                            label="Titolo"
                            value={editItem?.labels?.it_IT}
                            onChange={(e) =>
                              onEdit({
                                ...editItem,
                                labels: { it_IT: e.target.value },
                              })
                            }
                            fullWidth
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <TextField
                            label="Colore"
                            value={editItem?.color}
                            onChange={(e) =>
                              onEdit({
                                ...editItem,
                                color: e.target.value,
                              })
                            }
                            fullWidth
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={onSave}
                          >
                            Salva
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            </Draggable>
          ))}
      </Container>
      <Box sx={style.newItemContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Link"
              value={newItem?.to}
              onChange={(e) =>
                onEditNew({
                  ...newItem,
                  to: e.target.value,
                })
              }
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              label="Titolo"
              value={newItem?.labels?.it_IT}
              onChange={(e) =>
                onEditNew({
                  ...newItem,
                  labels: { it_IT: e.target.value },
                })
              }
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              label="Colore"
              value={newItem?.color}
              onChange={(e) =>
                onEditNew({
                  ...newItem,
                  color: e.target.value,
                })
              }
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="large" onClick={onSaveNew}>
              Salva
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default SidebarOther;
