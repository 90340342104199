import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import useEditModalContent from "./index.hooks";
import style from "./index.style";

type EditModalContentProps = {
  item: any;
  closeModal: () => void;
  getItems: any;
};

const EditModalContent = memo(
  ({ item, closeModal, getItems }: EditModalContentProps) => {
    const {
      handleChangeValue,
      editingItem,
      updateItem,
      getSelectCountries,
      handleChangeSelectValue,
      handleChangeEmail,
    } = useEditModalContent({
      item,
      getItems,
      closeModal,
    });

    return (
      <div>
        {editingItem && (
          <Box sx={style.modal}>
            <Grid container spacing={3}>
              <>
                <IconButton sx={style.modal.closeButton} onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={editingItem?.addressee}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "addressee")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Type"
                    value={editingItem?.type}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={editingItem?.user?.email}
                    onChange={(ev) => handleChangeEmail(ev.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Company name"
                    value={editingItem?.companyName}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "companyName")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Address type"
                    value={editingItem?.address_type}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "address_type")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Street name"
                    value={editingItem?.street}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "street")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Number"
                    value={editingItem?.number}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "number")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Zip"
                    value={editingItem?.cap}
                    onChange={(ev) => handleChangeValue(ev.target.value, "cap")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="City"
                    value={editingItem?.city}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "city")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Prov/State"
                    value={editingItem?.prov}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "prov")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                      labelId="select-country"
                      id="select-country"
                      label="Country"
                      value={editingItem?.country?.countrycode}
                      onChange={(ev) =>
                        handleChangeSelectValue(ev.target.value)
                      }
                    >
                      {getSelectCountries.map((country: any) => (
                        <MenuItem
                          key={country.countryCode}
                          value={country.countryCode}
                        >
                          {country.countryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Prefix"
                    value={editingItem?.celPrefix}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "celPrefix")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    label="Mobile"
                    value={editingItem?.cel}
                    onChange={(ev) => handleChangeValue(ev.target.value, "cel")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" size="large" onClick={updateItem}>
                    Save
                  </Button>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
      </div>
    );
  }
);

export default EditModalContent;
