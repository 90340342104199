import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import useEditModalContent from "./index.hooks";
import style from "./index.style";

type EditModalContentProps = {
  carrier: any;
  closeModal: () => void;
  getVatSystems: any;
  isEdit: boolean;
  countries: any[];
  carriers: any[];
};

const EditModalContent = memo(
  ({
    carrier,
    closeModal,
    getVatSystems,
    isEdit,
    countries,
    carriers,
  }: EditModalContentProps) => {
    const {
      handleChangeValue,
      editingCarrierFee,
      updateCarrierFee,
      deleteCarrierFee,
      getSelectCountries,
      getSelectCarriers,
    } = useEditModalContent({
      carrier,
      getVatSystems,
      closeModal,
      isEdit,
      countries,
      carriers,
    });
    return (
      <Box sx={style.modal}>
        {(!isEdit ||
          (editingCarrierFee?.country && editingCarrierFee?.carrier)) && (
          <Grid container spacing={3}>
            <>
              <IconButton sx={style.modal.closeButton} onClick={closeModal}>
                <CloseIcon />
              </IconButton>
              <Grid item xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit carrier fee
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    labelId="select-country"
                    id="select-country"
                    label="Country"
                    value={editingCarrierFee?.country}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "country")
                    }
                  >
                    {getSelectCountries.map((country: any) => (
                      <MenuItem
                        key={country.countryCode}
                        value={country.countryCode}
                      >
                        {country.countryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="carrier-label">Carrier</InputLabel>
                  <Select
                    labelId="select-carrier"
                    id="select-carrier"
                    label="Carrier"
                    value={editingCarrierFee?.carrier}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "carrier")
                    }
                  >
                    {getSelectCarriers.map((carrier: any) => (
                      <MenuItem
                        key={carrier.carrierId}
                        value={carrier.carrierId}
                      >
                        {carrier.carrierName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Fee"
                  value={editingCarrierFee?.fee}
                  onChange={(ev) => handleChangeValue(ev.target.value, "fee")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Weight"
                  value={editingCarrierFee?.weight}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "weight")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={updateCarrierFee}
                >
                  {isEdit ? "Save" : "Create"}
                </Button>
                {isEdit && (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={deleteCarrierFee}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
            </>
          </Grid>
        )}
      </Box>
    );
  }
);

export default EditModalContent;
