import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useState } from "react";
import request from "redux/slices/ajax/request";

const useCarrier = () => {
  const [page, setPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [carriers, setCarriers] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingCarrier, setEditingCarrier] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingCarrier(null);
  }, []);

  const handleOpenModal = useCallback((carrier?: any, tempIsEdit?: boolean) => {
    setIsEdit(tempIsEdit || false);
    if (tempIsEdit) {
      setEditingCarrier(carrier);
    } else {
      setEditingCarrier({
        online: true,
        refId: 0,
        days: 0,
      });
    }
    setModalOpen((prevState) => !prevState);
  }, []);

  const getCarriers = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.carrier}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
      },
    });
    setCarriers(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [page, pageSize]);

  useEffect(() => {
    getCarriers().then();
  }, [getCarriers]);
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  return {
    page,
    searchValue,
    carriers,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    handleChangeSearchValue,
    modalOpen,
    handleOpenModal,
    editingCarrier,
    getCarriers,
    isEdit,
    handleCloseModal,
  };
};

export default useCarrier;
