import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import style from "./index.style";
import useStripe from "./index.hooks";
import { getFormattedDate } from "../../../utils";

type StripeProps = {};

const Stripe = memo(({}: StripeProps) => {
  const {
    page,
    logs,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getLogs,
    searchValue,
    handleChangeSearchValue,
    startDate,
    endDate,
    onChangeDate,
  } = useStripe();

  return (
    <>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search"
              value={searchValue}
              onChange={handleChangeSearchValue}
              size="medium"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                startText="Start date"
                endText="End date"
                value={[startDate, endDate]}
                onChange={onChangeDate}
                inputFormat="dd-MM-yyyy"
                mask="__-__-____"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 3 }}> to </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>BDroppy Email</TableCell>
                <TableCell>Stripe Email</TableCell>
                <TableCell>Payment time</TableCell>
                <TableCell>Stripe Link</TableCell>
                <TableCell>Amount Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs?.map((log) => (
                <TableRow key={log?._id}>
                  <TableCell>{log?.res?.metadata?.userEmail}</TableCell>
                  <TableCell>{log?.res?.receipt_email}</TableCell>
                  <TableCell>{getFormattedDate(log?.resTime)}</TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://dashboard.stripe.com/payments/${log?.res?.id}`}
                    >
                      {log?.res?.id}
                    </a>
                  </TableCell>
                  <TableCell>
                    {(Number(log?.res?.amount) / 100)?.toFixed(2)} €
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default Stripe;
