import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Divider, IconButton, Toolbar } from "@mui/material";
import React, { memo } from "react";

import useHeader from "./index.hooks";
import style from "./index.style";

type HeaderProps = {
  handleDrawer: () => void;
};

const Header = memo(({ handleDrawer }: HeaderProps) => {
  const { handleLogout } = useHeader();
  return (
    <AppBar position="fixed" sx={[style.header, { zIndex: 11 }]}>
      <Toolbar>
        <img
          src={`images/logo/${process.env.REACT_APP_ENV}-logo.svg`}
          alt="logo"
        />
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          sx={{ ml: 1, display: { sm: "none" } }}
          onClick={handleDrawer}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <Toolbar sx={{ justifyContent: "flex-end" }}>
        <IconButton disableRipple onClick={handleLogout} size="small">
          Logout
        </IconButton>
      </Toolbar>
    </AppBar>
  );
});

export default Header;
