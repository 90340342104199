import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Modal,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import EditIcon from "@mui/icons-material/Edit";
import Info from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import style from "./index.style";
import useTurnkey from "./index.hooks";
import EditModalContent from "./EditModalContent";
import { getFormattedDate } from "../../utils";

type TurnkeyProps = {};

const Turnkey = memo(({}: TurnkeyProps) => {
  const {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    searchValue,
    handleChangeSearchValue,
    purchaseStartDate,
    purchaseEndDate,
    onChangePurchaseDate,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    editingItem,
    updatedStartDate,
    updatedEndDate,
    onChangeUpdatedDate,
    searchStatusValue,
    handleChangeSelectStatusValue,
    getSelectStatusValues,
    getSelectCountriesValues,
    searchCountryValue,
    handleChangeSelectCountryValue,
    getSelectCountries,
    handleInfoModal,
    infoModalOpen,
    infoItem,
  } = useTurnkey();

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditModalContent
          item={editingItem}
          closeModal={handleCloseModal}
          getItems={getItems}
        />
      </Modal>
      <Modal
        open={infoModalOpen}
        onClose={handleInfoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <Grid container spacing={3}>
            <IconButton sx={style.modal.closeButton} onClick={handleInfoModal}>
              <CloseIcon />
            </IconButton>
            <TableContainer sx={style.table} component={Box}>
              <Table aria-label="users-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Role</TableCell>
                    <TableCell>Template</TableCell>
                    <TableCell>Lang</TableCell>
                    <TableCell>Currency</TableCell>
                    <TableCell>Platform</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{infoItem?.plan_id}</TableCell>
                    <TableCell>{infoItem?.site_tmpl}</TableCell>
                    <TableCell>{infoItem?.site_lang}</TableCell>
                    <TableCell>{infoItem?.site_cur}</TableCell>
                    <TableCell>{infoItem?.site_platform}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search"
              value={searchValue}
              onChange={handleChangeSearchValue}
              size="medium"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="select-status"
                id="select-status"
                label="Status"
                value={searchStatusValue}
                onChange={(ev) =>
                  handleChangeSelectStatusValue(ev.target.value)
                }
              >
                {getSelectStatusValues()?.map((value: any) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="country-label">Billing country</InputLabel>
              <Select
                labelId="select-country"
                id="select-country"
                label="Billing country"
                value={searchCountryValue}
                onChange={(ev) =>
                  handleChangeSelectCountryValue(ev.target.value)
                }
              >
                {getSelectCountriesValues().map((country: any) => (
                  <MenuItem
                    key={country.countryCode}
                    value={country.countryCode}
                  >
                    {country.countryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ mb: 2 }}>Purchase date</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                startText="Start date"
                endText="End date"
                value={[purchaseStartDate, purchaseEndDate]}
                onChange={onChangePurchaseDate}
                inputFormat="dd-MM-yyyy"
                mask="__-__-____"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 3 }}> to </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ mb: 2 }}>Form filling date</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                startText="Start date"
                endText="End date"
                value={[updatedStartDate, updatedEndDate]}
                onChange={onChangeUpdatedDate}
                inputFormat="dd-MM-yyyy"
                mask="__-__-____"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 3 }}> to </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Purchase Date</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Domain</TableCell>
                <TableCell>Billing Country</TableCell>
                <TableCell>Form Filling Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item) => (
                <TableRow key={item?._id}>
                  <TableCell>{getFormattedDate(item?.createdAt)}</TableCell>
                  <TableCell>{item?.email}</TableCell>
                  <TableCell>{item?.domain}</TableCell>
                  <TableCell>
                    {
                      getSelectCountries?.filter(
                        (country: any) =>
                          country?.countryCode === item?.country_code
                      )?.[0]?.countryName
                    }
                  </TableCell>
                  <TableCell>{getFormattedDate(item?.updatedAt)}</TableCell>
                  <TableCell>{item?.status}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleOpenModal(item)}
                      >
                        <EditIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleInfoModal(item)}
                      >
                        <Info sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default Turnkey;
