import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { removeCookie } from "utils/cookie";
import { TOKEN_NAME } from "constants/contants";

const useHeader = () => {
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch({
      type: "USER_LOGOUT",
    });
    removeCookie(TOKEN_NAME);
    dispatch(push("/login"));
  }, [dispatch]);

  return { handleLogout };
};

export default useHeader;
