export function setCookie(name: string, value = "", seconds = 0, path = "/") {
  const date = new Date();
  date.setTime(date.getTime() + seconds * 1000);
  const expires = date.toUTCString();
  document.cookie = `${name}=${value};expires=${expires};path=${path}`;
}

export function getCookie(name: string) {
  if (global?.document?.cookie?.length > 0) {
    let cStart = document.cookie.indexOf(`${name}=`);
    if (cStart !== -1) {
      cStart = cStart + name.length + 1;
      let cEnd = document.cookie.indexOf(";", cStart);
      if (cEnd === -1) cEnd = document.cookie.length;
      return unescape(document.cookie.substring(cStart, cEnd));
    }
  }
  return "";
}

export function removeCookie(name: string) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
