import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { Button, Paper } from "@mui/material";
import useLogin from "./index.hooks";
import style from "./index.style";

type LoginProps = {};

const Login = memo(({}: LoginProps) => {
  const { email, password, onChangeEmail, onChangePassword, onSubmit } =
    useLogin();

  return (
    <Paper sx={style.login}>
      <TextField
        id="outlined-name"
        label="Email"
        value={email}
        onChange={onChangeEmail}
      />
      <TextField
        id="outlined-name"
        label="Password"
        value={password}
        onChange={onChangePassword}
        type="password"
      />
      <Button variant="contained" onClick={onSubmit}>
        Login
      </Button>
    </Paper>
  );
});

export default Login;
