import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  getBrands,
  getCountries,
} from "redux/slices/platform-data/platform-data.selectors";
import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";
import { useMediaQuery } from "@mui/material";
import theme from "style/theme";

type useEditUserProps = {
  user: any;
  getUsers: () => void;
};

const useEditUser = ({ user, getUsers }: useEditUserProps) => {
  const [editingUser, setEditingUser] = useState<any>();
  const brands = useSelector(getBrands);
  const countries = useSelector(getCountries);
  const [showLimitTarsCount, setShowLimitTarsCount] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [longToken, setLongToken] = useState("");
  const [expires, setExpires] = useState<any>(null);
  const [userFiles, setUserFiles] = useState<any[]>([]);

  useEffect(() => {
    setExpires(user?.roles?.[0]?.expires);
    setEditingUser(user);
    setShowLimitTarsCount(user?.limitTarsCount !== -1);
  }, [user]);

  const getSelectBrands = useMemo(
    () => brands?.map((brand: any) => brand?.name),
    [brands]
  );
  const getSelectCountries = useMemo(
    () =>
      countries?.map((country: any) => ({
        countryName: country?.country,
        countryCode: country?.countrycode,
      })),
    [countries]
  );
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempUser = { ...editingUser };
      tempUser[key] = value;
      setEditingUser(tempUser);
    },
    [editingUser]
  );

  const handleChangeSelectValue = useCallback(
    (value: string, key: string) => {
      const tempUser = { ...editingUser };
      tempUser[key] = value;
      setEditingUser(tempUser);
    },
    [editingUser]
  );
  const handleChangeLimitTarsSwitch = useCallback((value: boolean) => {
    setShowLimitTarsCount(value);
  }, []);
  const handleChangeExpiresDate = useCallback(
    (newDate: Date, index: number) => {
      const tempUser = { ...editingUser };
      tempUser.roles[index].expires = newDate.toISOString();
      setEditingUser(tempUser);
    },
    [editingUser]
  );
  const handleChangeProductsLimit = useCallback(
    (value: string, index: number) => {
      const tempUser = { ...editingUser };
      tempUser.roles[index].limit = value;
      setEditingUser(tempUser);
    },
    [editingUser]
  );
  const handleChangeSelectRoleValue = useCallback(
    (value: string, index: number) => {
      const tempUser = { ...editingUser };
      tempUser.roles[index].name = value;
      setEditingUser(tempUser);
    },
    [editingUser]
  );
  const addNewRole = useCallback(() => {
    const tempUser = { ...editingUser };
    tempUser.roles.push({ name: "", expires: "", limit: 10000 });
    setEditingUser(tempUser);
  }, [editingUser]);
  const deleteRole = useCallback(
    (index: number) => {
      const tempUser = { ...editingUser };
      tempUser.roles.splice(index, 1);
      setEditingUser(tempUser);
    },
    [editingUser]
  );

  const getLongToken = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.getLongToken,
      pathParams: {
        username: user.username,
      },
    });
    setLongToken(response?.data?.token);
  }, [user.username]);

  const updateUser = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.user}/${editingUser?._id}`,
      method: HttpMethod.PUT,
      body: {
        catalogNumber: editingUser?.catalogNumber,
        name: editingUser?.name,
        limitTarsCount: editingUser?.limitTarsCount,
        cod: editingUser?.cod,
        roles: editingUser?.roles,
        ignoreRestrinctions: editingUser?.ignoreRestrinctions,
        noTax: editingUser?.noTax,
        status: editingUser?.status,
        countryCode: editingUser?.countryCode,
        adhoc: editingUser?.adhoc,
        catalogDownloadable: editingUser?.catalogDownloadable,
        shippyproApiKey: editingUser?.shippyproApiKey,
        brands: editingUser?.brands,
      },
    });
    if (response.status === 200) {
      if (expires !== editingUser?.roles?.[0]?.expires) {
        response = await request({
          path: `${ENDPOINTS.subscription}/user/${editingUser?._id}`,
        });
        const { plan } = response.data;
        plan.expiryDate = editingUser?.roles?.[0]?.expires;
        response = await request({
          path: `${ENDPOINTS.subscription}/${response.data?._id}`,
          method: HttpMethod.PUT,
          body: {
            plan,
          },
        });
        console.log("response update", response);
      }
      await getUsers();
    }
  }, [
    editingUser?._id,
    editingUser?.adhoc,
    editingUser?.brands,
    editingUser?.catalogDownloadable,
    editingUser?.catalogNumber,
    editingUser?.cod,
    editingUser?.countryCode,
    editingUser?.ignoreRestrinctions,
    editingUser?.limitTarsCount,
    editingUser?.name,
    editingUser?.noTax,
    editingUser?.roles,
    editingUser?.shippyproApiKey,
    editingUser?.status,
    expires,
    getUsers,
  ]);

  const logAs = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_DASH_URL}/log-as?user=${editingUser?.username}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, [editingUser?.username]);

  const resetPassword = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.resetPassword,
      pathParams: {
        username: user.username,
      },
    });
    // eslint-disable-next-line no-alert
    alert(`Nuova password: ${response.data}`);
  }, [user.username]);
  const getIDUrl = useCallback(async () => {
    let response: any = {};
    let result = [];

    response = await request({
      path: ENDPOINTS.getID,
      pathParams: {
        userId: user._id,
      },
    });

    if (response?.status === 200) {
      if (response?.data?.signedUrls) {
        result = response.data.signedUrls;
        // window.open(response.data.signedUrl, "_blank", "noopener,noreferrer");
      }
    } else if (response?.status === 404) {
      // eslint-disable-next-line no-alert
      alert(`User has not loaded his ID`);
    }
    return result;
  }, [user._id]);

  const getFrontID = useCallback(async () => {
    let files = userFiles;
    if (!files.length) {
      files = await getIDUrl();
      setUserFiles(files);
    }
    const frontFile = files.find((file) => file.type === "ID_FRONT");
    if (frontFile) {
      window.open(frontFile.signedUrl, "_blank", "noopener,noreferrer");
    } else {
      // eslint-disable-next-line no-alert
      alert(`User has not loaded his ID`);
    }
  }, [getIDUrl, userFiles]);

  const getBackID = useCallback(async () => {
    let files = userFiles;
    if (!files.length) {
      files = await getIDUrl();
      setUserFiles(files);
    }
    const backFile = files.find((file) => file.type === "ID_BACK");
    if (backFile) {
      window.open(backFile.signedUrl, "_blank", "noopener,noreferrer");
    } else {
      // eslint-disable-next-line no-alert
      alert(`User has not loaded his ID`);
    }
  }, [getIDUrl, userFiles]);

  return {
    handleChangeValue,
    editingUser,
    getSelectBrands,
    handleChangeSelectValue,
    getSelectCountries,
    showLimitTarsCount,
    handleChangeLimitTarsSwitch,
    handleChangeExpiresDate,
    handleChangeSelectRoleValue,
    addNewRole,
    deleteRole,
    updateUser,
    isMobile,
    getLongToken,
    longToken,
    handleChangeProductsLimit,
    logAs,
    resetPassword,
    getIDUrl,
    getFrontID,
    getBackID,
  };
};

export default useEditUser;
