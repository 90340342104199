import { useDispatch } from "react-redux";
import { useEffect, useState, useCallback, useMemo } from "react";
import request, { HttpMethod } from "../../redux/slices/ajax/request";
import ENDPOINTS from "../../constants/endpoints";
import { getFileAddress } from "../../utils";

const ITEMS_PER_PAGE = 8;

const usePhotoDownload = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [pagesArray, setPagesArray] = useState([]);
  const [renderCategories, setRenderCategories] = useState<any[]>([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("all");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setSelectedImg("");
    setImgBrand("");
  }, []);
  const [selectedImg, setSelectedImg] = useState<any>();
  const [imgBrand, setImgBrand] = useState<any>();

  const getImages = useCallback(async () => {
    try {
      let response: any = {};
      response = await request({
        path: ENDPOINTS.getImages,
        method: HttpMethod.GET,
        pathParams: {
          brand: selectedBrand.replace("%", "%25"),
        },
      });
      const tempPagesArray: any = [];
      response.data.forEach((c: any) =>
        tempPagesArray.push({
          category: c.brand,
          page: 1,
        })
      );
      setPagesArray(tempPagesArray);
      if (selectedBrand === "all") {
        setRenderCategories(response.data);
        setCategories(response.data);
      } else {
        setRenderCategories([
          response.data.find((c: any) => c.brand === selectedBrand),
        ]);
      }
    } catch (e) {
      console.log("getImagesApi Error");
    }
  }, [selectedBrand]);

  useEffect(() => {
    getImages().then();
  }, [dispatch, getImages, selectedBrand]);

  const getCurrentPage = useCallback(
    // @ts-ignore
    (brand) => pagesArray?.find((p: any) => p?.category === brand)?.page,
    [pagesArray]
  );

  const getTotalPages = useCallback(
    (category: any) =>
      Math.ceil(
        // @ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        categories?.find((c) => c?.brand === category)?.value?.length /
          ITEMS_PER_PAGE
      ),
    [categories]
  );

  const getGalleryImages = useCallback(
    (category) => {
      const tempImages: any = [];
      const page = getCurrentPage(category?.brand);
      category.value.forEach((i: any) =>
        tempImages.push({
          src: getFileAddress(i?.m?.replace("%", "%25"))?.replace(
            "/prod/m",
            ""
          ),
          thumb: getFileAddress(
            i?.m?.thumb?.replace("%", "%25")?.replace("/prod/m", "")
          ),
          m: getFileAddress(i?.m?.replace("%", "%25"))?.replace("/prod/m", ""),
          contentType: i?.contentType,
          name: i?.name,
          width: 1,
          height: 1,
        })
      );
      if (tempImages.length > page * ITEMS_PER_PAGE) {
        return tempImages.slice(
          (page - 1) * ITEMS_PER_PAGE,
          page * ITEMS_PER_PAGE
        );
      }
      return tempImages.slice((page - 1) * ITEMS_PER_PAGE);
    },
    [getCurrentPage]
  );

  const getNextCategory = useCallback(() => {
    const maxLength = categories.length;
    const currentLength = renderCategories.length;
    const tempRenderCategories = [...renderCategories];
    if (currentLength < maxLength && renderCategories?.length > 0) {
      tempRenderCategories.push(categories[currentLength]);
      setRenderCategories(tempRenderCategories);
    }
  }, [categories, renderCategories]);

  const checkHasMore = useMemo(() => {
    if (!isFilterActive) {
      const maxLength = categories.length;
      const currentLength = renderCategories.length;
      return currentLength < maxLength;
    }
    return false;
  }, [categories.length, isFilterActive, renderCategories.length]);

  const changePage = useCallback(
    (page, brand) => {
      const pages: any = [...pagesArray];
      const currentBrandIndex = pages
        ?.map((p: any) => p?.category)
        ?.indexOf(brand);
      pages[currentBrandIndex].page = page;
      setPagesArray(pages);
    },
    [pagesArray]
  );

  const getBrandsSelectOptions = useMemo(() => {
    const selectOptions = categories.map((c: any) => ({
      value: c?.brand,
      label: c?.brand,
    }));
    selectOptions.unshift({
      value: "all",
      label: "All Brands",
    });
    return selectOptions;
  }, [categories]);

  const onChangeBrandsSelect = useCallback(
    (value) => {
      setSelectedBrand(value);
      if (value !== "all") {
        setIsFilterActive(true);
        // @ts-ignore
        setRenderCategories([categories.find((c) => c.brand === value)]);
      } else {
        setIsFilterActive(false);
        const tempCategories = [
          categories[0],
          categories[1],
          categories[2],
          categories[3],
        ];
        setRenderCategories(tempCategories);
      }
    },
    [categories]
  );

  const openImage = useCallback((image, brand) => {
    setSelectedImg(image);
    setImgBrand(brand);
    setModalOpen(true);
    // window.open(image?.m, "_blank", "noopener,noreferrer");
  }, []);

  const uploadImage = useCallback(
    async (e, brand) => {
      const file = e.target.files;
      const fd = new FormData();
      for (const item of file) {
        fd.append("uploads[]", item);
      }
      try {
        await request({
          path: ENDPOINTS.uploadImage,
          method: HttpMethod.POST,
          pathParams: {
            brand: brand.replace("%", "%25"),
          },
          body: fd,
        });
        await getImages();
      } catch (e) {
        console.log("uploadImage Error");
      }
    },
    [getImages]
  );

  const deleteImage = useCallback(async () => {
    try {
      await request({
        path: ENDPOINTS.deleteImage,
        method: HttpMethod.DELETE,
        pathParams: {
          brand: imgBrand.replace("%", "%25"),
          img: selectedImg.name.substring(selectedImg.name.indexOf("/") + 1),
        },
      });
      setSelectedImg(null);
      setImgBrand(null);
      handleCloseModal();
      await getImages();
    } catch (e) {
      console.log("deleteImage Error");
    }
  }, [getImages, handleCloseModal, imgBrand, selectedImg]);

  return {
    renderCategories,
    getNextCategory,
    checkHasMore,
    onChangeBrandsSelect,
    selectedBrand,
    getBrandsSelectOptions,
    getGalleryImages,
    openImage,
    getTotalPages,
    getCurrentPage,
    changePage,
    uploadImage,
    modalOpen,
    handleCloseModal,
    selectedImg,
    deleteImage,
  };
};

export default usePhotoDownload;
