import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useState } from "react";
import request from "redux/slices/ajax/request";
import {
  VOUCHER_STATUS,
  VOUCHER_TYPE,
  VOUCHER_USABILITY,
  VOUCHER_USERS_TYPOLOGY,
  VOUCHER_VALUE_TYPE,
} from "./index";

const useVouchers = () => {
  const [page, setPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [vouchers, setVouchers] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingVoucher, setEditingVoucher] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingVoucher(null);
  }, []);

  const handleOpenModal = useCallback((voucher?: any, tempIsEdit?: boolean) => {
    setIsEdit(tempIsEdit || false);
    if (tempIsEdit) {
      setEditingVoucher(voucher);
    } else {
      setEditingVoucher({
        code: "",
        type: VOUCHER_TYPE.ORDER_TAXABLE,
        value: 10,
        value_type: VOUCHER_VALUE_TYPE.PERCENTAGE,
        orderTotal: 5,
        usability: VOUCHER_USABILITY.ONCE_PER_USER,
        usable_with_other_vouchers: false,
        users_typology: VOUCHER_USERS_TYPOLOGY.ALL,
        status: VOUCHER_STATUS.ACTIVE,
      });
    }
    setModalOpen((prevState) => !prevState);
  }, []);

  const getVouchers = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.voucher}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
      },
    });
    setVouchers(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [page, pageSize]);

  useEffect(() => {
    getVouchers().then();
  }, [getVouchers]);
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  return {
    page,
    searchValue,
    vouchers,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    handleChangeSearchValue,
    modalOpen,
    handleOpenModal,
    editingVoucher,
    getVouchers,
    isEdit,
    handleCloseModal,
  };
};

export default useVouchers;
