const ENDPOINTS = {
  getUser: "api/user/me",
  login: "api/auth/login",
  getCountries: "api/country",
  settings: "api/setting",
  getCategories: "api/category",
  getSubCategories: "api/subcategory",
  getBrands: "api/brand",
  getUsers: "api/user/filter",
  getUsersConnectionService: "api/user/filter/connection/service",
  getLocaleTranslations: "api/content/lang",
  platformPages: "api/Page",
  contentTranslation: "api/content",
  getStatistics: "api/bill/statistics",
  getInvoiceStatistics: "api/invoice/statistics",
  getInvoiceStatisticsInfos: "api/invoice/statistics-infos",
  downloadCsvStatistics: "api/invoice/statistics/daily.csv",
  downloadIntras: "api/invoice/intra.zip",
  downloadAdhoc: "api/invoice/adhoc",
  downloadIntraServices: "api/invoice/intra_services.csv",
  downloadCsvStatisticsInfos: "api/invoice/statistics-infos.xlsx",
  downloadFounderPath: "api/invoice/founderpath.csv",
  user: "api/user",
  downloadBills:
    "api/invoice/type/{invoiceType}/class/{invoiceClass}/credit_note/{creditNote}/file/xls/export",
  getPlans: "api/product/export",
  products: "api/product",
  refunds: "api/return_order/filter",
  completeRefunds: "api/return_order/completed",
  returnOrder: "api/return_order/order",
  carrier: "api/carrier",
  carrierFee: "api/carrier_fee",
  vatRule: "api/vat_rule",
  vatSystem: "api/vat_system",
  platform: "api/platform",
  brand: "api/brand",
  stripeLogs: "api/stripe_product",
  paypalLogs: "api/paypal_product",
  turnkey: "api/turnkey",
  orders: "api/orders",
  order: "api/order",
  userCron: "api/user_cron",
  userCronLogs: "api/user_cron_log",
  confirmOrder: "api/ghost/order/confirm",
  downloadOrderInvoice: "api/order/{orderId}/invoice/pdf",
  getLongToken: "api/auth/logas/{username}/long",
  invoices: "api/invoice",
  address: "api/address",
  createCreditNote: "api/invoice/{invoiceId}/credit-note",
  rebuildInvoice: "api/invoice/{invoiceId}/rebuild",
  downloadInvoice: "api/invoice/{invoiceId}/pdf",
  subscription: "api/subscription",
  returnReasons: "api/return_reason",
  downloadProducts: "api/invoice/export/products",
  resetPassword: "api/auth/{username}/reset_password",
  getID: "api/user/{userId}/user_file/ID",
  lightsail: "api/lightsail/instances",
  withdrawTransaction: "api/withdraw_transaction",
  userCatalog: "api/user_catalog",
  getImages: "api/img/brands/{brand}",
  getMerchantInvoice: "api/invoice/export/{merchantId}/report",
  getBillsExpandedInvoice: "api/invoice/exportformat/csv/export",
  uploadImage: "api/img/brand/{brand}",
  deleteImage: "api/img/{brand}/{img}",
  voucher: "api/voucher",
};

export default ENDPOINTS;
