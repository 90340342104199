import React, { memo } from "react";
import CustomTabs from "components/CustomTabs";
import useRateManagement from "./index.hooks";
import Carrier from "./Carrier";
import CarrierFee from "./CarrierFee";

type RateManagementProps = {};

const RateManagement = memo(({}: RateManagementProps) => {
  const { tabIndex, handleChange } = useRateManagement();

  return (
    <CustomTabs
      value={tabIndex}
      handleChange={handleChange}
      tabs={["Carrier", "Carrier fee"]}
      items={[<Carrier />, <CarrierFee />]}
    />
  );
});

export default RateManagement;
