import React, { memo } from "react";
import { useSelector } from "react-redux";
import { getAjaxIsLoading } from "redux/slices/ajax/ajax.selectors";
import style from "./index.style";

const Loader = memo(() => {
  const isLoading = useSelector(getAjaxIsLoading());
  return (
    <div>
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            top: 0,
            zIndex: 2000,
            backgroundColor: "rgba(0, 94, 255, 0.6)",
          }}
        >
          <div className="logo-wrapper">
            <div className="logo">
              <div />
              <div />
              <div />
              <img
                className="animate-flicker"
                src="images/logo/BDroppy-logo-small.svg"
                alt="logo"
              />
            </div>
          </div>
          <style>{style}</style>
        </div>
      )}
    </div>
  );
});

export default Loader;
