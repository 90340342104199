import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import useEditReferral from "./index.hooks";
import style from "./index.style";

type EditReferralProps = {
  user: any;
  closeModal: () => void;
  getUsers: () => void;
};

const SOURCES = ["all", "products", "services"];

const EditReferral = memo(
  ({ user, closeModal, getUsers }: EditReferralProps) => {
    const {
      handleChangeValue,
      updateUser,
      referralSettings,
      handleChangeSelectValue,
    } = useEditReferral({ user, getUsers });
    return (
      <Grid container spacing={3}>
        <IconButton sx={style.modal.closeButton} onClick={closeModal}>
          <CloseIcon />
        </IconButton>
        {referralSettings && (
          <>
            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Editing user {user?.email}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                Referral code
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  fullWidth
                  label="ReferralCode"
                  value={referralSettings?.userCode}
                  onChange={(ev) => {
                    handleChangeValue(ev.target.value, "userCode");
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                Dopo quanto tempo (in giorni) non riceverà più revenue
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  fullWidth
                  label="Days limit"
                  value={referralSettings?.gainDaysLimit}
                  onChange={(ev) => {
                    let value = parseInt(ev.target.value, 10);
                    if (value < 365 && ev.target.value.length >= 3) value = 365;
                    handleChangeValue(value, "gainDaysLimit");
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                Percentuale che riceve l&apos;utente (default 5%)
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  fullWidth
                  label="Percentage"
                  value={referralSettings?.referralPercentageGain}
                  onChange={(ev) => {
                    let value = parseInt(ev.target.value, 10);
                    if (value < 1) value = 1;
                    if (value > 100 && ev.target.value.length >= 3) value = 100;
                    handleChangeValue(value, "referralPercentageGain");
                  }}
                  InputProps={{
                    endAdornment: <Box>%</Box>,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                Credito caricato sugli utenti che arrivano tramite il referral
                (default 10 euro flat)
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  fullWidth
                  label="Ecredit"
                  value={referralSettings?.referredUsersEcredit}
                  onChange={(ev) => {
                    let value = parseInt(ev.target.value, 10);
                    if (value < 1) value = 1;
                    handleChangeValue(value, "referredUsersEcredit");
                  }}
                  InputProps={{
                    endAdornment: <Box>€</Box>,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                Percentuale da prodotti, servizi o entrambi
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="sources-label">Source</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-source"
                    id="select-source"
                    label="Source"
                    value={referralSettings?.referralGainSource}
                    onChange={(ev) =>
                      handleChangeSelectValue(
                        ev.target.value,
                        "referralGainSource"
                      )
                    }
                  >
                    {SOURCES.map((source: string) => (
                      <MenuItem key={source} value={source}>
                        {source}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" size="large" onClick={updateUser}>
                Save
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
);

export default EditReferral;
