import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBanners,
  getBannersId,
} from "redux/slices/platform-data/platform-data.selectors";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Edit } from "@mui/icons-material/";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { actions } from "redux/slices";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateTimePicker } from "@mui/lab";
import { generateString, getFormattedDateWithTime } from "../../../utils";
import style from "./index.style";
import { TIMEZONES } from "../../../constants/contants";

function Banners() {
  const banners = useSelector(getBanners);
  const id = useSelector(getBannersId);
  const dispatch = useDispatch();
  const [editBanner, setEditBanner] = useState<[string, any]>([
    "",
    {
      bigBannerEN: "",
      bigBannerES: "",
      bigBannerFR: "",
      bigBannerIT: "",
      link: "",
      linkM: "",
      linkS: "",
      littleBannerEN: "",
      littleBannerES: "",
      littleBannerFR: "",
      littleBannerIT: "",
      sidebarBannerEN: "",
      sidebarBannerES: "",
      sidebarBannerFR: "",
      sidebarBannerIT: "",
      name: "",
      status: false,
      startDate: "",
      endDate: "",
      timeZone: "+02:00",
      timer: false,
      showPage: ["Home"],
      limitListLang: [],
      limitListLangM: [],
      limitListLangS: [],
    },
  ]);
  const containerRef = useRef(null);
  const [isNew, setIsNew] = useState(false);

  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempItem: [string, any] = [editBanner[0], { ...editBanner[1] }];
      tempItem[1][key] = value;
      setEditBanner(tempItem);
    },
    [editBanner]
  );

  const selectBanner = useCallback((banner: any) => {
    setEditBanner(banner);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(false);
  }, []);

  const onSave = useCallback(async () => {
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          [`body.banners.${editBanner[0]}`]: editBanner[1],
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, editBanner, id]);

  const onDelete = useCallback(async () => {
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          [`body.banners.${editBanner[0]}`]: {},
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, editBanner, id]);

  const createNew = useCallback(() => {
    const randomKey = generateString(20);
    setEditBanner([
      randomKey,
      {
        bigBannerEN: "",
        bigBannerES: "",
        bigBannerFR: "",
        bigBannerIT: "",
        link: "",
        linkM: "",
        linkS: "",
        littleBannerEN: "",
        littleBannerES: "",
        littleBannerFR: "",
        littleBannerIT: "",
        sidebarBannerEN: "",
        sidebarBannerES: "",
        sidebarBannerFR: "",
        sidebarBannerIT: "",
        name: "",
        status: false,
        startDate: "",
        endDate: "",
        timeZone: "+02:00",
        timer: false,
        showPage: ["Home"],
        limitListLang: [],
        limitListLangM: [],
        limitListLangS: [],
      },
    ]);
    setIsNew(true);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(true);
  }, []);

  return (
    <Box sx={style.bannersGallery}>
      <Box>
        <Button variant="contained" onClick={createNew}>
          Create
        </Button>
      </Box>
      <Box sx={style.imagesContainer}>
        {banners &&
          Object.entries(banners).map((banner: [string, any]) => (
            <Box sx={style.imageContainer}>
              <Box sx={[style.bannerInfo, style.bannerName]}>
                {banner[1].name}
              </Box>
              <Box sx={[style.bannerInfo, style.bannerStatus]}>
                {banner[1].status ? "Enabled" : "Disabled"}
              </Box>
              <Box sx={[style.bannerInfo, style.bannerPriority]}>
                {getFormattedDateWithTime(banner[1].startDate)} --{" "}
                {getFormattedDateWithTime(banner[1].endDate)}
              </Box>
              <IconButton
                sx={[style.bannerEditIcon, style.bannerInfo]}
                onClick={() => selectBanner(banner)}
              >
                <Edit />
              </IconButton>
              <img
                style={{
                  width: 500,
                  border:
                    banner[0] === editBanner[0] ? "3px solid #0F45FF" : "",
                }}
                src={banner[1].littleBannerEN}
                alt="banner"
              />
            </Box>
          ))}
      </Box>
      {editBanner[0] && (
        <Paper sx={style.editContainer} ref={containerRef}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <TextField
                fullWidth
                label="Name"
                value={editBanner[1]?.name}
                onChange={(ev) => handleChangeValue(ev.target.value, "name")}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editBanner[1]?.status as boolean}
                    onChange={() =>
                      handleChangeValue(!editBanner[1].status, "status")
                    }
                  />
                }
                label="Online"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat="dd-MM-yyyy hh:mm:ss"
                  onChange={(newValue) =>
                    handleChangeValue(newValue, "startDate")
                  }
                  value={editBanner[1]?.startDate}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat="dd-MM-yyyy hh:mm:ss"
                  onChange={(newValue) =>
                    handleChangeValue(newValue, "endDate")
                  }
                  value={editBanner[1]?.endDate}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="timezone-label">Time Zone</InputLabel>
                <Select
                  labelId="timezone-country"
                  id="timezone-country"
                  label="Timezone"
                  value={editBanner[1]?.timeZone}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "timeZone")
                  }
                >
                  {TIMEZONES.map((timeZone: any) => (
                    <MenuItem key={timeZone.value} value={timeZone.value}>
                      {timeZone.gmt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editBanner[1]?.timer as boolean}
                    onChange={() =>
                      handleChangeValue(!editBanner[1].timer, "timer")
                    }
                  />
                }
                label="Timer"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Redirect link desktop"
                value={editBanner[1]?.link}
                onChange={(ev) => handleChangeValue(ev.target.value, "link")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Redirect link mobile"
                value={editBanner[1]?.linkM}
                onChange={(ev) => handleChangeValue(ev.target.value, "linkM")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Redirect link sidebar"
                value={editBanner[1]?.linkS}
                onChange={(ev) => handleChangeValue(ev.target.value, "linkS")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Big banner EN"
                value={editBanner[1]?.bigBannerEN}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "bigBannerEN")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Big banner ES"
                value={editBanner[1]?.bigBannerES}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "bigBannerES")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Big banner FR"
                value={editBanner[1]?.bigBannerFR}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "bigBannerFR")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Big banner IT"
                value={editBanner[1]?.bigBannerIT}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "bigBannerIT")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Little banner EN"
                value={editBanner[1]?.littleBannerEN}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "littleBannerEN")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Little banner ES"
                value={editBanner[1]?.littleBannerES}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "littleBannerES")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Little banner FR"
                value={editBanner[1]?.littleBannerFR}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "littleBannerFR")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Little banner IT"
                value={editBanner[1]?.littleBannerIT}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "littleBannerIT")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Sidebar banner EN"
                value={editBanner[1]?.sidebarBannerEN}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "sidebarBannerEN")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Sidebar banner ES"
                value={editBanner[1]?.sidebarBannerES}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "sidebarBannerES")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Sidebar banner FR"
                value={editBanner[1]?.sidebarBannerFR}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "sidebarBannerFR")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Sidebar banner IT"
                value={editBanner[1]?.sidebarBannerIT}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "sidebarBannerIT")
                }
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Button variant="contained" color="primary" onClick={onSave}>
                Save
              </Button>
            </Grid>
            {!isNew && (
              <Grid item xs={3} sm={3}>
                <Button variant="contained" color="error" onClick={onDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default Banners;
