import { useCallback, useEffect, useState } from "react";
import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";

type useEditModalContentProps = {
  voucher: any;
  getVouchers: any;
  closeModal: () => void;
  isEdit: boolean;
};

const useEditModalContent = ({
  voucher,
  getVouchers,
  closeModal,
  isEdit,
}: useEditModalContentProps) => {
  const [editingVoucher, setEditingVoucher] = useState<any>();

  useEffect(() => {
    setEditingVoucher(voucher);
  }, [voucher]);
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempUser = { ...editingVoucher };
      tempUser[key] = value;
      setEditingVoucher(tempUser);
    },
    [editingVoucher]
  );

  const deleteVoucher = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.voucher}/${editingVoucher?._id}`,
      method: HttpMethod.DELETE,
    });
    console.log("response", response);
    await getVouchers();
    closeModal();
  }, [closeModal, editingVoucher?._id, getVouchers]);

  const updateVoucher = useCallback(async () => {
    let response: any = {};
    if (isEdit) {
      response = await request({
        path: `${ENDPOINTS.voucher}/${editingVoucher?._id}`,
        method: HttpMethod.PUT,
        body: {
          code: editingVoucher?.code,
          type: editingVoucher?.type,
          value: editingVoucher?.value,
          value_type: editingVoucher?.value_type,
          orderTotal: editingVoucher?.orderTotal,
          status: editingVoucher?.status,
          usability: editingVoucher?.usability,
          usable_with_other_vouchers:
            editingVoucher?.usable_with_other_vouchers,
          users_typology: editingVoucher?.users_typology,
        },
      });
      if (response.status === 200) {
        // Apro feedback success
        closeModal();
      }
    } else {
      response = await request({
        path: `${ENDPOINTS.voucher}`,
        method: HttpMethod.POST,
        body: {
          code: editingVoucher?.code,
          type: editingVoucher?.type,
          value: editingVoucher?.value,
          value_type: editingVoucher?.value_type,
          orderTotal: editingVoucher?.orderTotal,
          status: editingVoucher?.status,
          usability: editingVoucher?.usability,
          usable_with_other_vouchers:
            editingVoucher?.usable_with_other_vouchers,
          users_typology: editingVoucher?.users_typology,
        },
      });
      if (response.status === 200) {
        // Apro feedback success
        closeModal();
      }
    }
    await getVouchers();
    console.log("response", response);
  }, [
    closeModal,
    editingVoucher?._id,
    editingVoucher?.code,
    editingVoucher?.orderTotal,
    editingVoucher?.status,
    editingVoucher?.type,
    editingVoucher?.usability,
    editingVoucher?.usable_with_other_vouchers,
    editingVoucher?.users_typology,
    editingVoucher?.value,
    editingVoucher?.value_type,
    getVouchers,
    isEdit,
  ]);
  return {
    handleChangeValue,
    editingVoucher,
    updateVoucher,
    deleteVoucher,
  };
};

export default useEditModalContent;
