import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Drawer,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import { getFormattedDate } from "utils";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { MonetizationOn } from "@mui/icons-material";
import useUserManagement from "./index.hooks";
import style from "./index.style";
import EditModalContent from "./EditModalContent";
import { STATUSES, ROLES, CHANNEL_COMPONENT } from "../../constants/contants";
import EcreditModalContent from "./EcreditModalContent";

type UserManagementProps = {};

const UserManagement = memo(({}: UserManagementProps) => {
  const {
    page,
    searchValue,
    users,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    handleChangeSearchValue,
    modalOpen,
    handleOpenModal,
    editingUser,
    filtersModalOpen,
    handleFiltersModal,
    filters,
    setShouldFilter,
    resetFilters,
    dispatchFilters,
    getSelectCountries,
    modalOpenEcredit,
    handleOpenModalEcredit,
    getUsers,
  } = useUserManagement();

  return (
    <>
      <Drawer
        anchor="right"
        open={filtersModalOpen}
        onClose={() => {
          handleFiltersModal();
          setShouldFilter(true);
        }}
      >
        <Box sx={style.filters}>
          <FormControl fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="select-status"
              label="Status"
              value={filters?.status}
              onChange={(ev) =>
                dispatchFilters({ type: "status", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                ALL
              </MenuItem>
              {STATUSES.map((status: any) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="service-label">Service</InputLabel>
            <Select
              labelId="service-label"
              id="select-service"
              label="Service"
              value={filters?.service}
              onChange={(ev) =>
                dispatchFilters({ type: "service", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                ALL
              </MenuItem>
              {CHANNEL_COMPONENT.map((service: any) => (
                <MenuItem key={service} value={service}>
                  {service}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="select-role"
              label="Role"
              value={filters?.role}
              onChange={(ev) =>
                dispatchFilters({ type: "role", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                ALL
              </MenuItem>
              {ROLES.map((role: any) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Catalog number"
            value={filters?.catalogNumber}
            onChange={(ev) =>
              dispatchFilters({
                type: "catalogNumber",
                payload: ev.target.value,
              })
            }
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="select-country"
              id="select-country"
              label="Country"
              value={filters?.country}
              onChange={(ev) =>
                dispatchFilters({ type: "country", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                All
              </MenuItem>
              {getSelectCountries.map((country: any) => (
                <MenuItem key={country.countryCode} value={country.countryCode}>
                  {country.countryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Adhoc"
            value={filters?.adhoc}
            onChange={(ev) =>
              dispatchFilters({
                type: "adhoc",
                payload: ev.target.value,
              })
            }
            fullWidth
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Subscription start date"
              endText="Subscription end date"
              value={[
                filters?.subscriptionStartDate,
                filters?.subscriptionEndDate,
              ]}
              onChange={(newDate) =>
                dispatchFilters({
                  type: "subscriptionDate",
                  payload: newDate,
                })
              }
              inputFormat="dd-MM-yyyy"
              mask="__-__-____"
              renderInput={(startProps, endProps) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "100%",
                  }}
                >
                  <TextField {...startProps} fullWidth />
                  <TextField {...endProps} fullWidth />
                </Box>
              )}
            />
          </LocalizationProvider>
          <TextField
            label="Shopify shop name"
            value={filters?.siteName}
            onChange={(ev) =>
              dispatchFilters({
                type: "siteName",
                payload: ev.target.value,
              })
            }
            fullWidth
          />
          <Button
            variant="contained"
            onClick={() => {
              setShouldFilter(true);
              handleFiltersModal();
            }}
          >
            Filter
          </Button>
          <Button variant="outlined" onClick={resetFilters}>
            Reset
          </Button>
        </Box>
      </Drawer>
      <Modal
        open={modalOpen}
        onClose={handleOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditModalContent
          user={editingUser}
          closeModal={handleOpenModal}
          getUsers={getUsers}
        />
      </Modal>
      <Modal
        open={modalOpenEcredit}
        onClose={handleOpenModalEcredit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EcreditModalContent
          user={editingUser}
          closeModal={handleOpenModalEcredit}
        />
      </Modal>
      <Box sx={style.user.wrapper}>
        <Box sx={style.user.action}>
          <TextField
            label="Search by customer email"
            value={searchValue}
            onChange={handleChangeSearchValue}
            size="small"
          />
          <Box>
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => {
                handleFiltersModal();
                setShouldFilter(false);
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Box>
        </Box>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Expiration</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user) => (
                <TableRow key={user?._id}>
                  <TableCell>{user?.email}</TableCell>
                  <TableCell>{user?.roles?.[0]?.name}</TableCell>
                  <TableCell>
                    {getFormattedDate(user?.roles?.[0]?.expires)}
                  </TableCell>
                  <TableCell>{user?.username}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleOpenModal(user)}
                      >
                        <EditIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                      <IconButton
                        aria-label="dollar"
                        size="small"
                        onClick={() => handleOpenModalEcredit(user)}
                      >
                        <MonetizationOn sx={{ fontSize: 20 }} />
                      </IconButton>
                      {/*
                                            <IconButton
                        aria-label="view"
                        size="small"
                        onClick={() => handleOpenModal(user)}
                      >
                        <Visibility sx={{ fontSize: 20 }} />
                      </IconButton>
                         */}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default UserManagement;
