import React, { memo } from "react";
import { Alert, Snackbar } from "@mui/material";
import useFeedback from "./index.hooks";

// eslint-disable-next-line no-shadow
export enum FeedbackTypes {
  Success,
  Info,
  Error,
}

type FeedbackProps = {};

const Feedback = memo(({}: FeedbackProps) => {
  const { open, getSeverity, message } = useFeedback();

  if (!open) return null;
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
    >
      <Alert variant="filled" severity={getSeverity()} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
});

export default Feedback;
