import { Tabs, Tab, Box } from "@mui/material";
import React, { memo } from "react";

type CustomTabsProps = {
  value: number;
  handleChange: any;
  tabs: any[];
  items: any[];
};

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

const CustomTabs = memo(
  ({ value, handleChange, tabs, items }: CustomTabsProps) => (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab label={tab} key={tab} />
          ))}
        </Tabs>
      </Box>
      {items.map((item, index) => (
        <TabPanel value={value} index={index} key={tabs[index]}>
          {item}
        </TabPanel>
      ))}
    </Box>
  )
);

export default CustomTabs;
