import ENDPOINTS from "constants/endpoints";
import { useCallback, useEffect, useState, useMemo } from "react";
import request, { HttpMethod } from "redux/slices/ajax/request";

const useCronJobs = () => {
  const [items, setItems] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState("");
  const [selectedInstance, setSelectedInstance] = useState<any>(null);
  const [nameFilter, setNameFilter] = useState("");
  const [ipAddressFilter, setIpAddressFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [isExpired, setIsExpired] = useState(false);

  const handleOpenModal = useCallback((type, instance) => {
    setModalOpen((prevState) => !prevState);
    setModalType(type);
    setSelectedInstance(instance);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setModalType("");
    setSelectedInstance(null);
  }, []);

  const getItems = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.lightsail}`,
    });
    setItems(response.data);
  }, []);

  useEffect(() => {
    getItems().then();
  }, [getItems]);

  const onSubmit = useCallback(async () => {
    let response: any = {};
    if (modalType === "play") {
      response = await request({
        method: HttpMethod.POST,
        path: `${ENDPOINTS.lightsail}/start`,
        body: {
          name: selectedInstance?.name,
        },
      });
    } else if (modalType === "stop") {
      response = await request({
        method: HttpMethod.POST,
        path: `${ENDPOINTS.lightsail}/stop`,
        body: {
          account: selectedInstance?.account,
          name: selectedInstance?.name,
          force: true,
        },
      });
    } else if (modalType === "delete") {
      response = await request({
        method: HttpMethod.POST,
        path: `${ENDPOINTS.lightsail}/delete`,
        body: {
          name: selectedInstance?.name,
          account: selectedInstance?.account,
        },
      });
    }
    console.log("response", response);
    await getItems();
    handleCloseModal();
  }, [
    getItems,
    handleCloseModal,
    modalType,
    selectedInstance?.account,
    selectedInstance?.name,
  ]);

  const filteredItems = useMemo(() => {
    let tempItems = [...items];
    tempItems = tempItems.filter((item) => item?.name?.includes(nameFilter));
    tempItems = tempItems.filter((item) =>
      item?.privateIpAddress?.includes(ipAddressFilter)
    );
    if (emailFilter) {
      tempItems = tempItems.filter((item) =>
        item?.email?.includes(emailFilter)
      );
    }
    if (isExpired) {
      tempItems = tempItems.filter(
        (item) => new Date() > new Date(item?.roles?.[0]?.expires)
      );
    }
    return tempItems;
  }, [emailFilter, ipAddressFilter, isExpired, items, nameFilter]);

  return {
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    modalType,
    onSubmit,
    nameFilter,
    ipAddressFilter,
    setNameFilter,
    setIpAddressFilter,
    filteredItems,
    emailFilter,
    setEmailFilter,
    isExpired,
    setIsExpired,
  };
};

export default useCronJobs;
