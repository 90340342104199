import React, { useCallback, useMemo, useState } from "react";

type UsePriceObj = {
  suggestedPrice: number;
  streetPrice: number;
};

type UsePlansPriceProps = {
  plan: any;
  onSubmit: (
    { suggestedPrice, streetPrice }: UsePriceObj,
    modelType: string
  ) => void;
};

const usePlansPrice = ({ plan, onSubmit }: UsePlansPriceProps) => {
  const [promoPriceActive, setPromoPriceActive] = useState(
    plan.models.map((model: any) => !!model.streetPrice)
  );
  const [modelTypeIndex, setModelTypeIndex] = useState(0);
  const [suggestedPrice, setSuggestedPrice] = useState(
    plan?.models?.[modelTypeIndex]?.suggestedPrice || "0"
  );
  const [streetPrice, setStreetPrice] = useState(
    plan?.models?.[modelTypeIndex]?.streetPrice || "0"
  );
  const [priceTab, setPriceTab] = useState(0);

  const getModelTabs = useMemo(
    () => plan?.models?.map((model: any) => model.model),
    [plan?.models]
  );

  const TABS = useMemo(() => ["normalPrice", "promoPrice"], []);

  const onClickSave = useCallback(() => {
    const tempSuggestedPrice = parseFloat(suggestedPrice);
    const tempStreetPrice =
      (promoPriceActive && parseFloat(streetPrice)) || null;
    const modelType = plan?.models?.[modelTypeIndex]?.model;
    onSubmit(
      { suggestedPrice: tempSuggestedPrice, streetPrice: tempStreetPrice },
      modelType
    );
  }, [
    modelTypeIndex,
    onSubmit,
    plan?.models,
    promoPriceActive,
    streetPrice,
    suggestedPrice,
  ]);

  const onChangePromoPriceActive = useCallback(
    (index) => {
      const tmpPromoPriceActive = [...promoPriceActive];
      tmpPromoPriceActive[index] = !tmpPromoPriceActive[index];
      setPromoPriceActive(tmpPromoPriceActive);
    },
    [promoPriceActive]
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setModelTypeIndex(newValue);
      setSuggestedPrice(plan?.models?.[newValue]?.suggestedPrice);
      setStreetPrice(plan?.models?.[newValue]?.streetPrice);
    },
    [plan?.models]
  );

  const handleChangePriceTab = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setPriceTab(newValue);
    },
    []
  );

  return {
    modelTypeIndex,
    handleChange,
    getModelTabs,
    priceTab,
    handleChangePriceTab,
    TABS,
    suggestedPrice,
    setSuggestedPrice,
    promoPriceActive,
    onChangePromoPriceActive,
    streetPrice,
    setStreetPrice,
    onClickSave,
  };
};

export default usePlansPrice;
