import { useCallback, useEffect, useMemo, useState } from "react";
import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";
import { useMediaQuery } from "@mui/material";
import theme from "style/theme";

type useEditModalContentProps = {
  carrier: any;
  getVatSystems: any;
  closeModal: () => void;
  isEdit: boolean;
  countries: any[];
  carriers: any[];
};

const useEditModalContent = ({
  carrier,
  getVatSystems,
  closeModal,
  isEdit,
  countries,
  carriers,
}: useEditModalContentProps) => {
  const [editingCarrierFee, setEditingCarrierFee] = useState<any>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isEdit) {
      setEditingCarrierFee(carrier);
    } else {
      setEditingCarrierFee({
        country: "",
        carrier: "",
      });
    }
  }, [carrier, isEdit]);
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempUser = { ...editingCarrierFee };
      tempUser[key] = value;
      setEditingCarrierFee(tempUser);
    },
    [editingCarrierFee]
  );

  const deleteCarrierFee = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.carrierFee}/${editingCarrierFee?._id}`,
      method: HttpMethod.DELETE,
    });
    console.log("response", response);
    await getVatSystems();
    closeModal();
  }, [closeModal, editingCarrierFee?._id, getVatSystems]);

  const updateCarrierFee = useCallback(async () => {
    let response: any = {};
    if (isEdit) {
      response = await request({
        path: `${ENDPOINTS.carrierFee}/${editingCarrierFee?._id}`,
        method: HttpMethod.PUT,
        body: {
          carrier: editingCarrierFee?.carrier,
          country: editingCarrierFee?.country,
          fee: editingCarrierFee?.fee,
          weight: editingCarrierFee?.weight,
        },
      });
      if (response.status === 200) {
        // Apro feedback success
        closeModal();
      }
    } else {
      response = await request({
        path: `${ENDPOINTS.carrierFee}`,
        method: HttpMethod.POST,
        body: {
          carrier: editingCarrierFee?.carrier,
          country: editingCarrierFee?.country,
          fee: editingCarrierFee?.fee,
          weight: editingCarrierFee?.weight,
        },
      });
      if (response.status === 200) {
        // Apro feedback success
        closeModal();
      }
    }
    await getVatSystems();
    console.log("response", response);
  }, [
    closeModal,
    editingCarrierFee?._id,
    editingCarrierFee?.carrier,
    editingCarrierFee?.country,
    editingCarrierFee?.fee,
    editingCarrierFee?.weight,
    getVatSystems,
    isEdit,
  ]);

  const getSelectCountries = useMemo(
    () =>
      countries?.map((country: any) => ({
        countryName: country?.country,
        countryCode: country?.countrycode,
      })),
    [countries]
  );

  const getSelectCarriers = useMemo(
    () =>
      carriers?.map((carrier: any) => ({
        carrierName: carrier.name,
        carrierId: carrier._id,
      })),
    [carriers]
  );
  return {
    handleChangeValue,
    editingCarrierFee,
    updateCarrierFee,
    deleteCarrierFee,
    getSelectCountries,
    getSelectCarriers,
  };
};

export default useEditModalContent;
