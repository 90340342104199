import { useCallback, useEffect, useState } from "react";
import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";
import { useMediaQuery } from "@mui/material";
import theme from "style/theme";

type useEditModalContentProps = {
  carrier: any;
  getCarriers: any;
  closeModal: () => void;
  isEdit: boolean;
};

const useEditModalContent = ({
  carrier,
  getCarriers,
  closeModal,
  isEdit,
}: useEditModalContentProps) => {
  const [editingCarrier, setEditingCarrier] = useState<any>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setEditingCarrier(carrier);
  }, [carrier]);
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempUser = { ...editingCarrier };
      tempUser[key] = value;
      setEditingCarrier(tempUser);
    },
    [editingCarrier]
  );

  const deleteCarrier = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.carrier}/${editingCarrier?._id}`,
      method: HttpMethod.DELETE,
    });
    console.log("response", response);
    await getCarriers();
    closeModal();
  }, [closeModal, editingCarrier?._id, getCarriers]);

  const updateCarrier = useCallback(async () => {
    let response: any = {};
    if (isEdit) {
      response = await request({
        path: `${ENDPOINTS.carrier}/${editingCarrier?._id}`,
        method: HttpMethod.PUT,
        body: {
          days: editingCarrier?.days,
          daysLiteral: editingCarrier?.daysLiteral,
          logo: editingCarrier?.logo,
          name: editingCarrier?.name,
          note: editingCarrier?.note,
          online: editingCarrier?.online,
          refId: editingCarrier?.refId,
        },
      });
      if (response.status === 200) {
        // Apro feedback success
        closeModal();
      }
    } else {
      response = await request({
        path: `${ENDPOINTS.carrier}`,
        method: HttpMethod.POST,
        body: {
          days: editingCarrier?.days,
          daysLiteral: editingCarrier?.daysLiteral,
          logo: editingCarrier?.logo,
          name: editingCarrier?.name,
          note: editingCarrier?.note,
          online: editingCarrier?.online,
          refId: editingCarrier?.refId,
        },
      });
      if (response.status === 200) {
        // Apro feedback success
        closeModal();
      }
    }
    await getCarriers();
    console.log("response", response);
  }, [
    closeModal,
    editingCarrier?._id,
    editingCarrier?.days,
    editingCarrier?.daysLiteral,
    editingCarrier?.logo,
    editingCarrier?.name,
    editingCarrier?.note,
    editingCarrier?.online,
    editingCarrier?.refId,
    getCarriers,
    isEdit,
  ]);
  return {
    handleChangeValue,
    editingCarrier,
    updateCarrier,
    deleteCarrier,
  };
};

export default useEditModalContent;
