import React from "react";

type ReturnTagProps = {
  className: string;
  children: any;
};

export default function ReturnTag({ className, children }: ReturnTagProps) {
  return (
    <>
      <div className={`${className} return-tag`}>{children}</div>
      <style>{`
        .return-tag {
          display: inline-block;
          width: auto;
          min-width: 7em;
          padding: 3px 6px;
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          border-radius: 2px;
          color: #00596d;
          background: #cafde0;
        }
        
        .pending {
          color: #0241b0;
          background: #adecfe;
        }

        .approved {
          color: #00596d;
          background: #cafde0;
        }

        .waiting_arrival {
          color: #0241b0;
          background: #adecfe;
        }

        .not_arrived {
          color: #1b9250;
          background: #dcfcd7;
        }

        .error {
          color: #b7263f;
          background: #ffe7db;
        }
        .refunded {
          color: #784500;
          background: antiquewhite;
        }
        .dispatched {
          color: #1b9250;
          background: #dcfcd7;
        }
      `}</style>
    </>
  );
}
