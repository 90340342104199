import ENDPOINTS from "constants/endpoints";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import request from "redux/slices/ajax/request";
import { useSelector } from "react-redux";
import { TURNKEY_STATUSES } from "../../constants/contants";
import { getCountries } from "../../redux/slices/platform-data/platform-data.selectors";

const initialState = {
  status: "",
  country: "",
  email: "",
  orderId: "",
  trackingCode: "",
  transRef: "",
  order: "",
  paid: "",
  startDate: null,
  endDate: null,
};

const useOrders = () => {
  const [page, setPage] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [modalOpen, setModalOpen] = useState<number>(-1);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const countries = useSelector(getCountries);
  const [shouldFilter, setShouldFilter] = useState(true);
  const handleCloseModal = useCallback(() => {
    setModalOpen(-1);
  }, []);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const filtersReducer = (state: any, action: any) => {
    switch (action.type) {
      case "status":
        return { ...state, status: action.payload };
      case "paid":
        return { ...state, paid: action.payload };
      case "country":
        return { ...state, country: action.payload };
      case "email":
        return { ...state, email: action.payload };
      case "orderId":
        return { ...state, orderId: action.payload };
      case "trackingCode":
        return { ...state, trackingCode: action.payload };
      case "transRef":
        return { ...state, transRef: action.payload };
      case "order":
        return { ...state, order: action.payload };
      case "startDate":
        return { ...state, startDate: action.payload };
      case "endDate":
        return { ...state, endDate: action.payload[1] };
      case "reset":
        return initialState;
      default:
        return { [action.type]: action.payload };
    }
  };

  const [filters, dispatchFilters] = useReducer(filtersReducer, initialState);

  const getSelectStatusValues = () => {
    const statuses = [...TURNKEY_STATUSES];
    statuses.unshift("All");
    return statuses;
  };

  const handleOpenModal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, index) => {
      setAnchorEl(event.currentTarget);
      setModalOpen(index);
    },
    []
  );

  const handleFiltersModal = useCallback(() => {
    setFiltersModalOpen((prev) => !prev);
  }, []);

  const getItems = useCallback(async () => {
    let response: any = {};
    const $or: any = [];
    if (filters.email) {
      $or.push({ "recipient.email": { $regex: filters.email } });
    }
    if (filters.order) {
      $or.push(
        {
          "ids.user": Number(filters.order),
        },
        {
          "ids.source": Number(filters.order),
        },
        {
          "ids.platform": Number(filters.order),
        }
      );
    }
    const q: any = {
      "childrens.0": { $exists: false },
    };
    if ($or.length) {
      q.$or = $or;
    }
    if (filters.status) {
      q.status = filters.status;
    }
    if (filters.paid === true || filters.paid === false) {
      q.paid = filters.paid;
    }
    if (filters.trackingCode) {
      q.trackingCode = filters.trackingCode;
    }
    if (filters.transRef) {
      q["paymentGW.chargeId"] = filters.transRef;
    }
    if (filters.orderId) {
      q["ids.external"] = filters.orderId;
    }
    if (filters.country) {
      q["recipient.address.countrycode"] = filters.country;
    }
    const date: any = {};

    if (filters?.startDate) {
      date.$gte = filters.startDate.toISOString();
    }

    if (filters?.endDate) {
      date.$lte = filters.endDate.toISOString();
    }

    if (date.$lte || date.$gte) {
      q.date = date;
    }
    response = await request({
      path: `${ENDPOINTS.orders}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q,
        sortings: {
          date: -1,
        },
        populate: [{ path: "user", select: ["email", "username"] }],
      },
    });
    setItems(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [
    filters.country,
    filters.email,
    filters.endDate,
    filters.order,
    filters.orderId,
    filters.paid,
    filters.startDate,
    filters.status,
    filters.trackingCode,
    filters.transRef,
    page,
    pageSize,
  ]);

  useEffect(() => {
    if (shouldFilter) {
      getItems().then();
    }
  }, [getItems, shouldFilter]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetFilters = useCallback(() => {
    dispatchFilters({ type: "reset" });
  }, []);

  const getSelectCountries = useMemo(
    () =>
      countries?.map((country: any) => ({
        countryName: country?.country,
        countryCode: country?.countrycode,
      })),
    [countries]
  );

  return {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    getSelectStatusValues,
    anchorEl,
    handleFiltersModal,
    filtersModalOpen,
    filters,
    dispatchFilters,
    resetFilters,
    getSelectCountries,
    setShouldFilter,
    setPage,
  };
};

export default useOrders;
