import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBannersGallery,
  getBannersGalleryId,
} from "redux/slices/platform-data/platform-data.selectors";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
} from "@mui/material";
import { Edit } from "@mui/icons-material/";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { actions } from "redux/slices";
import style from "./index.style";
import { generateString } from "../../../utils";

function BannersGallery() {
  const banners = useSelector(getBannersGallery);
  const id = useSelector(getBannersGalleryId);
  const dispatch = useDispatch();
  const [editBanner, setEditBanner] = useState<[string, any]>([
    "",
    {
      img: "",
      link: "",
      name: "",
      priority: "",
      status: false,
    },
  ]);
  const containerRef = useRef(null);
  const [isNew, setIsNew] = useState(false);

  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempItem: [string, any] = [editBanner[0], { ...editBanner[1] }];
      tempItem[1][key] = value;
      setEditBanner(tempItem);
    },
    [editBanner]
  );

  const selectBanner = useCallback((banner: any) => {
    setEditBanner(banner);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(false);
  }, []);

  const onSave = useCallback(async () => {
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          [`body.BannersGallery.${editBanner[0]}`]: editBanner[1],
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, editBanner, id]);

  const onDelete = useCallback(async () => {
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          [`body.BannersGallery.${editBanner[0]}`]: {},
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, editBanner, id]);

  const createNew = useCallback(() => {
    const randomKey = generateString(20);
    setEditBanner([
      randomKey,
      {
        img: "",
        link: "",
        name: "",
        priority: "",
        status: false,
      },
    ]);
    setIsNew(true);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(true);
  }, []);

  return (
    <Box sx={style.bannersGallery}>
      <Box>
        <Button variant="contained" onClick={createNew}>
          Create
        </Button>
      </Box>
      <Box sx={style.imagesContainer}>
        {Object.entries(banners)
          .sort(([keya, a]: [string, any], [keyb, b]: [string, any]) => {
            if (Number(a.priority) < Number(b.priority)) return 1;
            if (Number(a.priority) > Number(b.priority)) return -1;
            return 0;
          })
          .map((banner: [string, any]) => (
            <Box sx={style.imageContainer}>
              <Box sx={[style.bannerInfo, style.bannerName]}>
                {banner[1].name}
              </Box>
              <Box sx={[style.bannerInfo, style.bannerStatus]}>
                {banner[1].status ? "Enabled" : "Disabled"}
              </Box>
              <Box sx={[style.bannerInfo, style.bannerPriority]}>
                {banner[1].priority}
              </Box>
              <IconButton
                sx={[style.bannerEditIcon, style.bannerInfo]}
                onClick={() => selectBanner(banner)}
              >
                <Edit />
              </IconButton>
              <img
                style={{
                  width: 230,
                  border:
                    banner[0] === editBanner[0] ? "3px solid #0F45FF" : "",
                }}
                src={banner[1].img}
                alt="banner"
              />
            </Box>
          ))}
      </Box>
      {editBanner[0] && (
        <Paper sx={style.editContainer} ref={containerRef}>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4}>
              <TextField
                fullWidth
                label="Name"
                value={editBanner[1]?.name}
                onChange={(ev) => handleChangeValue(ev.target.value, "name")}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                fullWidth
                label="Priority"
                value={editBanner[1]?.priority}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "priority")
                }
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editBanner[1]?.status as boolean}
                    onChange={() =>
                      handleChangeValue(!editBanner[1].status, "status")
                    }
                  />
                }
                label="Online"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Link"
                value={editBanner[1]?.link}
                onChange={(ev) => handleChangeValue(ev.target.value, "link")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Image"
                value={editBanner[1]?.img}
                onChange={(ev) => handleChangeValue(ev.target.value, "img")}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Button variant="contained" color="primary" onClick={onSave}>
                Save
              </Button>
            </Grid>
            {!isNew && (
              <Grid item xs={3} sm={3}>
                <Button variant="contained" color="error" onClick={onDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default BannersGallery;
