import React, { useCallback, useMemo, useState } from "react";

type UseVatRulesProps = {
  page: number;
  pageSize: number;
  vatRules: any[];
  vatSystems: any[];
  platforms: any[];
  totalNumberOfElements: number;
  setPage: any;
  setPageSize: any;
  getVatRules: any;
  searchPlatform: any;
  searchVat: any;
  setSearchPlatform: any;
  setSearchVat: any;
};

const useVatRules = ({
  page,
  pageSize,
  vatRules,
  vatSystems,
  platforms,
  totalNumberOfElements,
  setPage,
  setPageSize,
  getVatRules,
  searchPlatform,
  searchVat,
  setSearchVat,
  setSearchPlatform,
}: UseVatRulesProps) => {
  const [carriers, setVatRules] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingVatRules, setEditingVatRules] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingVatRules(null);
  }, []);

  const handleOpenModal = useCallback((carrier?: any, tempIsEdit?: boolean) => {
    setIsEdit(tempIsEdit || false);
    if (tempIsEdit) {
      setEditingVatRules(carrier);
    } else {
      setEditingVatRules({
        online: true,
        refId: 0,
        days: 0,
      });
    }
    setModalOpen((prevState) => !prevState);
  }, []);

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getSelectPlatforms = useMemo(() => {
    const selectPlatforms = platforms?.map((platform: any) => ({
      platformName: platform?.country,
      platformId: platform?._id,
    }));
    selectPlatforms.unshift({
      platformName: "All",
      platformId: "All",
    });
    return selectPlatforms;
  }, [platforms]);

  const getSelectVats = useMemo(() => {
    const selectVat = vatSystems?.map((vat: any) => ({
      vatCode: vat.code,
      vatId: vat._id,
    }));
    selectVat.unshift({
      vatCode: "All",
      vatId: "All",
    });
    return selectVat;
  }, [vatSystems]);

  const handleChangeSelectPlatformValue = useCallback(
    (value: string) => {
      setSearchPlatform(value);
    },
    [setSearchPlatform]
  );

  const handleChangeSelectVatValue = useCallback(
    (value: string) => {
      setSearchVat(value);
    },
    [setSearchVat]
  );

  return {
    page,
    carriers,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    modalOpen,
    handleOpenModal,
    editingVatRules,
    isEdit,
    handleCloseModal,
    getSelectPlatforms,
    getSelectVats,
    handleChangeSelectPlatformValue,
    handleChangeSelectVatValue,
  };
};

export default useVatRules;
