import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsCms,
  getProductsCmsId
} from "redux/slices/platform-data/platform-data.selectors";
import {
  Box,
  Button, FormControlLabel,
  Grid,
  IconButton,
  Paper, Switch,
  TextField,
  Typography
} from "@mui/material";
import { Edit } from "@mui/icons-material/";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { actions } from "redux/slices";
import style from "./index.style";

interface Block {
  subTitle: string,
  title: string,
  desc:string,
  flexReverse:boolean,
  img: string,
  background:string,
}
const initialFormState = {
  subTitle: '',
  title: '',
  desc:'',
  flexReverse:false,
  img: '',
  background:'',
};

function ProductsCMS() {
  const productsCms = useSelector(getProductsCms);
  const id = useSelector(getProductsCmsId);

  const dispatch = useDispatch();
  const [editProduct, setEditProduct] = useState<[string, any]>([
    "",
    [],
  ]);
  const containerRef = useRef(null);
  const [isNew, setIsNew] = useState(false);
  const selectProduct = useCallback((product: any) => {
    setEditProduct(product)
    setBlocks(product[1]);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(false);
  }, []);
  const [blocks, setBlocks] = useState<Block[]>([]);

  const handleChangeSku = useCallback(
    (value: any) => {
      const tempItem: [string, any] = [editProduct[0], [...editProduct[1]]];
      tempItem[0] = value;
      setEditProduct(tempItem);
    },
    [editProduct]
  );

  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexProduct, setIndexProduct] = useState(0);
  const [formValues, setFormValues] = useState(initialFormState);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newBlock: Block = {
      subTitle: formValues.subTitle,
      title: formValues.title,
      desc:formValues.desc,
      flexReverse:formValues.flexReverse,
      img:formValues.img,
      background:formValues.background,
    };
    setBlocks([...blocks, newBlock]);
    setShowForm(false);
    setFormValues(initialFormState);
  };
  const handleEditFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newBlock: Block = {
      subTitle: formValues.subTitle,
      title: formValues.title,
      desc:formValues.desc,
      flexReverse:formValues.flexReverse,
      img:formValues.img,
      background:formValues.background,
    };
   const newBlocks = [...blocks];
    newBlocks.splice(indexProduct, 1, newBlock);
    setBlocks(newBlocks);
   setShowForm(false);
   setIsEdit(false);
   setFormValues(initialFormState);
  };
  const clearFormValue =(event: React.FormEvent) => {
    event.preventDefault();
    setFormValues(initialFormState);
    setShowForm(false);
    setIsEdit(false)
  }
  const handleDelete = (index: number) => {
    const updatedBlocks = [...blocks];
    updatedBlocks.splice(index, 1);
    setBlocks(updatedBlocks);
  };
  const handleEdit = (index:number) => {
    setShowForm(true);
    setIsEdit(true);
    const blockValues={
      title: blocks[index].title,
      subTitle: blocks[index].subTitle,
      desc:blocks[index].desc,
      flexReverse:blocks[index].flexReverse,
      img:blocks[index].img,
      background:blocks[index].background,
    }
    setIndexProduct(index);
    setFormValues(blockValues);
  }

  const onSave = useCallback(async () => {
    let tempEditbanner = [...editProduct[1]];
    console.log('tempEditbanner',tempEditbanner)
    if (blocks) {
      tempEditbanner= [...blocks];
    }
    if(editProduct[0]){
      console.log('testttaaaa')
    }
      try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          body: {
            products: {
              ...productsCms,
              [editProduct[0]]: tempEditbanner,
            },
          },
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [productsCms, dispatch, editProduct,blocks,id]);
  const onDelete = useCallback(async () => {
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          [`body.products.${editProduct[0]}`]: {},
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
      setIsNew(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, editProduct,id]);
  const createNew = useCallback(() => {
    setBlocks([]);
    setEditProduct([
      "",
      [],
    ]);
    setIsNew(true);
    setTimeout(() => {
      // @ts-ignore
      containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setIsNew(true);
  }, []);

  useEffect(()=>{
    if(editProduct[1].length > 0){
      setBlocks(editProduct[1])
    }
  },[editProduct])

   return (
    <Box sx={style.bannersGallery}>
      <Box>
        <Button variant="contained" onClick={createNew} sx={{ mt: 3 }}>
          Create
        </Button>
      </Box>
      <Box sx={style.imagesContainer}>
        {productsCms  &&
          Object?.entries(productsCms)?.map((product: [string, any]) => (
              <Box sx={style.imageContainer}>
                <Box sx={[style.bannerInfo, style.bannerName]}>
                  {product[0]}
                </Box>
                <IconButton
                  sx={[style.bannerEditIcon, style.bannerInfo]}
                  onClick={() => selectProduct(product)}
                >
                  <Edit />
                </IconButton>
                <img
                  style={{
                    width: 300,
                    border:"3px solid #0F45FF"
                  }}
                  src={product[1]?.[0]?.img}
                  alt="banner"
                />
              </Box>
            )
           )}
      </Box>
      {(editProduct?.[0] || isNew) && (
        <Paper sx={style.editContainer} ref={containerRef}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} item>
              <Box component="div" sx={{ display: 'flex', justifyContent:'space-between',width:'100%' }}>
                <div style={{display:'flex',gap:20}}>
                <Typography variant="h3" sx={{margin:'3px 0'}}>Blocchi prodotto</Typography>
                </div>
                <Button onClick={() => setShowForm(true)} variant="outlined" color="primary">
                  Aggiungi blocco
                </Button>
              </Box>
              <Grid item xs={6} sm={6}>
                <div style={{margin:'10px 0px'}}>Inserisci lo SKU del prodotto</div>
                <TextField
                  fullWidth
                  value={editProduct[0]}
                  onChange={(ev) =>
                    handleChangeSku(ev.target.value)
                  }
                />
              </Grid>
            </Grid>
            {showForm && (
              <>
                <Grid item xs={6} sm={6}>
                  <TextField
                    label="Titolo"
                    type="text"
                    fullWidth
                    value={formValues.title}
                    onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                <TextField
                    label="Sottotitolo"
                    type="text"
                    fullWidth
                    value={formValues.subTitle}
                    onChange={(e) => setFormValues({ ...formValues, subTitle: e.target.value })}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    label="Image URL"
                    fullWidth
                    type="text"
                    value={formValues.img}
                    onChange={(e) => setFormValues({ ...formValues, img: e.target.value })}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    label="Background"
                    fullWidth
                    type="text"
                    value={formValues.background}
                    onChange={(e) => setFormValues({ ...formValues, background: e.target.value })}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Descrizione"
                    fullWidth
                    type="text"
                    value={formValues.desc}
                    onChange={(e) => setFormValues({ ...formValues, desc: e.target.value })}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid xs={12} sm={12} item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formValues.flexReverse as boolean}
                        onChange={(e) => setFormValues({ ...formValues, flexReverse: e.target.checked })}
                      />
                    }
                    label="Attiva per invertire immagine e testo da desktop"
                  />
                </Grid>


                <Grid item xs={12} sm={12}>
                  {!isEdit &&
                    <Button type="submit" variant="contained" color="primary" onClick={handleFormSubmit}>
                      Aggiungi
                    </Button>
                  }
                  {isEdit &&
                    <Button type="submit" variant="contained" color="primary" onClick={handleEditFormSubmit}>
                      Conferma
                    </Button>
                  }
                  <Button type="submit" variant="text" color="error" onClick={clearFormValue}>
                    Cancella
                  </Button>
                </Grid>
              </>
            )}
            <Box component="div" sx={{display: 'flex', gap:2, width:'100%',flexWrap:'wrap',margin:3}}>
                {blocks?.map((block, index) => (
                  <Grid item sx={{padding:2, border:'solid thin #ccc', minWidth:'300px', maxWidth:'300px',position:'relative'}}>
                    <div style={{position:'absolute',top:2,right:2,cursor:'pointer',color:'blue'}}>
                      <Button onClick={()=>handleEdit(index)} variant="outlined" color="primary">
                       Edit
                      </Button>
                    </div>
                    <img src={block?.img} width={130} alt={block?.title} loading="lazy"/>
                    <Typography sx={{color:'blue'}}>Titolo: {block.title}</Typography>
                    <Typography sx={{color:'blue'}}>Sottotitolo: {block.subTitle}</Typography>
                    <Typography>Descrizione: {block.desc}</Typography>
                    <Button onClick={() => handleDelete(index)} variant="text" color="error">
                      Delete
                    </Button>
                  </Grid>
                ))}
            </Box>
            <Grid item xs={12} sm={12}>
              <div style={{display:'flex',gap:10,justifyContent:'center'}}>
              <Button variant="contained" color="primary" onClick={onSave} disabled={blocks?.length <= 0}>
                Save
              </Button>
              {!isNew && (
                  <Button variant="contained" color="error" onClick={onDelete}>
                    Delete
                  </Button>
              )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default ProductsCMS;
