import globalStyle from "index.style";

const useStyles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    p: 4,
    flexWrap: "wrap",
    overflowX: "scroll",
    maxHeight: "80vh",
    closeButton: {
      position: "absolute",
      top: 5,
      right: 5,
    },
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "thin solid #ccc5",
    jusifyContent: "space-between",
    width: "100%",
  },
  itemRowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  itemRowText: {
    display: "flex",
    flexDirection: "column",
    alignItem: "flex-start",
  },
} as const;

export default { ...globalStyle, ...useStyles };
