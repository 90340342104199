import React, { memo, useCallback, useMemo } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import prefixes from "constants/prefixes.json";
import { getCountries } from "../../../../redux/slices/platform-data/platform-data.selectors";

type AddressProps = {
  order: any;
  setOrder: any;
};

const Address = memo(({ order, setOrder }: AddressProps) => {
  const countries = useSelector(getCountries);
  // @ts-ignore
  const setProperty = useCallback((obj: any, path: any, value: any) => {
    const [head, ...rest] = path.split(".");
    return {
      ...obj,
      [head]: rest.length
        ? setProperty(obj[head], rest.join("."), value)
        : value,
    };
  }, []);
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      let tempItem = { ...order };
      tempItem = setProperty(tempItem, key, value);
      setOrder(tempItem);
    },
    [order, setOrder, setProperty]
  );

  const getSelectCountries = useMemo(
    () =>
      countries?.map((country: any) => ({
        countryName: country?.country,
        countryCode: country?.countrycode,
      })),
    [countries]
  );

  const getSelectPrefixes = useMemo(
    () =>
      prefixes?.map((prefix: any) => ({
        name: prefix?.name,
        dial_code: prefix?.dial_code,
      })),
    []
  );

  if (!order?.recipient) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          label="Email"
          value={order?.recipient?.email}
          onChange={(ev) =>
            handleChangeValue(ev.target.value, "recipient.email")
          }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          label="Name"
          value={order?.recipient?.recipient}
          onChange={(ev) =>
            handleChangeValue(ev.target.value, "recipient.recipient")
          }
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          fullWidth
          label="City"
          value={order?.recipient?.address?.city}
          onChange={(ev) =>
            handleChangeValue(ev.target.value, "recipient.address.city")
          }
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="select-country"
            id="select-country"
            label="Country"
            value={order?.recipient?.address?.countrycode}
            onChange={(ev) =>
              handleChangeValue(
                ev.target.value,
                "recipient.address.countrycode"
              )
            }
          >
            {getSelectCountries.map((country: any) => (
              <MenuItem key={country.countryCode} value={country.countryCode}>
                {country.countryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          label="Street name"
          value={order?.recipient?.address?.streetName}
          onChange={(ev) =>
            handleChangeValue(ev.target.value, "recipient.address.streetName")
          }
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          fullWidth
          label="Province"
          value={order?.recipient?.address?.province}
          onChange={(ev) =>
            handleChangeValue(ev.target.value, "recipient.address.province")
          }
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          fullWidth
          label="Zip Code"
          value={order?.recipient?.address?.zip}
          onChange={(ev) =>
            handleChangeValue(ev.target.value, "recipient.address.zip")
          }
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="country-label">Prefix</InputLabel>
          <Select
            labelId="select-country"
            id="select-country"
            label="Prefix"
            value={order?.recipient?.phone?.prefix}
            onChange={(ev) =>
              handleChangeValue(ev.target.value, "recipient.phone.prefix")
            }
          >
            {getSelectPrefixes.map((prefix: any) => (
              <MenuItem key={prefix.dial_code} value={prefix.dial_code}>
                ({prefix.dial_code}) - {prefix?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          fullWidth
          label="Number"
          value={order?.recipient?.phone?.number}
          onChange={(ev) =>
            handleChangeValue(ev.target.value, "recipient.phone.number")
          }
        />
      </Grid>
    </Grid>
  );
});

export default Address;
