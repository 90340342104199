import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import useEditModalContent from "./index.hooks";
import style from "./index.style";

type EditModalContentProps = {
  item: any;
  closeModal: () => void;
  getItems: any;
};

const EditModalContent = memo(
  ({ item, closeModal, getItems }: EditModalContentProps) => {
    const {
      handleChangeValue,
      editingItem,
      updateItem,
      getSelectCountries,
      handleChangeSelectValue,
    } = useEditModalContent({
      item,
      getItems,
      closeModal,
    });
    const selectValues = ["true", "false"];
    return (
      <div>
        {editingItem && (
          <Box sx={style.modal}>
            <Grid container spacing={3}>
              <>
                <IconButton sx={style.modal.closeButton} onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Edit Brand
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={editingItem?.name}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "name")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="restrictions-label">
                      Restrictions
                    </InputLabel>
                    <Select
                      labelId="select-restrictions"
                      id="select-restrictions"
                      label="Restrictions"
                      multiple
                      value={editingItem?.restrictions || []}
                      onChange={(ev) =>
                        handleChangeSelectValue(ev.target.value, "restrictions")
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: string) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {getSelectCountries.map((country: any) => (
                        <MenuItem
                          key={country.countryCode}
                          value={country.countryCode}
                        >
                          {country.countryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="hidden-label">Hidden</InputLabel>
                    <Select
                      labelId="hidden-country"
                      id="hidden-country"
                      label="Hidden"
                      value={editingItem?.hidden}
                      onChange={(ev) =>
                        handleChangeValue(ev.target.value, "hidden")
                      }
                    >
                      {selectValues.map((value: any) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="active-label">Active</InputLabel>
                    <Select
                      labelId="select-active"
                      id="select-active"
                      label="Active"
                      value={editingItem?.active}
                      onChange={(ev) =>
                        handleChangeValue(ev.target.value, "active")
                      }
                    >
                      {selectValues.map((value: any) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" size="large" onClick={updateItem}>
                    Save
                  </Button>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
      </div>
    );
  }
);

export default EditModalContent;
