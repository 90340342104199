import React, { memo } from "react";
import PlansPrice from "./PlansPrice";
import PlansDescription from "./PlansDescription";
import usePlansPrice from "./index.hooks";

type PlanProps = {
  plan: any;
};

const Plan = memo(({ plan }: PlanProps) => {
  const {
    model,
    onSubmit,
    descriptionEditorState,
    setDescriptionEditorState,
    secondDescriptionEditorState,
    setSecondDescriptionEditorState,
    thirdDescriptionEditorState,
    setThirdDescriptionEditorState,
    language,
    setLanguage,
  } = usePlansPrice({ plan });
  return (
    <>
      <PlansPrice plan={plan} onSubmit={onSubmit} />
      <PlansDescription
        model={model}
        descriptionEditorState={descriptionEditorState}
        setDescriptionEditorState={setDescriptionEditorState}
        secondDescriptionEditorState={secondDescriptionEditorState}
        setSecondDescriptionEditorState={setSecondDescriptionEditorState}
        thirdDescriptionEditorState={thirdDescriptionEditorState}
        setThirdDescriptionEditorState={setThirdDescriptionEditorState}
        language={language}
        setLanguage={setLanguage}
        onSubmit={onSubmit}
      />
    </>
  );
});

export default Plan;
