import * as feedback from "./feedback";
import * as user from "./user";
import * as ajax from "./ajax";
import * as platformData from "./platform-data";

export const reducers = {
  ajax: ajax.ajaxStore.reducer,
  feedback: feedback.feedbackStore.reducer,
  user: user.userStore.reducer,
  platformData: platformData.platformDataStore.reducer,
};

export const actions = {
  ...ajax.ajaxStore.actions,
  ...feedback.feedbackStore.actions,
  ...user.userStore.actions,
  ...platformData.platformDataStore.actions,
};

export const selectors = {
  ...ajax.selectors,
  ...feedback.selectors,
  ...user.selectors,
  ...platformData.selectors,
};

export const sagas = [...Object.values(user.sagas)];
