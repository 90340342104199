import {
  AccessTime,
  AddBusiness,
  Apps,
  AttachMoney,
  BarChart,
  ConfirmationNumber,
  Description,
  DirectionsBus,
  HomeWork,
  InsertDriveFile,
  KeyboardReturn,
  LocalOffer,
  Paid,
  Payment,
  Photo,
  PointOfSale,
  Sailing,
  Savings,
  Settings,
  Translate,
  ViewSidebar,
} from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GroupIcon from "@mui/icons-material/Group";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import style from "./index.style";
import { isReadOnly } from "../../redux/slices/user/user.selectors";

const drawerWidth = 250;

function DrawerContent() {
  const location = useSelector(
    (state: RootState) => state.router.location.pathname
  );
  const userIsReadOnly = useSelector(isReadOnly);

  const LETSELL = process.env.REACT_APP_ENV === "Letsell";

  return (
    <Box sx={{ mt: "60px" }}>
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Home
          </ListSubheader>
        }
      >
        <ListItemButton
          component="a"
          href="/statistics"
          selected={location === "/statistics" || location === "/"}
        >
          <ListItemIcon>
            <BarChart />
          </ListItemIcon>
          <ListItemText
            sx={{ fontSize: 14 }}
            primary="Statistics"
            disableTypography
          />
        </ListItemButton>
        <ListItemButton
          component="a"
          href="/statistics-table"
          selected={location === "/statistics-table"}
        >
          <ListItemIcon>
            <BarChart />
          </ListItemIcon>
          <ListItemText
            sx={{ fontSize: 14 }}
            primary="Statistics Table"
            disableTypography
          />
        </ListItemButton>
      </List>
      {!userIsReadOnly && (
        <>
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Customers
              </ListSubheader>
            }
          >
            <ListItemButton
              component="a"
              href="/users"
              selected={location === "/users"}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText
                primary="Users"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/address-management"
              selected={location === "/address-management"}
            >
              <ListItemIcon>
                <HomeWork />
              </ListItemIcon>
              <ListItemText
                primary="Addresses"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
          </List>
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Orders
              </ListSubheader>
            }
          >
            <ListItemButton
              component="a"
              href="/orders"
              selected={location === "/orders"}
            >
              <ListItemIcon>
                <Paid />
              </ListItemIcon>
              <ListItemText
                primary="Orders"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/logs"
              selected={location === "/logs"}
            >
              <ListItemIcon>
                <Payment />
              </ListItemIcon>
              <ListItemText
                primary="Subscriptions"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/returns"
              selected={location === "/returns"}
            >
              <ListItemIcon>
                <KeyboardReturn />
              </ListItemIcon>
              <ListItemText
                primary="Returns"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/invoices-management"
              selected={location === "/invoices-management"}
            >
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText
                primary="Invoices"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/bills"
              selected={location === "/bills"}
            >
              <ListItemIcon>
                <InsertDriveFile />
              </ListItemIcon>
              <ListItemText
                primary="Bills and Customers"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/ecredit"
              selected={location === "/ecredit"}
            >
              <ListItemIcon>
                <Savings />
              </ListItemIcon>
              <ListItemText
                primary="Withdraw requests"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
          </List>
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                General
              </ListSubheader>
            }
          >
            {LETSELL && (
              <ListItemButton
                component="a"
                href="/sidebar"
                selected={location === "/sidebar"}
              >
                <ListItemIcon>
                  <ViewSidebar />
                </ListItemIcon>
                <ListItemText
                  primary="Sidebar"
                  sx={{ fontSize: 14 }}
                  disableTypography
                />
              </ListItemButton>
            )}
            <ListItemButton
              component="a"
              href="/vouchers"
              selected={location === "/vouchers"}
            >
              <ListItemIcon>
                <ConfirmationNumber />
              </ListItemIcon>
              <ListItemText
                primary="Vouchers"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/settings"
              selected={location === "/settings"}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText
                primary="Settings"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            {/*
                  <ListItemButton
          component="a"
          href="/catalogs"
          selected={location === "/catalogs"}
        >
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <ListItemText
            primary="Catalogs management"
            sx={{ fontSize: 14 }}
            disableTypography
          />
        </ListItemButton>
           */}

            <ListItemButton
              component="a"
              href="/turnkey"
              selected={location === "/turnkey"}
            >
              <ListItemIcon>
                <AddBusiness />
              </ListItemIcon>
              <ListItemText
                primary="Turnkey"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/plans"
              selected={location === "/plans"}
            >
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText
                primary="Plans"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/service"
              selected={location === "/service"}
            >
              <ListItemIcon>
                <PointOfSale />
              </ListItemIcon>
              <ListItemText
                primary="Services"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/translations"
              selected={location === "/translations"}
            >
              <ListItemIcon>
                <Translate />
              </ListItemIcon>
              <ListItemText
                primary="Translations"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/rate-management"
              selected={location === "/rate-management"}
            >
              <ListItemIcon>
                <DirectionsBus />
              </ListItemIcon>
              <ListItemText
                primary="Rate management"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/tags"
              selected={location === "/tags"}
            >
              <ListItemIcon>
                <LocalOffer />
              </ListItemIcon>
              <ListItemText
                primary="Tags"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/orders-services"
              selected={location === "/orders-services"}
            >
              <ListItemIcon>
                <Apps />
              </ListItemIcon>
              <ListItemText
                primary="Orders Services"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/cron-job"
              selected={location === "/cron-job"}
            >
              <ListItemIcon>
                <AccessTime />
              </ListItemIcon>
              <ListItemText
                primary="Cron jobs"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/lightsail-management"
              selected={location === "/lightsail-management"}
            >
              <ListItemIcon>
                <Sailing />
              </ListItemIcon>
              <ListItemText
                primary="Lightsail"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
            <ListItemButton
              component="a"
              href="/photo-download"
              selected={location === "/photo-download"}
            >
              <ListItemIcon>
                <Photo />
              </ListItemIcon>
              <ListItemText
                primary="Photo download"
                sx={{ fontSize: 14 }}
                disableTypography
              />
            </ListItemButton>
          </List>
        </>
      )}
    </Box>
  );
}
type SidebarProps = {
  mobileOpen: boolean;
  handleDrawer: () => void;
};

const Sidebar = memo(({ mobileOpen, handleDrawer }: SidebarProps) => (
  <>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          zIndex: 10,
        },
        display: { xs: "none", sm: "block" },
      }}
      variant="permanent"
      anchor="left"
      open={mobileOpen}
      onClose={handleDrawer}
    >
      <DrawerContent />
    </Drawer>
    <Drawer
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
        },
      }}
      variant="temporary"
      anchor="left"
      open={mobileOpen}
    >
      <>
        <IconButton onClick={handleDrawer} sx={style.sidebar.iconToggle}>
          <ArrowForwardIcon />
        </IconButton>
        <DrawerContent />
      </>
    </Drawer>
  </>
));

export default Sidebar;
