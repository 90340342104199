import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import { AttachMoney } from "@mui/icons-material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import { getFormattedDate } from "../../utils";
import useEcredit from "./index.hooks";
import style from "./index.style";

type EcreditProps = {};

const Ecredit = memo(({}: EcreditProps) => {
  const {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    confirmWithdraw,
    currentItem,
    startDate,
    endDate,
    onChangeDate,
    onDownload,
    searchValue,
    handleChangeSearchValue,
    showAll,
    handleShowAll,
  } = useEcredit();

  return (
    <Box>
      <Grid container spacing={3} sx={{ marginBottom: 3 }}>
        <Grid item xs={12} sm={8}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start date"
              endText="End date"
              value={[startDate, endDate]}
              onChange={onChangeDate}
              inputFormat="dd-MM-yyyy"
              mask="__-__-____"
              renderInput={(startProps, endProps) => (
                <>
                  <TextField fullWidth {...startProps} />
                  <Box sx={{ mx: 3 }}> to </Box>
                  <TextField fullWidth {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button variant="contained" size="large" onClick={onDownload}>
            Download csv
          </Button>
        </Grid>
      </Grid>
      <Box sx={style.user.wrapper}>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style.modal}>
            <Typography
              sx={{ mb: 2 }}
            >{`Holder: ${currentItem?.accountHolder}`}</Typography>
            <Typography
              sx={{ mb: 2 }}
            >{`Amount: ${currentItem?.amount} €`}</Typography>
            <Typography
              sx={{ mb: 2 }}
            >{`Iban: ${currentItem?.iban}`}</Typography>
            <Box sx={style.buttons}>
              <Button
                variant="outlined"
                size="large"
                onClick={handleCloseModal}
                sx={{ marginRight: 4 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={confirmWithdraw}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box sx={{ display: "flex", marginBottom: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={10} sm={10}>
              <TextField
                label="Search"
                value={searchValue}
                onChange={handleChangeSearchValue}
                size="medium"
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <FormControlLabel
                  control={
                    <Checkbox checked={showAll} onChange={handleShowAll} />
                  }
                  label="Show all"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>User email</TableCell>
                <TableCell>Account holder</TableCell>
                <TableCell>Iban</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Request date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, index) => (
                <TableRow key={item?._id}>
                  <TableCell>{item?.user?.email}</TableCell>
                  <TableCell>{item?.accountHolder}</TableCell>
                  <TableCell>{item?.iban}</TableCell>
                  <TableCell>{item?.amount} €</TableCell>
                  <TableCell>{getFormattedDate(item?.createdAt)}</TableCell>
                  <TableCell>{item?.status}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleOpenModal(item)}
                    >
                      <AttachMoney sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
});

export default Ecredit;
