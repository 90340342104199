import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Drawer,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import style from "./index.style";
import useOrders from "./index.hooks";
import { getFormattedDate } from "../../utils";
import { ORDER_PAYMENT, ORDER_STATUSES } from "../../constants/contants";
import OrderActions from "./OrderActions";

type OrdersProps = {};

const Orders = memo(({}: OrdersProps) => {
  const {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    anchorEl,
    handleFiltersModal,
    filtersModalOpen,
    filters,
    dispatchFilters,
    resetFilters,
    getSelectCountries,
    setShouldFilter,
    setPage,
  } = useOrders();

  return (
    <>
      <Drawer
        anchor="right"
        open={filtersModalOpen}
        onClose={() => {
          setShouldFilter(true);
          setPage(0);
          handleFiltersModal();
        }}
      >
        <Box sx={style.filters}>
          <FormControl fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="select-status"
              label="Status"
              value={filters?.status}
              onChange={(ev) =>
                dispatchFilters({ type: "status", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                ALL
              </MenuItem>
              {ORDER_STATUSES.map((orderStatus: any) => (
                <MenuItem key={orderStatus.value} value={orderStatus.value}>
                  {orderStatus.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="select-country"
              id="select-country"
              label="Country"
              value={filters?.country}
              onChange={(ev) =>
                dispatchFilters({ type: "country", payload: ev.target.value })
              }
            >
              <MenuItem key="all" value="">
                All
              </MenuItem>
              {getSelectCountries.map((country: any) => (
                <MenuItem key={country.countryCode} value={country.countryCode}>
                  {country.countryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="status-label">Paid</InputLabel>
            <Select
              labelId="paid-label"
              id="paid-id"
              label="Paid"
              value={filters?.paid}
              onChange={(ev) =>
                dispatchFilters({ type: "paid", payload: ev.target.value })
              }
            >
              {ORDER_PAYMENT.map((orderStatus: any) => (
                <MenuItem key={orderStatus.value} value={orderStatus.value}>
                  {orderStatus.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Customer email"
            value={filters?.email}
            onChange={(ev) =>
              dispatchFilters({ type: "email", payload: ev.target.value })
            }
            fullWidth
          />
          <TextField
            label="External ref"
            value={filters?.orderId}
            onChange={(ev) =>
              dispatchFilters({ type: "orderId", payload: ev.target.value })
            }
            fullWidth
          />
          <TextField
            label="Tracking code"
            value={filters?.trackingCode}
            onChange={(ev) =>
              dispatchFilters({
                type: "trackingCode",
                payload: ev.target.value,
              })
            }
            fullWidth
          />
          <TextField
            label="Trans Ref"
            value={filters?.transRef}
            onChange={(ev) =>
              dispatchFilters({
                type: "transRef",
                payload: ev.target.value,
              })
            }
            fullWidth
          />
          <TextField
            label="Order"
            value={filters?.order}
            onChange={(ev) =>
              dispatchFilters({
                type: "order",
                payload: ev.target.value,
              })
            }
            fullWidth
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start date"
              value={filters.startDate}
              onChange={(newDate) =>
                dispatchFilters({
                  type: "startDate",
                  payload: newDate,
                })
              }
              inputFormat="dd-MM-yyyy"
              mask="__-__-____"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            <DatePicker
              label="End date"
              value={filters.endDate}
              onChange={(newDate) =>
                dispatchFilters({
                  type: "endDate",
                  payload: newDate,
                })
              }
              inputFormat="dd-MM-yyyy"
              mask="__-__-____"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={() => {
              handleFiltersModal();
              setShouldFilter(true);
              setPage(0);
            }}
          >
            Filter
          </Button>
          <Button variant="outlined" onClick={resetFilters}>
            Reset
          </Button>
        </Box>
      </Drawer>
      <Box sx={style.user.wrapper}>
        <Box sx={style.user.action}>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => {
              setShouldFilter(false);
              handleFiltersModal();
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Box>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Bdroppy Order Id</TableCell>
                <TableCell>Rewix Order Id</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total cost</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, index) => (
                <TableRow key={item?._id}>
                  <TableCell>{item?.ids?.platform}</TableCell>
                  <TableCell>{item?.ids?.source}</TableCell>
                  <TableCell>{getFormattedDate(item?.date)}</TableCell>
                  <TableCell>{item?.user?.email}</TableCell>
                  <TableCell>
                    {
                      ORDER_STATUSES.find(
                        (status) => status.value === item?.status
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {item?.priceCost?.total} {item?.priceCost?.total ? "€" : ""}
                  </TableCell>
                  <TableCell>
                    <OrderActions
                      order={item}
                      updateOrders={getItems}
                      closeParentModal={handleCloseModal}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default Orders;
