import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useState } from "react";
import request from "redux/slices/ajax/request";
import { useDebounce } from "../../utils/useDebounce";
import { TURNKEY_STATUSES } from "../../constants/contants";

const useOrders = () => {
  const [page, setPage] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTextValue = useDebounce(searchValue, 500);
  const [modalOpen, setModalOpen] = useState<number>(-1);
  const handleCloseModal = useCallback(() => {
    setModalOpen(-1);
  }, []);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const getSelectStatusValues = () => {
    const statuses = [...TURNKEY_STATUSES];
    statuses.unshift("All");
    return statuses;
  };

  const handleOpenModal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, index) => {
      setAnchorEl(event.currentTarget);
      setModalOpen(index);
    },
    []
  );
  useEffect(() => {
    (async () => {
      let response: any = {};
      response = await request({
        path: ENDPOINTS.getPlans,
        query: {
          acceptedlocales: "it_IT",
          page: 1,
          pageSize: 1000,
          type: "S",
          category: "servizi",
          subcategory: "assistance",
        },
      });
      const data = response?.data;

      setPlans(data?.items || []);
    })();
  }, []);

  const getItems = useCallback(async () => {
    let response: any = {};
    let $or;
    if (debouncedSearchTextValue?.length >= 1) {
      $or = [
        { "recipient.email": { $regex: debouncedSearchTextValue } },
        {
          "ids.user": Number(debouncedSearchTextValue),
        },
        {
          "ids.source": Number(debouncedSearchTextValue),
        },
        {
          "ids.platform": Number(debouncedSearchTextValue),
        },
      ];
    }
    response = await request({
      path: `${ENDPOINTS.orders}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q: {
          $or,
          $and: [{ type: "SA", paid: true }],
        },
        sortings: {
          date: -1,
        },
        populate: [{ path: "user", select: "email" }],
      },
    });
    setItems(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [debouncedSearchTextValue, page, pageSize]);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    getItems().then();
  }, [getItems]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    page,
    searchValue,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    handleChangeSearchValue,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    getSelectStatusValues,
    anchorEl,
    plans,
  };
};

export default useOrders;
