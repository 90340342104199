import React, { memo, useCallback, useState } from "react";
import CustomTabs from "components/CustomTabs";
import CronJobs from "./CronJobs";
import Logs from "./Logs";

type LogsProps = {};

const CronJob = memo(({}: LogsProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [cronId, setCronId] = useState("");
  const handleChange = useCallback((_, newValue: number) => {
    setCronId("");
    setTabIndex(newValue);
  }, []);

  const handleCronId = useCallback((cronId: string) => {
    setCronId(cronId);
    setTabIndex(1);
  }, []);

  const resetCronId = useCallback(() => {
    setCronId("");
  }, []);

  return (
    <CustomTabs
      value={tabIndex}
      handleChange={handleChange}
      tabs={["Cron Jobs", "Logs"]}
      items={[
        <CronJobs setCronId={handleCronId} />,
        <Logs cronId={cronId} resetCronId={resetCronId} />,
      ]}
    />
  );
});

export default CronJob;
