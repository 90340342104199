import { createSlice } from "@reduxjs/toolkit";
import { FeedbackTypes } from "components/Feedback";
import { FeedbackState, SetFeedbackAction } from "./feedback.interfaces";
import * as selectors from "./feedback.selectors";

export const feedbackStore = createSlice({
  name: "feedback",
  initialState: {
    open: false,
    type: FeedbackTypes.Info,
    message: "",
  } as FeedbackState,
  reducers: {
    setFeedback: (state, { payload }: SetFeedbackAction) => {
      state.open = !state.open;
      state.type = payload.type ?? 0;
      state.message = payload.message || state.message;
      return state;
    },
  },
});

export { selectors };
