import { useCallback, useEffect, useState } from "react";
import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";

type useEditModalContentProps = {
  item: any;
  getItems: any;
  closeModal: () => void;
  type: string;
};

const useEditModalContent = ({
  item,
  getItems,
  closeModal,
  type,
}: useEditModalContentProps) => {
  const [editingItem, setEditingItem] = useState<any>();

  useEffect(() => {
    setEditingItem(item);
  }, [item]);
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempItem = { ...editingItem };
      tempItem[key] = value;
      setEditingItem(tempItem);
    },
    [editingItem]
  );

  const updateItem = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${
        type === "category"
          ? ENDPOINTS.getCategories
          : ENDPOINTS.getSubCategories
      }/${editingItem?._id}`,
      method: HttpMethod.PUT,
      body: {
        active: editingItem?.active,
      },
    });
    if (response.status === 200) {
      // Apro feedback success
      closeModal();
    }
    await getItems();
  }, [closeModal, editingItem?._id, editingItem?.active, getItems, type]);
  return {
    handleChangeValue,
    editingItem,
    updateItem,
  };
};

export default useEditModalContent;
