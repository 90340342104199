import globalStyle from "index.style";

const useStyles = {
  user: {
    wrapper: {
      background: "white",
      padding: 2,
      borderRadius: 2,
      boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 700,
      bgcolor: "background.paper",
      boxShadow: 12,
      p: 4,
      button: {
        position: "absolute",
        top: 5,
        right: 5,
      },
    },
    action: {
      display: "flex",
      justifyContent: "flex-end",
      button: {
        fontSize: 12,
        textTransform: "inherit",
      },
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    p: 4,
    flexWrap: "wrap",
    overflowX: "scroll",
    maxHeight: "80vh",
    closeButton: {
      position: "absolute",
      top: 5,
      right: 5,
    },
  },
  filters: {
    width: 300,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  buttons: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
} as const;

export default { ...globalStyle, ...useStyles };
