import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

const useLogin = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    []
  );

  const onChangePassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  const onSubmit = useCallback(() => {
    dispatch({
      type: "LOGIN_SAGA",
      payload: { email, password },
    });
  }, [dispatch, password, email]);

  return { email, password, onChangeEmail, onChangePassword, onSubmit };
};

export default useLogin;
