import React, { memo } from "react";
import { Box, Button } from "@mui/material";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { getValueFromDeepKey } from "utils";
import TextField from "@mui/material/TextField";
import { ArrowForward, Edit } from "@mui/icons-material";
import useTranslations from "./index.hooks";
import style from "./index.style";

type TranslationsProps = {};

const Translations = memo(({}: TranslationsProps) => {
  const {
    defaultTranslations,
    translationTree,
    notChangedTranslations,
    setTranslationTree,
    langsTranslations,
    LANGUAGES,
    isLoading,
    handleSaveTranslation,
    values,
    handleChangeValue,
    filteredTranslations,
    setTranslationsFilter,
    translationsFilter,
  } = useTranslations();

  return (
    <Box>
      <TextField
        fullWidth
        label="Cerca traduzione"
        value={translationsFilter}
        onChange={(ev) => setTranslationsFilter(ev.target.value)}
      />
      {!isLoading && (
        <ScrollMenu>
          {translationTree.map((key, index) => {
            const slicedTranslationTree = translationTree.slice(0, index + 1);
            const mappedTranslations = getValueFromDeepKey(
              defaultTranslations,
              slicedTranslationTree.join(".")
            );
            return (
              <Box key={key}>
                {typeof mappedTranslations === "object" ? (
                  Object.keys(mappedTranslations)
                    .filter((key) =>
                      filteredTranslations.includes(
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        [...translationTree?.slice(1, index + 1), key]?.join(
                          "."
                        )
                      )
                    )
                    .map((value) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                      <Box
                        sx={[
                          style.editValue,
                          translationTree[index + 1] === value && style.active,
                          notChangedTranslations?.includes(
                            [
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              ...translationTree?.slice(1, index + 1),
                              value,
                            ]?.join(".")
                          )
                            ? style.translationMissing
                            : {},
                        ]}
                        key={value}
                        title={key}
                        onClick={() => {
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                          setTranslationTree([...slicedTranslationTree, value]);
                        }}
                      >
                        {value}
                        {typeof mappedTranslations[value] === "object" ? (
                          <ArrowForward />
                        ) : (
                          <Edit />
                        )}
                      </Box>
                    ))
                ) : (
                  <Box sx={style.editForm}>
                    <Box>
                      <p>Aggiungi traduzioni</p>
                      {Object.keys(LANGUAGES)?.map((key, index) => {
                        const item = LANGUAGES[key];
                        const langSlicedTranslationTree = [
                          ...slicedTranslationTree,
                        ];
                        langSlicedTranslationTree[0] = key;
                        let initialLabelValue = getValueFromDeepKey(
                          langsTranslations,
                          langSlicedTranslationTree.join(".")
                        );
                        if (typeof initialLabelValue === "object") {
                          initialLabelValue = getValueFromDeepKey(
                            defaultTranslations,
                            slicedTranslationTree.join(".")
                          );
                        }
                        return (
                          <Box sx={style.translationBox} key={key}>
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                defaultValue={initialLabelValue}
                                placeholder="Inserisci traduzione"
                                value={values?.[key]}
                                onChange={(ev) =>
                                  handleChangeValue(ev.target.value, key)
                                }
                                multiline
                                maxRows={10}
                              />
                            </Box>
                            <Box sx={style.flag}>
                              <img
                                src={`/images/translations${item?.src}`}
                                alt="flag"
                              />
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                    <Button variant="outlined" onClick={handleSaveTranslation}>
                      Salva
                    </Button>
                  </Box>
                )}
              </Box>
            );
          })}
        </ScrollMenu>
      )}
    </Box>
  );
});

export default Translations;
