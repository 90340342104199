import React, { memo } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import useBills from "./index.hooks";

type BillsProps = {};

const Bills = memo(({}: BillsProps) => {
  const {
    invoiceTypes,
    invoiceClasses,
    startDate,
    endDate,
    onChangeDate,
    handleChangeInvoiceType,
    handleChangeInvoiceClass,
    invoiceType,
    invoiceClass,
    onSubmit,
    handleCreditNoteCheck,
    creditNote,
  } = useBills();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="Start date"
            endText="End date"
            value={[startDate, endDate]}
            onChange={onChangeDate}
            inputFormat="dd-MM-yyyy"
            mask="__-__-____"
            renderInput={(startProps, endProps) => (
              <>
                <TextField fullWidth {...startProps} />
                <TextField fullWidth {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel id="invoiceType-label">Tipo</InputLabel>
          <Select
            labelId="select-invoiceType"
            id="select-invoiceType"
            label="Tipo"
            value={invoiceType}
            onChange={handleChangeInvoiceType}
          >
            {invoiceTypes.map((invoice: any) => (
              <MenuItem key={invoice.value} value={invoice.value}>
                {invoice.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel id="invoiceClass-label">Classe</InputLabel>
          <Select
            labelId="select-invoiceClass"
            id="select-invoiceClass"
            label="Classe"
            value={invoiceClass}
            onChange={handleChangeInvoiceClass}
          >
            {invoiceClasses.map((invoice: any) => (
              <MenuItem key={invoice.value} value={invoice.value}>
                {invoice.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {invoiceClass === "F" && (
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox checked={creditNote} onChange={handleCreditNoteCheck} />
            }
            label="Credit note"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Button variant="contained" size="large" onClick={onSubmit}>
          Download
        </Button>
      </Grid>
    </Grid>
  );
});

export default Bills;
