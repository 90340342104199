import React, { memo, useCallback, useState } from "react";
import CustomTabs from "../../components/CustomTabs";
import Banners from "./Banners";
import BannersGallery from "./BannersGallery";
import TimerPlans from "./TimerPlans";
import TimedProductsBanner from "./TimedProductsBanner";
import ProductsCarousel from "./ProductsCarousel";
import ProductsCMS from './ProductsCMS'

const Settings = memo(() => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
    },
    []
  );

  return (
    <div>
      <CustomTabs
        value={tabIndex}
        handleChange={handleChange}
        tabs={[
          "Banners",
          "Banners Gallery",
          /* "Banners Video", */
          /* "URL Links", */
          "Timer Plans",
          "Banner Catalogo",
          "Blocchi Homepage",
          "Product CMS",
          'Privacy policy'
        ]}
        items={[
          <Banners />,
          <BannersGallery />,
          /* <BannersVideo />, */
          /* <UrlLinks />, */
          <TimerPlans />,
          <TimedProductsBanner />,
          <ProductsCarousel />,
          <ProductsCMS />,
        ]}
      />
    </div>
  );
});

export default Settings;
