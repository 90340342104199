import { useDispatch, useSelector } from "react-redux";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useDebounce } from "utils/useDebounce";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "../../constants/endpoints";
import { actions } from "../../redux/slices";
import { FeedbackTypes } from "../../components/Feedback";
import { getCountries } from "../../redux/slices/platform-data/platform-data.selectors";

const initialState = {
  status: "",
  country: "",
  extRef: "",
  trackingCode: "",
  orderId: "",
  transRef: "",
};

const pendingStatuses = [0];
const processingStatuses = [1, 2];
const completedStatuses = [3, 4, 7, 9];
const notReceivedStatutes = [5];

const useReturnsManagement = () => {
  const dispatch = useDispatch();
  const [searchTextValue, setSearchTextValue] = useState("");
  const debouncedSearchTextValue = useDebounce(searchTextValue, 500);
  const [page, setPage] = useState(0);
  const [returns, setReturns] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const handleOpenModal = useCallback((item?: any) => {
    setModalOpen((prevState) => !prevState);
    setSelectedItem(item);
  }, []);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [reasons, setReasons] = useState<any>([]);
  const [imageModalOpen, setImageModalOpen] = useState(-1);
  const [showAll, setShowAll] = useState(true);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [shouldFilter, setShouldFilter] = useState(true);
  const countries = useSelector(getCountries);
  const filtersReducer = (state: any, action: any) => {
    switch (action.type) {
      case "status":
        return { ...state, status: action.payload };
      case "country":
        return { ...state, country: action.payload };
      case "orderId":
        return { ...state, orderId: action.payload };
      case "trackingCode":
        return { ...state, trackingCode: action.payload };
      case "transRef":
        return { ...state, transRef: action.payload };
      case "reset":
        return initialState;
      default:
        return { [action.type]: action.payload };
    }
  };
  const [filters, dispatchFilters] = useReducer(filtersReducer, initialState);

  const handleFiltersModal = useCallback(() => {
    setFiltersModalOpen((prev) => !prev);
  }, []);

  const resetFilters = useCallback(() => {
    dispatchFilters({ type: "reset" });
  }, []);

  const handleImageModalOpen = useCallback((index: number) => {
    setImageModalOpen(index);
  }, []);

  const handleShowAll = useCallback(() => {
    setShowAll((prevState) => !prevState);
    setPage(0);
  }, []);

  const getReasonTitle = (reasonCode: string) =>
    reasons.find((reason: any) => reason.code === reasonCode).title.it_IT;
  const downloadInvoice: any = useCallback(async (invoice: any) => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.downloadInvoice,
      method: HttpMethod.GET,
      additionalHeaders: {
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      responseType: "blob",
      pathParams: {
        invoiceId: invoice._id,
      },
    });
    const filename =
      response?.headers?.["content-disposition"]
        ?.split("filename=")?.[1]
        .replace(/"/g, "") || "Bill.pdf";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }, []);
  useEffect(() => {
    (async () => {
      const response: any = await request({
        path: ENDPOINTS.returnReasons,
      });
      setReasons(response.data);
    })();
  }, []);

  const getReturns = useCallback(async () => {
    let q: any = {};

    if (!showAll) {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 2);
      q = {
        "items.status": { $in: [3, 9] },
        "items.creditNote._id": { $exists: false },
        requestDate: {
          $gt: startDate.toISOString(),
        },
      };
    }
    if (filters?.country) {
      q["recipient.address.countrycode"] = filters?.country;
    }

    if (filters?.orderId) {
      q.orderId = filters?.orderId;
    }

    if (filters?.trackingCode) {
      q["items.returnCode"] = filters?.trackingCode;
    }

    if (filters?.transRef) {
      q.transRef = filters?.transRef;
    }

    if (filters.status) {
      switch (filters.status) {
        case "pending":
          q["items.status"] = { $in: pendingStatuses };
          break;
        case "processing":
          q["items.status"] = { $in: processingStatuses };
          break;
        case "received":
          q["items.status"] = { $in: completedStatuses };
          break;
        case "not_received":
          q["items.status"] = { $in: notReceivedStatutes };
          break;
        default:
          break;
      }
    }
    const text = debouncedSearchTextValue;
    if (text?.length >= 3) {
      const $or: any = [
        { "recipient.email": { $regex: text } },
        { "items.returnCode": { $regex: text } },
      ];

      try {
        const code = parseInt(text, 10);
        if (code >= 0) {
          $or.push({
            "items.modelRefId": code,
          });
        }
      } catch (err) {
        console.log("err", err);
      }

      q = {
        ...q,
        ...{
          $or,
        },
      };
    }
    let response: any = {};
    response = await request({
      path: ENDPOINTS.refunds,
      method: HttpMethod.GET,
      query: {
        pageNumber: page + 1 || 1,
        pageSize,
        q,
        sortings: { requestDate: -1 },
        populate: [
          {
            path: "order",
            select: [
              "_id",
              "recipient.email",
              "code",
              "shippingCost",
              "vatAmount",
              "items",
              "ids.source",
              "ids.platform",
              "ids.external",
              "father",
            ],
          },
          {
            path: "items.product",
            select: [
              "_id",
              "brand",
              "code",
              "bestTaxable",
              "pictures",
              "models.refId",
              "models.size",
            ],
          },
          { path: "items.pic", select: ["_id", "url"] },
          { path: "user", select: ["email"] },
        ],
      },
    });
    setReturns(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [
    debouncedSearchTextValue,
    filters?.country,
    filters?.orderId,
    filters.status,
    filters?.trackingCode,
    filters?.transRef,
    page,
    pageSize,
    showAll,
  ]);

  useEffect(() => {
    if (shouldFilter) {
      getReturns().then();
    }
  }, [getReturns, shouldFilter]);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
    setSelectedRow(-1);
  }, []);

  const onConfirmReturn = useCallback(
    async (
      values,
      selectedItems,
      shippingEnabled,
      extraLineEnabled,
      manualEcreditEnabled
    ) => {
      const shippingAmount = shippingEnabled
        ? values.shippingCost.toString().replace(",", ".")
        : 0;
      const selectedIds = selectedItem?.items
        .filter((item: any, index: number) => !!selectedItems[index])
        .map((item: any) => item._id);
      const extra = [];
      if (extraLineEnabled) {
        extra.push({
          amount: values?.extraLineAmount.replace(",", "."),
          note: values.extraLineNote,
        });
      }
      const creditNote = {
        returnOrderId: selectedItem?._id,
        extra,
        note: values?.note || "",
        shippingAmount,
        selectedIds,
        ecreditSelection: manualEcreditEnabled ? "manual" : "auto",
        manualEcreditValue: manualEcreditEnabled
          ? values.manualEcreditValue.replace(",", ".")
          : 0,
      };

      let response: any = {};
      response = await request({
        path: ENDPOINTS.completeRefunds,
        method: HttpMethod.POST,
        body: creditNote,
        disableSuccessFeedback: true,
      });
      if (response.status === 200) {
        dispatch(
          actions.setFeedback({
            type: FeedbackTypes.Success,
            message:
              response?.data?.description || "Operazione eseguita con successo",
          })
        );
      }
      console.log("response", response);
      await getReturns();
      handleOpenModal(null);
    },
    [
      dispatch,
      getReturns,
      handleOpenModal,
      selectedItem?._id,
      selectedItem?.items,
    ]
  );

  const openImage = useCallback((url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  }, []);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setSearchTextValue(event.target.value);
  };

  const getSelectCountries = useMemo(
    () =>
      countries?.map((country: any) => ({
        countryName: country?.country,
        countryCode: country?.countrycode,
      })),
    [countries]
  );

  return {
    returns,
    totalNumberOfElements,
    pageSize,
    handlePageSizeChange,
    page,
    handleChangePage,
    modalOpen,
    handleOpenModal,
    selectedItem,
    onConfirmReturn,
    setSelectedRow,
    selectedRow,
    getReasonTitle,
    imageModalOpen,
    handleImageModalOpen,
    openImage,
    handleChangeSearchValue,
    searchTextValue,
    showAll,
    handleShowAll,
    handleFiltersModal,
    resetFilters,
    setShouldFilter,
    filters,
    filtersModalOpen,
    getReturns,
    dispatchFilters,
    getSelectCountries,
    downloadInvoice,
  };
};

export default useReturnsManagement;
