import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

type UsePlansDescriptionProps = {
  model: any;
  language: any;
  setDescriptionEditorState: any;
  setSecondDescriptionEditorState: any;
  setLanguage: any;
};

const usePlansDescription = ({
  model,
  language,
  setDescriptionEditorState,
  setSecondDescriptionEditorState,
  setLanguage,
}: UsePlansDescriptionProps) => {
  const [tab, setTab] = useState(0);
  const createMarkup = useMemo(
    () => ({ __html: model?.descriptions?.[language] || "" }),
    [language, model?.descriptions]
  );

  const TABS = useMemo(() => ["it_IT", "en_US", "es_ES", "fr_FR"], []);

  useEffect(() => {
    setDescriptionEditorState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          // @ts-ignore
          htmlToDraft(model?.descriptions?.[language] || "")
        )
      )
    );
    setSecondDescriptionEditorState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          // @ts-ignore
          htmlToDraft(model?.secondDescriptions?.[language] || "")
        )
      )
    );
  }, [
    language,
    model?.descriptions,
    model?.secondDescriptions,
    setDescriptionEditorState,
    setSecondDescriptionEditorState,
  ]);
  const updateTextDescription = setDescriptionEditorState;
  const updateTextSecondDescription = setSecondDescriptionEditorState;

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setLanguage(TABS[newValue]);
      setTab(newValue);
    },
    [TABS, setLanguage]
  );

  return {
    createMarkup,
    tab,
    handleChange,
    TABS,
    updateTextDescription,
    updateTextSecondDescription,
  };
};

export default usePlansDescription;
