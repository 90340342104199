import React, { memo } from "react";
import { Button, Grid } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Box } from "@mui/system";
import useBillsExpandedInvoice from "./index.hooks";

type BillsExpandedInvoiceProps = {};

const BillsExpandedInvoice = memo(({}: BillsExpandedInvoiceProps) => {
  const {
    startDate,
    endDate,
    onChangeDate,
    onSubmit,
    handleCreditNoteCheck,
    creditNote,
  } = useBillsExpandedInvoice();

  return (
    <Grid container spacing={3}>
      <Grid item xs={8} sm={8}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="Start date"
            endText="End date"
            value={[startDate, endDate]}
            onChange={onChangeDate}
            inputFormat="dd-MM-yyyy"
            mask="__-__-____"
            renderInput={(startProps, endProps) => (
              <>
                <TextField fullWidth {...startProps} />
                <Box sx={{ mx: 3 }}> to </Box>
                <TextField fullWidth {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </Grid>
      {/* <Grid item xs={12} sm={6}> */}
      {/*  <FormControlLabel */}
      {/*    control={ */}
      {/*      <Checkbox checked={creditNote} onChange={handleCreditNoteCheck} /> */}
      {/*    } */}
      {/*    label="Credit note" */}
      {/*  /> */}
      {/* </Grid> */}
      <Grid item xs={12}>
        <Button variant="contained" size="large" onClick={onSubmit}>
          Download
        </Button>
      </Grid>
    </Grid>
  );
});

export default BillsExpandedInvoice;
