import React, { memo } from "react";
import {
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Paper,
} from "@mui/material";
import CustomTabs from "components/CustomTabs";
import usePlansPrice from "./index.hooks";

type PriceObj = {
  suggestedPrice: number;
  streetPrice: number;
};

type PlansPriceProps = {
  plan: any;
  onSubmit: (
    { suggestedPrice, streetPrice }: PriceObj,
    modelType: string
  ) => void;
};

const PlansPrice = memo(({ plan, onSubmit }: PlansPriceProps) => {
  const {
    modelTypeIndex,
    handleChange,
    getModelTabs,
    priceTab,
    handleChangePriceTab,
    TABS,
    suggestedPrice,
    setSuggestedPrice,
    promoPriceActive,
    onChangePromoPriceActive,
    streetPrice,
    setStreetPrice,
    onClickSave,
  } = usePlansPrice({ plan, onSubmit });
  return (
    <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
      <div>
        <div>
          <div>
            <CustomTabs
              value={modelTypeIndex}
              handleChange={handleChange}
              tabs={getModelTabs}
              items={plan.models.map((model: any, index: number) => (
                <Box>
                  <CustomTabs
                    value={priceTab}
                    handleChange={handleChangePriceTab}
                    tabs={TABS}
                    items={[
                      <TextField
                        fullWidth
                        label="Suggested Price"
                        value={suggestedPrice}
                        onChange={(ev) => setSuggestedPrice(ev.target.value)}
                      />,
                      <>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={promoPriceActive[index]}
                              onChange={() => onChangePromoPriceActive(index)}
                            />
                          }
                          label="promoPriceActive"
                        />
                        {promoPriceActive[index] && (
                          <TextField
                            fullWidth
                            label="StreetPrice"
                            value={streetPrice}
                            onChange={(ev) => setStreetPrice(ev.target.value)}
                          />
                        )}
                      </>,
                    ]}
                  />
                  <Button
                    variant="contained"
                    size="large"
                    onClick={onClickSave}
                  >
                    Save
                  </Button>
                </Box>
              ))}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
});

export default PlansPrice;
