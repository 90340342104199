import ENDPOINTS from "constants/endpoints";
import { useCallback, useEffect, useState } from "react";
import request from "redux/slices/ajax/request";

type UseCategoriesProps = {
  type: string;
};

const useCategories = ({ type }: UseCategoriesProps) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [canali, setCanali] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingCategory, setEditingCategory] = useState<any>();

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingCategory(null);
  }, []);

  const handleOpenModal = useCallback((category?: any) => {
    setEditingCategory(category);
    setModalOpen((prevState) => !prevState);
  }, []);

  const getCategories = useCallback(async () => {
    let response: any = {};
     if (type === "category") {
      response = await request({
        path: `${ENDPOINTS.getCategories}`,
      });
    } else {
      response = await request({
        path: `${ENDPOINTS.getSubCategories}`,
      });
    }
    const orderedCategories = response?.data?.sort((a: any, b: any) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 0;
    });
    setCategories(orderedCategories);
  }, [type]);

  useEffect(() => {
    getCategories().then();
  }, [getCategories]);

  return {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    categories,
    editingCategory,
    getCategories,
  };
};

export default useCategories;
