import { useCallback, useEffect, useState } from "react";

import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";

type useEditReferralProps = {
  user: any;
  getUsers: () => void;
};

const useEditReferral = ({ user, getUsers }: useEditReferralProps) => {
  const [referralSettings, setReferralSettings] = useState<any>();

  useEffect(() => {
    setReferralSettings(user?.referralSettings);
  }, [user]);
  const handleChangeSelectValue = useCallback(
    (value: string, key: string) => {
      const tempSettings = { ...referralSettings };
      tempSettings[key] = value;
      setReferralSettings(tempSettings);
    },
    [referralSettings]
  );

  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempSettings = { ...referralSettings };
      tempSettings[key] = value;
      setReferralSettings(tempSettings);
    },
    [referralSettings]
  );
  const updateUser = useCallback(async () => {
    await request({
      path: `${ENDPOINTS.user}/${user?._id}/referral_settings`,
      method: HttpMethod.PUT,
      body: referralSettings,
    });
    await getUsers();
  }, [getUsers, referralSettings, user?._id]);

  return {
    handleChangeValue,
    updateUser,
    referralSettings,
    handleChangeSelectValue,
  };
};

export default useEditReferral;
