import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import request from "redux/slices/ajax/request";

const initialState = {
  saveResponse: "",
  name: "",
  description: "",
  url: "",
  interval: "",
  status: "",
  email: "",
  startDate: null,
  endDate: null,
};

const useCronJobs = () => {
  const [page, setPage] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState<any>();
  const [newCron, setNewCron] = useState(false);
  const [shouldFilter, setShouldFilter] = useState(true);
  const filtersReducer = (state: any, action: any) => {
    switch (action.type) {
      case "saveResponse":
        return { ...state, saveResponse: action.payload };
      case "name":
        return { ...state, name: action.payload };
      case "description":
        return { ...state, description: action.payload };
      case "email":
        return { ...state, email: action.payload };
      case "url":
        return { ...state, url: action.payload };
      case "interval":
        return { ...state, interval: action.payload };
      case "status":
        return { ...state, status: action.payload };
      case "startDate":
        return { ...state, startDate: action.payload[0] };
      case "endDate":
        return { ...state, endDate: action.payload[1] };
      case "reset":
        return initialState;
      default:
        return { [action.type]: action.payload };
    }
  };

  const [filters, dispatchFilters] = useReducer(filtersReducer, initialState);

  const handleOpenModal = useCallback((isEdit, item?: any) => {
    if (isEdit) {
      setNewCron(true);
      setEditingItem({
        saveResponse: false,
        name: "",
        description: "",
        url: "",
        interval: "",
        status: 1,
      });
    } else {
      setEditingItem(item);
      setNewCron(false);
    }
    setModalOpen((prevState) => !prevState);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingItem(null);
    setNewCron(false);
  }, []);

  const handleFiltersModal = useCallback(() => {
    setFiltersModalOpen((prev) => !prev);
  }, []);

  const getItems = useCallback(async () => {
    let response: any = {};
    const q: any = {};
    if (filters.email) {
      q.email = { $regex: filters.email };
    }
    if (filters.saveResponse) {
      q.saveResponse = filters.saveResponse;
    }
    if (filters.name) {
      q.name = { $regex: filters.name, $options: "i" };
    }
    if (filters.description) {
      q.description = { $regex: filters.description };
    }
    if (filters.url) {
      q.url = { $regex: filters.url };
    }
    if (filters.interval) {
      q.interval = filters.interval;
    }
    if (filters.status) {
      q.status = filters.status;
    }
    const date: any = {};

    if (filters?.startDate) {
      date.$gte = filters.startDate.toISOString();
    }

    if (filters?.endDate) {
      date.$lte = filters.endDate.toISOString();
    }

    if (date.$lte && date.$gte) {
      q.date = date;
    }
    response = await request({
      path: `${ENDPOINTS.userCron}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q,
        sortings: {
          createdAt: -1,
        },
        populate: [{ path: "user", select: "email" }],
      },
    });
    setItems(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [
    filters?.description,
    filters?.email,
    filters?.endDate,
    filters?.interval,
    filters?.name,
    filters?.saveResponse,
    filters?.startDate,
    filters?.status,
    filters?.url,
    page,
    pageSize,
  ]);

  useEffect(() => {
    if (shouldFilter) {
      getItems().then();
    }
  }, [getItems, shouldFilter]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetFilters = useCallback(() => {
    dispatchFilters({ type: "reset" });
  }, []);

  return {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    handleFiltersModal,
    filtersModalOpen,
    filters,
    dispatchFilters,
    resetFilters,
    editingItem,
    newCron,
    setNewCron,
    setShouldFilter,
  };
};

export default useCronJobs;
