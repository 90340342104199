import ENDPOINTS from "constants/endpoints";
import { useCallback, useEffect, useState } from "react";
import request from "redux/slices/ajax/request";

const useBrands = () => {
  const [brands, setBrands] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingBrand, setEditingBrand] = useState<any>();

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingBrand(null);
  }, []);

  const handleOpenModal = useCallback((brand?: any) => {
    setEditingBrand(brand);
    setModalOpen((prevState) => !prevState);
  }, []);

  const getBrands = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.brand}`,
    });
    const orderedBrands = response?.data?.sort((a: any, b: any) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    setBrands(orderedBrands);
  }, []);

  useEffect(() => {
    getBrands().then();
  }, [getBrands]);

  return {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    brands,
    editingBrand,
    getBrands,
  };
};

export default useBrands;
