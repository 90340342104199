import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useState } from "react";
import request from "redux/slices/ajax/request";
import { useDebounce } from "../../utils/useDebounce";

const useTurnkey = () => {
  const [page, setPage] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTextValue = useDebounce(searchValue, 500);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<any>();
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingItem(null);
  }, []);

  const handleOpenModal = useCallback((category?: any) => {
    setEditingItem(category);
    setModalOpen((prevState) => !prevState);
  }, []);

  const getItems = useCallback(async () => {
    let response: any = {};
    let $or;
    if (debouncedSearchTextValue?.length >= 1) {
      $or = {
        email: { $regex: `.*${debouncedSearchTextValue}.*` },
      };
    }
    response = await request({
      path: `${ENDPOINTS.address}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q: {
          ...$or,
        },
        p: {},
        sortings: {
          createdAt: -1,
        },
        populate: [{ path: "user", select: ["email"] }],
      },
    });
    setItems(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [debouncedSearchTextValue, page, pageSize]);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    getItems().then();
  }, [getItems]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    page,
    searchValue,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    handleChangeSearchValue,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    editingItem,
  };
};

export default useTurnkey;
