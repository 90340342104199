export const getBrands = (state: any) => state?.platformData?.brands;
export const getCountries = (state: any) => state?.platformData?.countries;
export const getBanners = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "banners"
  )?.[0]?.body?.banners;
export const getBannersId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "banners"
  )?.[0]?._id;
export const getBannersGallery = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "BannersGallery"
  )?.[0]?.body?.BannersGallery || {};
export const getBannersGalleryId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "BannersGallery"
  )?.[0]?._id;
export const getBannersVideo = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "BannersVideo"
  )?.[0]?.body?.BannersVideo || {};
export const getBannersVideoId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "BannersVideo"
  )?.[0]?._id;
export const getLinks = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "Links"
  )?.[0]?.body || {};
export const getLinksId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "Links"
  )?.[0]?._id;
export const getPlansSetting = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "PlansSetting"
  )?.[0]?.body || {};
export const getPlansSettingId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "PlansSetting"
  )?.[0]?._id;
export const getTimedProductsBannerBody = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "TimedProductsBanner"
  )?.[0]?.body?.banners;
export const getTimedProductsBannerTimer = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "TimedProductsBanner"
  )?.[0]?.body?.timer;
export const getTimedProductsBannerId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "TimedProductsBanner"
  )?.[0]?._id;
export const getProductsCarouselBody = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "ProductsCarousel"
  )?.[0]?.body?.banners;
export const getProductsCarouselId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "ProductsCarousel"
  )?.[0]?._id;
export const getProductsCms = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "ProductsCMS"
  )?.[0]?.body?.products;
export const getProductsCmsId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "ProductsCMS"
  )?.[0]?._id;
export const getSidebarMain = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "Sidebar"
  )?.[0]?.body?.main;
export const getSidebarOther = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "Sidebar"
  )?.[0]?.body?.other;
export const getSidebarId = (state: any) =>
  state?.platformData?.settings?.filter(
    (setting: any) => setting?.type === "Sidebar"
  )?.[0]?._id;
