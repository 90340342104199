import React, { memo } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Box } from "@mui/system";
import useMerchantInvoice from "./index.hooks";
import { MERCHANT_IDS } from "../../../constants/contants";

type MerchantInvoiceProps = {};

const MerchantInvoice = memo(({}: MerchantInvoiceProps) => {
  const {
    startDate,
    endDate,
    onChangeDate,
    onSubmit,
    merchantId,
    setMerchantId,
    handleCreditNoteCheck,
    creditNote,
  } = useMerchantInvoice();

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} sm={3}>
        <FormControl fullWidth>
          <InputLabel id="invoiceType-label">Tipo</InputLabel>
          <Select
            labelId="select-invoiceType"
            id="select-invoiceType"
            label="Tipo"
            value={merchantId}
            onChange={(ev) => setMerchantId(Number(ev.target.value))}
          >
            {MERCHANT_IDS.map((id: any) => (
              <MenuItem key={id.value} value={id.value}>
                {id.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8} sm={8}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="Start date"
            endText="End date"
            value={[startDate, endDate]}
            onChange={onChangeDate}
            inputFormat="dd-MM-yyyy"
            mask="__-__-____"
            renderInput={(startProps, endProps) => (
              <>
                <TextField fullWidth {...startProps} />
                <Box sx={{ mx: 3 }}> to </Box>
                <TextField fullWidth {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Checkbox checked={creditNote} onChange={handleCreditNoteCheck} />
          }
          label="Credit note"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" size="large" onClick={onSubmit}>
          Download
        </Button>
      </Grid>
    </Grid>
  );
});

export default MerchantInvoice;
