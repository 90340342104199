import globalStyle from "index.style";

const useStyles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    p: 4,
    flexWrap: "wrap",
    overflowX: "scroll",
    height: "80vh",
    closeButton: {
      position: "absolute",
      top: 5,
      right: 5,
    },
  },
} as const;

export default { ...globalStyle, ...useStyles };
