import React, { useCallback, useState } from "react";

const useTagsManagement = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
    },
    []
  );
  return { tabIndex, handleChange };
};

export default useTagsManagement;
