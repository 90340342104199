import { useCallback, useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { LANGUAGES } from "../../../constants/contants";
import request, { HttpMethod } from "../../../redux/slices/ajax/request";
import ENDPOINTS from "../../../constants/endpoints";

type UsePlansPriceProps = {
  plan: any;
};

const usePlansPrice = ({ plan }: UsePlansPriceProps) => {
  const model = plan?.models?.[0] || {};
  const [language, setLanguage] = useState("it_IT");
  const [descriptionEditorState, setDescriptionEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        // @ts-ignore
        htmlToDraft(model?.descriptions?.[language] || "")
      )
    )
  );
  const [secondDescriptionEditorState, setSecondDescriptionEditorState] =
    useState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          // @ts-ignore
          htmlToDraft(model?.secondDescriptions?.[language] || "")
        )
      )
    );
  const [thirdDescriptionEditorState, setThirdDescriptionEditorState] =
    useState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          // @ts-ignore
          htmlToDraft(model?.thirdDescriptions?.[language] || "")
        )
      )
    );

  const onSubmit = useCallback(
    async (values, modelType) => {
      // main Description
      const data = convertToRaw(descriptionEditorState.getCurrentContent());
      const myHtml = draftToHtml(data);
      const descriptions: { [index: string]: any } = {};
      for (const l of Object.keys(LANGUAGES)) {
        descriptions[l] = model?.descriptions?.[l] || "";
      }
      descriptions[language] = myHtml;
      // second Description
      const secondData = convertToRaw(
        secondDescriptionEditorState.getCurrentContent()
      );
      const secondMyHtml = draftToHtml(secondData);
      const secondDescriptions: { [index: string]: any } = {};
      for (const l of Object.keys(LANGUAGES)) {
        secondDescriptions[l] = model?.secondDescriptions?.[l] || "";
      }
      secondDescriptions[language] = secondMyHtml;
      // third Description
      const thirdData = convertToRaw(
        thirdDescriptionEditorState.getCurrentContent()
      );
      const thirdMyHtml = draftToHtml(thirdData);
      const thirdDescriptions: { [index: string]: any } = {};
      for (const l of Object.keys(LANGUAGES)) {
        thirdDescriptions[l] = model?.thirdDescriptions?.[l] || "";
      }
      thirdDescriptions[language] = thirdMyHtml;
      let response: any = {};
      response = await request({
        path: `${ENDPOINTS.products}/${plan?._id}`,
        method: HttpMethod.PUT,
        body: {
          models: [
            ...plan.models.map((model: any) => {
              if (values && modelType) {
                if (model.model === modelType) {
                  model.suggestedPrice = values.suggestedPrice;
                  model.streetPrice = values.streetPrice;
                }
              }
              model.merchantId = "0";
              model.descriptions = descriptions;
              model.secondDescriptions = secondDescriptions;
              model.thirdDescriptions = thirdDescriptions;
              return model;
            }),
          ],
        },
      });
    },
    [
      descriptionEditorState,
      language,
      secondDescriptionEditorState,
      thirdDescriptionEditorState,
      plan?._id,
      plan.models,
      model?.descriptions,
      model?.secondDescriptions,
      model?.thirdDescriptions,
    ]
  );
  return {
    model,
    onSubmit,
    descriptionEditorState,
    setDescriptionEditorState,
    secondDescriptionEditorState,
    setSecondDescriptionEditorState,
    thirdDescriptionEditorState,
    setThirdDescriptionEditorState,
    language,
    setLanguage,
  };
};

export default usePlansPrice;
