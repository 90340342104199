import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import useEditModalContent from "./index.hooks";
import style from "./index.style";
import { LOCALES } from "../../../constants/contants";
import { CURRENCY_CONVERSION } from "../../../constants/currency_conversion";

type EditModalContentProps = {
  item: any;
  closeModal: () => void;
  getItems: any;
};

const EditModalContent = memo(
  ({ item, closeModal, getItems }: EditModalContentProps) => {
    const { handleChangeValue, editingItem, updateItem } = useEditModalContent({
      item,
      getItems,
      closeModal,
    });

    return (
      <div>
        {editingItem && (
          <Box sx={style.modal}>
            <Grid container spacing={3}>
              <>
                <IconButton sx={style.modal.closeButton} onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12} sm={12}>
                  <Typography>General settings</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography>List name</Typography>
                  <Typography>{editingItem?.name}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography>Currency</Typography>
                  <Typography>
                    {CURRENCY_CONVERSION?.[editingItem?.currency]?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography>Language</Typography>
                  <Typography>{LOCALES[editingItem?.lang]}</Typography>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
      </div>
    );
  }
);

export default EditModalContent;
