import React, { memo } from "react";
import useTagsManagement from "./index.hooks";
import CustomTabs from "../../components/CustomTabs";
import Brands from "./Brands";
import Categories from "./Categories";

type TagsManagementProps = {};

const TagsManagement = memo(({}: TagsManagementProps) => {
  const { tabIndex, handleChange } = useTagsManagement();

  return (
    <CustomTabs
      value={tabIndex}
      handleChange={handleChange}
      tabs={["Brands", "Categories", "Subcategories"]}
      items={[
        <Brands />,
        <Categories type="category" />,
        <Categories type="subcategory" />,
      ]}
    />
  );
});

export default TagsManagement;
