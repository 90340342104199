import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import style from "./index.style";
import useOrders from "./index.hooks";
import { getFormattedDate } from "../../utils";
import { ORDER_STATUSES } from "../../constants/contants";
import OrderActions from "./OrderActions";

type OrdersProps = {};

const OrdersServices = memo(({}: OrdersProps) => {
  const {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    searchValue,
    handleChangeSearchValue,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    anchorEl,
    plans,
  } = useOrders();

  return (
    <>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search"
              value={searchValue}
              onChange={handleChangeSearchValue}
              size="medium"
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Bdroppy Order Id</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total cost</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, index) => (
                <TableRow key={item?._id}>
                  <TableCell>{item?.ids?.platform}</TableCell>
                  <TableCell>{getFormattedDate(item?.date)}</TableCell>
                  <TableCell>{item?.user?.email}</TableCell>
                  <TableCell>
                    {
                      plans?.find(
                        (plan) => plan?.code === item?.items?.[0]?.code
                      )?.name
                    }
                  </TableCell>

                  <TableCell>
                    {
                      ORDER_STATUSES.find(
                        (status) => status.value === item?.status
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {item?.priceCost?.total} {item?.priceCost?.total ? "€" : ""}
                  </TableCell>
                  <TableCell>
                    <OrderActions
                      order={item}
                      updateOrders={getItems}
                      closeParentModal={handleCloseModal}
                      openPopover={handleOpenModal}
                      closePopover={handleCloseModal}
                      popoverOpen={modalOpen === index}
                      anchorEl={anchorEl}
                      index={index}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default OrdersServices;
