import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

import useCategories from "./index.hooks";
import style from "./index.style";
import EditModalContent from "./EditModalContent";

type CategoriesProps = {
  type: string;
};

const Categories = memo(({ type }: CategoriesProps) => {
  const {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    categories,
    editingCategory,
    getCategories,
  } = useCategories({ type });

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditModalContent
          item={editingCategory}
          closeModal={handleCloseModal}
          getItems={getCategories}
          type={type}
        />
      </Modal>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories?.map((category) => (
                <TableRow key={category?._id}>
                  <TableCell>{category?.code}</TableCell>
                  <TableCell>{category?._id}</TableCell>
                  <TableCell>{category?.active.toString()}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleOpenModal(category)}
                    >
                      <EditIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
});

export default Categories;
