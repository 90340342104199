import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import useCarrierFee from "./index.hooks";
import style from "./index.style";
import EditModalContent from "./EditModalContent";

type CarrierFeeProps = {};

const CarrierFee = memo(({}: CarrierFeeProps) => {
  const {
    page,
    carrierFees,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    modalOpen,
    handleOpenModal,
    editingCarrierFee,
    getCarrierFees,
    isEdit,
    handleCloseModal,
    getCarrierName,
    getCountryName,
    searchCountry,
    getSelectCountries,
    handleChangeSelectCountryValue,
    getSelectCarriers,
    searchCarrier,
    handleChangeSelectCarrierValue,
    countries,
    carriers,
  } = useCarrierFee();

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditModalContent
          carrier={editingCarrierFee}
          closeModal={handleCloseModal}
          getCarrierFees={getCarrierFees}
          isEdit={isEdit}
          countries={countries}
          carriers={carriers}
        />
      </Modal>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="select-country"
                id="select-country"
                label="Country"
                value={searchCountry}
                onChange={(ev) =>
                  handleChangeSelectCountryValue(ev.target.value)
                }
              >
                {getSelectCountries.map((country: any) => (
                  <MenuItem
                    key={country.countryCode}
                    value={country.countryCode}
                  >
                    {country.countryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="carrier-label">Carrier</InputLabel>
              <Select
                labelId="select-carrier"
                id="select-carrier"
                label="Carrier"
                value={searchCarrier}
                onChange={(ev) =>
                  handleChangeSelectCarrierValue(ev.target.value)
                }
              >
                {getSelectCarriers.map((carrier: any) => (
                  <MenuItem key={carrier.carrierId} value={carrier.carrierId}>
                    {carrier.carrierName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="large" onClick={handleOpenModal}>
              <AddIcon />
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Carrier</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carrierFees?.map((carrierFee) => (
                <TableRow key={carrierFee?._id}>
                  <TableCell>{getCarrierName(carrierFee?.carrier)}</TableCell>
                  <TableCell>{carrierFee?.fee}</TableCell>
                  <TableCell>{carrierFee?.weight}</TableCell>
                  <TableCell>{getCountryName(carrierFee?.country)}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleOpenModal(carrierFee, true)}
                    >
                      <EditIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default CarrierFee;
