import CloseIcon from "@mui/icons-material/Close";
import { Clear } from "@mui/icons-material";
import {
  IconButton,
  Typography,
  Select,
  Chip,
  MenuItem,
  Switch,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  Grid,
  InputLabel,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { Box } from "@mui/system";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { STATUSES, ROLES } from "constants/contants";
import useEditUser from "./index.hooks";
import style from "./index.style";

type EditUserProps = {
  user: any;
  closeModal: () => void;
  getUsers: () => void;
};

const EditUser = memo(({ user, closeModal, getUsers }: EditUserProps) => {
  const {
    handleChangeValue,
    editingUser,
    getSelectBrands,
    handleChangeSelectValue,
    getSelectCountries,
    showLimitTarsCount,
    handleChangeLimitTarsSwitch,
    handleChangeExpiresDate,
    handleChangeSelectRoleValue,
    addNewRole,
    deleteRole,
    updateUser,
    isMobile,
    getLongToken,
    longToken,
    handleChangeProductsLimit,
    logAs,
    resetPassword,
    getFrontID,
    getBackID,
  } = useEditUser({ user, getUsers });
  return (
    <Grid container spacing={3}>
      {editingUser && (
        <>
          <IconButton sx={style.modal.closeButton} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
          <Grid item xs={12}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Editing user {user?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              value={editingUser?.name}
              onChange={(ev) => handleChangeValue(ev.target.value, "name")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Catalog number"
              value={editingUser?.catalogNumber}
              onChange={(ev) =>
                handleChangeValue(ev.target.value, "catalogNumber")
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="brands-label">Brands</InputLabel>
              <Select
                fullWidth
                labelId="select-brand"
                id="select-brand"
                multiple
                label="Brands"
                value={editingUser?.brands}
                onChange={(ev) =>
                  handleChangeSelectValue(ev.target.value, "brands")
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value: string) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {getSelectBrands.map((brand: string) => (
                  <MenuItem key={brand} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="brands-label">Status</InputLabel>
              <Select
                labelId="select-status"
                id="select-status"
                label="Status"
                value={editingUser?.status}
                onChange={(ev) =>
                  handleChangeSelectValue(ev.target.value, "status")
                }
              >
                {STATUSES.map((status) => (
                  <MenuItem key={status.label} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="brands-label">Country code</InputLabel>
              <Select
                labelId="select-countryCode"
                id="select-countryCode"
                label="Country code"
                value={editingUser?.countryCode}
                onChange={(ev) =>
                  handleChangeSelectValue(ev.target.value, "countryCode")
                }
              >
                {getSelectCountries.map((country: any) => (
                  <MenuItem
                    key={country.countryCode}
                    value={country.countryCode}
                  >
                    {country.countryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={editingUser?.ignoreRestrinctions}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.checked, "ignoreRestrinctions")
                  }
                />
              }
              label="Ignore restrictions"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={editingUser?.noTax}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.checked, "noTax")
                  }
                />
              }
              label="noTax"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={editingUser?.cod}
                  onChange={(ev) => handleChangeValue(ev.target.checked, "cod")}
                />
              }
              label="cod"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="adhoc"
              value={editingUser?.adhoc}
              onChange={(ev) => handleChangeValue(ev.target.value, "adhoc")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="shippyproApiKey"
              value={editingUser?.shippyproApiKey}
              onChange={(ev) =>
                handleChangeValue(ev.target.value, "shippyproApiKey")
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="catalog-downloadable-radio-group">
                catalogDownloadable
              </FormLabel>
              <RadioGroup
                aria-labelledby="catalog-downloadable-radio-group"
                row={!isMobile}
                name="catalog-downloadable-radio-group"
                value={editingUser?.catalogDownloadable}
                onChange={(ev) =>
                  handleChangeValue(ev.target.value, "catalogDownloadable")
                }
              >
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="always_Active"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="always_Inactive"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="default_role_status"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showLimitTarsCount}
                    onChange={(ev) =>
                      handleChangeLimitTarsSwitch(ev.target.checked)
                    }
                  />
                }
                label="limitTarsCount_Status"
              />
            </Grid>
            <Grid item xs={6}>
              {showLimitTarsCount && (
                <TextField
                  fullWidth
                  label="limitTarsCount"
                  value={editingUser?.limitTarsCount}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "limitTarsCount")
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Typography id="modal-modal-title">Manage roles: </Typography>
            <Typography
              id="modal-modal-title"
              style={{ color: "blue", cursor: "pointer", zIndex: 30 }}
              onClick={addNewRole}
            >
              Add new role
            </Typography>
          </Grid>
          {editingUser?.roles?.map((role: any, index: number) => (
            <Grid
              item
              container
              xs={12}
              spacing={3}
              key={role?._id || role?.name}
            >
              <Grid item xs={4}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  {editingUser?.roles?.length > 1 && (
                    <IconButton onClick={() => deleteRole(index)}>
                      <Clear />
                    </IconButton>
                  )}
                  <FormControl fullWidth>
                    <InputLabel id="brands-label">Role {index + 1}</InputLabel>
                    <Select
                      fullWidth
                      labelId="select-role"
                      id="select-role"
                      label={`Role ${index + 1}`}
                      value={role?.name}
                      onChange={(ev) =>
                        handleChangeSelectRoleValue(ev.target.value, index)
                      }
                    >
                      {ROLES.map((roleName: string) => (
                        <MenuItem key={roleName} value={roleName}>
                          {roleName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Expires"
                    inputFormat="dd/MM/yyyy"
                    value={role?.expires}
                    onChange={(value) => handleChangeExpiresDate(value, index)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Products limit"
                  value={role?.limit}
                  onChange={(ev) =>
                    handleChangeProductsLimit(ev.target.value, index)
                  }
                />
              </Grid>
            </Grid>
          ))}
          <Grid item xs={4}>
            <Button variant="contained" size="large" onClick={updateUser}>
              Save
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" size="large" onClick={getLongToken}>
              Get long token
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" size="large" onClick={logAs}>
              Log As
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" size="large" onClick={resetPassword}>
              Reset Password
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" size="large" onClick={getFrontID}>
              Get Front ID
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" size="large" onClick={getBackID}>
              Get Back ID
            </Button>
          </Grid>
          <Grid item xs={12}>
            {longToken && (
              <>
                <Typography variant="h4">LongToken</Typography>
                <TextField fullWidth value={longToken} />
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default EditUser;
