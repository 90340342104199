import React, { memo } from "react";
import CustomTabs from "components/CustomTabs";
import useLogs from "./index.hooks";
import Stripe from "./Stripe";
import Paypal from "./Paypal";

type LogsProps = {};

const Logs = memo(({}: LogsProps) => {
  const { tabIndex, handleChange } = useLogs();

  return (
    <CustomTabs
      value={tabIndex}
      handleChange={handleChange}
      tabs={["Stripe", "Paypal"]}
      items={[<Stripe />, <Paypal />]}
    />
  );
});

export default Logs;
