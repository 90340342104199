import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlansSetting,
  getPlansSettingId,
} from "redux/slices/platform-data/platform-data.selectors";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { actions } from "redux/slices";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateTimePicker } from "@mui/lab";
import style from "./index.style";
import { TIMEZONES } from "../../../constants/contants";

function TimerPlans() {
  const plansSetting = useSelector(getPlansSetting);
  const id = useSelector(getPlansSettingId);
  const dispatch = useDispatch();
  const [showTimer, setShowTimer] = useState<boolean>();
  const [bannerDesktop, setBannerDesktop] = useState<any>();
  const [bannerMobile, setBannerMobile] = useState<any>();
  const [promoEndDate, setPromoEndDate] = useState<any>();
  const [timeZone, setTimeZone] = useState<any>();
  const [promoStatus, setPromoStatus] = useState<boolean>();
  const [switchYearly, setSwitchYearly] = useState<boolean>();

  useEffect(() => {
    setShowTimer(plansSetting.showTimer);
    setBannerDesktop(plansSetting.bannerDesktop);
    setBannerMobile(plansSetting.bannerMobile);
    setPromoEndDate(plansSetting.promoEndDate);
    setTimeZone(plansSetting.timeZone);
    setPromoStatus(plansSetting.promoStatus);
    setSwitchYearly(plansSetting.switchYearly);
  }, [
    plansSetting.bannerDesktop,
    plansSetting.bannerMobile,
    plansSetting.promoEndDate,
    plansSetting.promoStatus,
    plansSetting.showTimer,
    plansSetting.timeZone,
    plansSetting.switchYearly,
  ]);

  const handleChangeEndDate = useCallback((value: any) => {
    setPromoEndDate(value);
  }, []);

  const handleChangeTimeZone = useCallback((value: any) => {
    setTimeZone(value);
  }, []);

  const handleChangeShowTimer = useCallback(() => {
    setShowTimer((prev) => !prev);
  }, []);

  const handleChangePromoStatus = useCallback(() => {
    setPromoStatus((prev) => !prev);
  }, []);

  const handleChangeYearly = useCallback(() => {
    setSwitchYearly((prev) => !prev);
  }, []);

  const handleChangeBannerDesktop = useCallback(
    (value: any, key: string) => {
      const tempItem: { [key: string]: any } = { ...bannerDesktop };
      tempItem[key] = value;
      setBannerDesktop(tempItem);
    },
    [bannerDesktop]
  );

  const handleChangeBannerMobile = useCallback(
    (value: any, key: string) => {
      const tempItem: { [key: string]: any } = { ...bannerMobile };
      tempItem[key] = value;
      setBannerMobile(tempItem);
    },
    [bannerMobile]
  );

  const onSave = useCallback(async () => {
    try {
      await request({
        path: `${ENDPOINTS.settings}/${id}`,
        method: HttpMethod.PUT,
        body: {
          body: {
            ...plansSetting,
            showTimer,
            bannerDesktop,
            bannerMobile,
            promoEndDate,
            timeZone,
            promoStatus,
            switchYearly,
          },
        },
      });
      const response: any = await request({
        path: ENDPOINTS.settings,
      });
      dispatch(actions.setSettings({ settings: response.data }));
    } catch (e) {
      console.log(e);
    }
  }, [
    bannerDesktop,
    bannerMobile,
    dispatch,
    id,
    plansSetting,
    promoEndDate,
    showTimer,
    timeZone,
    promoStatus,
    switchYearly,
  ]);

  return (
    <Box sx={style.bannersGallery}>
      {bannerDesktop && bannerMobile && promoEndDate && timeZone && (
        <Paper sx={style.editContainer}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End date"
                  inputFormat="dd-MM-yyyy hh:mm:ss"
                  onChange={handleChangeEndDate}
                  value={promoEndDate}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="timezone-label">Time Zone</InputLabel>
                <Select
                  labelId="timezone-country"
                  id="timezone-country"
                  label="Timezone"
                  value={timeZone}
                  onChange={(ev) => handleChangeTimeZone(ev.target.value)}
                >
                  {TIMEZONES.map((timeZone: any) => (
                    <MenuItem key={timeZone.value} value={timeZone.value}>
                      {timeZone.gmt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showTimer as boolean}
                    onChange={handleChangeShowTimer}
                  />
                }
                label="Timer"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={promoStatus as boolean}
                    onChange={handleChangePromoStatus}
                  />
                }
                label="Promo Status"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={switchYearly as boolean}
                    onChange={handleChangeYearly}
                  />
                }
                label="Mostra Annuali "
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Big banner EN"
                value={bannerDesktop?.EN}
                onChange={(ev) =>
                  handleChangeBannerDesktop(ev.target.value, "EN")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Big banner ES"
                value={bannerDesktop?.ES}
                onChange={(ev) =>
                  handleChangeBannerDesktop(ev.target.value, "ES")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Big banner FR"
                value={bannerDesktop?.FR}
                onChange={(ev) =>
                  handleChangeBannerDesktop(ev.target.value, "FR")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Big banner IT"
                value={bannerDesktop?.IT}
                onChange={(ev) =>
                  handleChangeBannerDesktop(ev.target.value, "IT")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Little banner EN"
                value={bannerMobile?.EN}
                onChange={(ev) =>
                  handleChangeBannerMobile(ev.target.value, "EN")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Little banner ES"
                value={bannerMobile?.ES}
                onChange={(ev) =>
                  handleChangeBannerMobile(ev.target.value, "ES")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Little banner FR"
                value={bannerMobile?.FR}
                onChange={(ev) =>
                  handleChangeBannerMobile(ev.target.value, "FR")
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Little banner IT"
                value={bannerMobile?.IT}
                onChange={(ev) =>
                  handleChangeBannerMobile(ev.target.value, "IT")
                }
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Button variant="contained" color="primary" onClick={onSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default TimerPlans;
