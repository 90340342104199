import {
  Box,
  Modal,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import {
  Delete,
  Undo,
  Download,
  Send,
  LocalShipping,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import useOrderActions, { OrderAction } from "./index.hooks";
import style from "./index.style";
import CustomTabs from "../../../components/CustomTabs";
import Products from "./Products";
import Address from "./Address";
import Shipping from "./Shipping";

type OrderActionsProps = {
  order: any;
  updateOrders: () => void;
  closeParentModal: () => void;
};

const OrderActions = memo(
  ({ order, updateOrders, closeParentModal }: OrderActionsProps) => {
    const {
      modalOpen,
      handleOpenModal,
      handleCloseModal,
      isCancellable,
      isReturnableByAdmin,
      canForceOrder,
      hasInvoice,
      downloadInvoice,
      modalValues,
      isEditable,
      onlyAddressEditable,
      editModalOpen,
      handleCloseEditModal,
      tabIndex,
      handleChange,
      editOrder,
      setEditOrder,
      handleSaveEdit,
      canSendWithCOD,
    } = useOrderActions({ order, updateOrders, closeParentModal });
    return (
      <Box>
        {isEditable && (
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              aria-label="confirm"
              size="small"
              onClick={() => handleOpenModal(OrderAction.EDIT)}
            >
              <EditIcon sx={{ fontSize: 20, color: "rgba(0,0,0,0.87)" }} />
            </IconButton>
          </Tooltip>
        )}
        {canSendWithCOD && (
          <Tooltip title="Send with COD" placement="top" arrow>
            <IconButton
              aria-label="confirm"
              size="small"
              onClick={() => handleOpenModal(OrderAction.SEND_WITH_COD)}
            >
              <LocalShipping sx={{ fontSize: 20, color: "rgba(0,0,0,0.87)" }} />
            </IconButton>
          </Tooltip>
        )}
        {canForceOrder && (
          <Tooltip title="Confirm" placement="top" arrow>
            <IconButton
              aria-label="confirm"
              size="small"
              onClick={() => handleOpenModal(OrderAction.CONFIRM)}
            >
              <Send sx={{ fontSize: 20, color: "rgba(0,0,0,0.87)" }} />
            </IconButton>
          </Tooltip>
        )}
        {isCancellable && (
          <Tooltip title="Cancel" placement="top" arrow>
            <IconButton
              aria-label="cancel"
              size="small"
              onClick={() => handleOpenModal(OrderAction.CANCEL)}
            >
              <Delete sx={{ fontSize: 20, color: "rgba(0,0,0,0.87)" }} />
            </IconButton>
          </Tooltip>
        )}
        {isReturnableByAdmin && (
          <Tooltip title="Return" placement="top" arrow>
            <IconButton
              aria-label="return"
              size="small"
              onClick={() => handleOpenModal(OrderAction.RETURN)}
            >
              <Undo sx={{ fontSize: 20, color: "rgba(0,0,0,0.87)" }} />
            </IconButton>
          </Tooltip>
        )}
        {hasInvoice && (
          <Tooltip title="Download invoice" placement="top" arrow>
            <IconButton
              aria-label="return"
              size="small"
              onClick={downloadInvoice}
            >
              <Download sx={{ fontSize: 20, color: "rgba(0,0,0,0.87)" }} />
            </IconButton>
          </Tooltip>
        )}
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style.modal}>
            <Typography sx={{ mb: 2 }}>{modalValues?.label}</Typography>
            <Box sx={style.buttons}>
              <Button
                variant="outlined"
                size="large"
                onClick={handleCloseModal}
                sx={{ marginRight: 4 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  closeParentModal();
                  modalValues?.function();
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={editModalOpen}
          onClose={handleCloseEditModal}
          aria-labelledby="edit-modal-title"
          aria-describedby="edit-modal-description"
        >
          <Box sx={style.editModal}>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography sx={{ mb: 2 }} variant="h3">
                Edit Order
              </Typography>
              <CustomTabs
                value={tabIndex}
                handleChange={handleChange}
                tabs={
                  onlyAddressEditable
                    ? ["Address"]
                    : ["Products", "Address", "Shipping"]
                }
                items={
                  onlyAddressEditable
                    ? [<Address order={editOrder} setOrder={setEditOrder} />]
                    : [
                        <Products order={editOrder} setOrder={setEditOrder} />,
                        <Address order={editOrder} setOrder={setEditOrder} />,
                        <Shipping order={editOrder} setOrder={setEditOrder} />,
                      ]
                }
              />
            </Box>
            <Box sx={style.buttons}>
              <Button
                variant="outlined"
                size="large"
                onClick={handleCloseEditModal}
                sx={{ marginRight: 4 }}
              >
                Cancel
              </Button>
              <Button variant="contained" size="large" onClick={handleSaveEdit}>
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }
);

export default OrderActions;
