import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useState } from "react";
import request, { HttpMethod } from "redux/slices/ajax/request";
import { format } from "date-fns";
import { useDebounce } from "../../utils/useDebounce";

const useEcredit = () => {
  const [page, setPage] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [shouldFilter, setShouldFilter] = useState(true);
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setCurrentItem(null);
  }, []);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTextValue = useDebounce(searchValue, 500);
  const [showAll, setShowAll] = useState(false);

  const onChangeDate = useCallback((newDate) => {
    setStartDate(newDate[0]);
    setEndDate(newDate[1]);
  }, []);

  const handleShowAll = useCallback(() => {
    setShowAll((prevState) => !prevState);
    setPage(0);
  }, []);

  const handleOpenModal = useCallback((item) => {
    setModalOpen(true);
    setCurrentItem(item);
  }, []);

  const onDownload = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.withdrawTransaction}/export.csv`,
      method: HttpMethod.POST,
      query: {
        startTime: format(startDate, "yyyy-MM-dd"),
        endTime: format(endDate, "yyyy-MM-dd"),
      },
      responseType: "blob",
    });
    const filename =
      response?.headers?.["content-disposition"]
        .split("filename=")?.[1]
        .replace(/"/g, "") || "ecredit.csv";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }, [endDate, startDate]);

  const getItems = useCallback(async () => {
    let response: any = {};
    const q: any = {};
    if (!showAll) {
      q.status = "pending";
    }
    response = await request({
      path: `${ENDPOINTS.withdrawTransaction}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        sortings: { createdAt: -1 },
        populate: [{ path: "user", select: ["email"] }],
        q,
      },
    });
    setItems(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [page, pageSize, showAll]);

  useEffect(() => {
    if (shouldFilter) {
      getItems().then();
    }
  }, [getItems, shouldFilter]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const confirmWithdraw = useCallback(async () => {
    let response: any = {};
    response = await request({
      method: HttpMethod.PUT,
      path: `${ENDPOINTS.user}/${currentItem?.user?._id}/withdraw_transaction/${currentItem?._id}`,
    });
    await getItems();
    setModalOpen(false);
    setCurrentItem(null);
    console.log("response", response);
  }, [currentItem?._id, currentItem?.user, getItems]);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  return {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    setShouldFilter,
    setPage,
    confirmWithdraw,
    currentItem,
    startDate,
    endDate,
    onChangeDate,
    onDownload,
    searchValue,
    handleChangeSearchValue,
    showAll,
    handleShowAll,
  };
};

export default useEcredit;
