import React, { memo } from "react";
import CustomTabs from "components/CustomTabs";
import useBillsAndCustomers from "./index.hooks";
import Products from "./Products";
import Bills from "./Bills";
import Intras from "./Intras";
import MerchantInvoice from "./MerchantInvoice";
import IntraServices from "./IntraServices";
import BillsExpanded from "./BillsExpanded";
import Adhocs from "./AdHoc";

type BillsAndCustomersProps = {};

const BillsAndCustomers = memo(({}: BillsAndCustomersProps) => {
  const { tabIndex, handleChange } = useBillsAndCustomers();

  return (
    <CustomTabs
      value={tabIndex}
      handleChange={handleChange}
      tabs={[
        "Bills",
        "Products",
        "Intras",
        "Intra services",
        "Merchant invoice",
        "Bills expanded",
        "Ad hoc",
      ]}
      items={[
        <Bills />,
        <Products />,
        <Intras />,
        <IntraServices />,
        <MerchantInvoice />,
        <BillsExpanded />,
        <Adhocs />,
      ]}
    />
  );
});

export default BillsAndCustomers;
