import globalStyle from "index.style";

const useStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0",
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    cursor: "pointer",
    "&:hover": {
      color: "#0F45FF",
    },
  },
  icon: {
    marginRight: 1,
  },
  divider: {
    margin: "6px 10px",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "15px",
    p: 4,
    flexWrap: "wrap",
    overflowX: "scroll",
    maxHeight: "80vh",
    display: "flex",
    justifyContent: "center",
    closeButton: {
      position: "absolute",
      top: 5,
      right: 5,
    },
  },
  buttons: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
} as const;

export default { ...globalStyle, ...useStyles };
