import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Typography,
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo, useMemo } from "react";
import { DeleteForever, PlayArrow, Stop } from "@mui/icons-material";
import style from "./index.style";
import useLightsailManagement from "./index.hooks";
import { getFormattedDate } from "../../utils";

type LightsailManagementProps = {};

const LightsailManagement = memo(({}: LightsailManagementProps) => {
  const {
    filteredItems,
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    modalType,
    onSubmit,
    nameFilter,
    ipAddressFilter,
    setNameFilter,
    setIpAddressFilter,
    emailFilter,
    setEmailFilter,
    isExpired,
    setIsExpired,
  } = useLightsailManagement();

  const getModalType = useMemo(() => {
    switch (modalType) {
      case "play":
        return "Far ripartire";
      case "stop":
        return "fermare";
      case "delete":
        return "eliminare";
      default:
        return "";
    }
  }, [modalType]);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style.modal}>
          <Typography sx={{ mb: 2 }}>
            <Typography>{`Sei sicuro di voler ${getModalType} l'istanza?`}</Typography>
          </Typography>
          <Box sx={style.buttons}>
            <Button variant="contained" size="large" onClick={onSubmit}>
              Confirm
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="error"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box sx={style.user.wrapper}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3}>
            <TextField
              fullWidth
              label="Name"
              value={nameFilter}
              onChange={(ev) => setNameFilter(ev.target.value)}
              size="medium"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              fullWidth
              label="Email"
              value={emailFilter}
              onChange={(ev) => setEmailFilter(ev.target.value)}
              size="medium"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              fullWidth
              label="Ip Address"
              value={ipAddressFilter}
              onChange={(ev) => setIpAddressFilter(ev.target.value)}
              size="medium"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isExpired}
                  onChange={() => setIsExpired((prevState) => !prevState)}
                />
              }
              label="Is expired"
            />
          </Grid>
        </Grid>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>BundleId</TableCell>
                <TableCell>Ip Address</TableCell>
                <TableCell>Expire Date</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems?.map((item, index) => (
                <TableRow key={item?._id}>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>{item?.email}</TableCell>
                  <TableCell>{item?.account}</TableCell>
                  <TableCell>{item?.bundleId}</TableCell>
                  <TableCell>{item?.privateIpAddress}</TableCell>
                  <TableCell>
                    {getFormattedDate(item?.roles?.[0]?.expires)}
                  </TableCell>
                  <TableCell>{item?.state}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex" }}>
                      {item?.state === "stopped" && (
                        <IconButton
                          aria-label="play"
                          size="small"
                          onClick={() => handleOpenModal("play", item)}
                        >
                          <PlayArrow sx={{ fontSize: 20 }} />
                        </IconButton>
                      )}
                      {item?.state === "stopped" && (
                        <IconButton
                          aria-label="play"
                          size="small"
                          onClick={() => handleOpenModal("delete", item)}
                        >
                          <DeleteForever sx={{ fontSize: 20 }} />
                        </IconButton>
                      )}
                      {item?.state === "running" && (
                        <IconButton
                          aria-label="stop"
                          size="small"
                          onClick={() => handleOpenModal("stop", item)}
                        >
                          <Stop sx={{ fontSize: 20 }} />
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
});

export default LightsailManagement;
