import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import request from "redux/slices/ajax/request";
import { useSelector } from "react-redux";
import { getCountries } from "redux/slices/platform-data/platform-data.selectors";

const useVatSystems = () => {
  const [page, setPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [carrierFees, setVatSystemss] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingVatSystems, setEditingVatSystems] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [carriers, setCarriers] = useState<any[]>([]);
  const countries = useSelector(getCountries);
  const [searchCountry, setSearchCountry] = useState("All");
  const [searchCarrier, setSearchCarrier] = useState("All");

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setEditingVatSystems(null);
  }, []);

  const getCarriers = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.carrier}/filter`,
      query: {
        pageNumber: 1,
        pageSize: 500,
      },
    });
    setCarriers(response.data.items);
  }, []);

  const getCarrierName = useCallback(
    (carrierName) =>
      carriers.find((carrier) => carrier?._id === carrierName)?.name,
    [carriers]
  );

  const getCountryName = useCallback(
    (countryCode) =>
      countries?.find((country: any) => country?.countrycode === countryCode)
        ?.country,
    [countries]
  );

  const getSelectCountries = useMemo(() => {
    const selectCountries = countries?.map((country: any) => ({
      countryName: country?.country,
      countryCode: country?.countrycode,
    }));
    selectCountries.unshift({
      countryName: "All",
      countryCode: "All",
    });
    return selectCountries;
  }, [countries]);

  const getSelectCarriers = useMemo(() => {
    const selectCarriers = carriers?.map((carrier: any) => ({
      carrierName: carrier.name,
      carrierId: carrier._id,
    }));
    selectCarriers.unshift({
      carrierName: "All",
      carrierId: "All",
    });
    return selectCarriers;
  }, [carriers]);

  const handleOpenModal = useCallback(
    (carrierFee?: any, tempIsEdit?: boolean) => {
      setIsEdit(tempIsEdit || false);
      if (tempIsEdit) {
        setEditingVatSystems(carrierFee);
      } else {
        setEditingVatSystems({
          online: true,
          refId: 0,
          days: 0,
        });
      }
      setModalOpen((prevState) => !prevState);
    },
    []
  );

  const getVatSystems = useCallback(async () => {
    let response: any = {};
    const q: any = {};
    if (searchCountry !== "All") {
      q.country = searchCountry;
    }
    if (searchCarrier !== "All") {
      q.country = searchCarrier;
    }
    response = await request({
      path: `${ENDPOINTS.carrierFee}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q,
        p: {},
        populate: {},
        sortings: {
          carrierFee: 1,
          fee: 1,
          weight: 1,
        },
      },
    });
    setVatSystemss(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [page, pageSize, searchCarrier, searchCountry]);

  useEffect(() => {
    getVatSystems().then();
    getCarriers().then();
  }, [getVatSystems, getCarriers]);
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const handleChangeSelectCountryValue = useCallback((value: string) => {
    setSearchCountry(value);
  }, []);

  const handleChangeSelectCarrierValue = useCallback((value: string) => {
    setSearchCarrier(value);
  }, []);

  return {
    page,
    searchValue,
    carrierFees,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    handleChangeSearchValue,
    modalOpen,
    handleOpenModal,
    editingVatSystems,
    getVatSystems,
    isEdit,
    handleCloseModal,
    getCarrierName,
    getCountryName,
    searchCountry,
    getSelectCountries,
    handleChangeSelectCountryValue,
    getSelectCarriers,
    searchCarrier,
    handleChangeSelectCarrierValue,
    getCarriers,
    countries,
    carriers,
  };
};

export default useVatSystems;
