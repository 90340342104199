import globalStyle from "index.style";

const useStyles = {
  editValue: {
    padding: "16px 20px",
    display: "flex",
    justifyContent: "space-between",
    border: "solid thin #ccc1",
    "&:hover": {
      background: "#ccc4",
      cursor: "pointer",
      color: "black",
      fontWeight: 600,
    },
  },
  active: {
    background: "#ccc4",
    cursor: "pointer",
    color: "black",
    fontWeight: 600,
  },
  translationMissing: {
    color: "red",
    "&:hover": {
      color: "red",
    },
  },
  editForm: {
    background: "#f6f8fe",
    padding: "2em",
    minHeight: "90vh",
    width: 500,
  },
  defaultValue: {
    margin: "5px 0px",
  },
  translationBox: {
    display: "flex",
    marginBottom: "10px",
  },
  flag: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    img: {
      height: 30,
    },
  },
} as const;

export default { ...globalStyle, ...useStyles };
