import React, { useCallback, useEffect, useState } from "react";
import request from "../../redux/slices/ajax/request";
import ENDPOINTS from "../../constants/endpoints";

const useVatManagement = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [vatRulesPage, setVatRulesPage] = useState(0);
  const [vatSystemsPage, setVatSystemsPage] = useState(0);
  const [platformsPage, setPlatformsPage] = useState(0);
  const [vatRulesPageSize, setVatRulesPageSize] = useState(10);
  const [searchPlatform, setSearchPlatform] = useState("All");
  const [searchVat, setSearchVat] = useState("All");
  const [vatRules, setVatRules] = useState<any[]>([]);
  const [vatSystems, setVatSystems] = useState<any[]>([]);
  const [platforms, setPlatforms] = useState<any[]>([]);
  const [totalNumberOfVatRulesElements, setTotalNumberOfVatRulesElements] =
    useState<number>(1);
  const [totalNumberOfVatSystemsElements, setTotalNumberOfVatSystemsElements] =
    useState<number>(1);
  const [totalNumberOfPlatformsElements, setTotalNumberOfPlatformsElements] =
    useState<number>(1);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
    },
    []
  );
  const getVatRules = useCallback(async () => {
    let response: any = {};
    const q: any = {};
    if (searchPlatform !== "All") {
      q.platform = searchPlatform;
    }
    if (searchVat !== "All") {
      q.vat = searchVat;
    }
    response = await request({
      path: `${ENDPOINTS.vatRule}/filter`,
      query: {
        pageNumber: vatRulesPage + 1,
        pageSize: vatRulesPageSize,
        q,
        p: {},
        populate: [
          { path: "user", select: "email" },
          { path: "platform" },
          { path: "vat" },
        ],
        sortings: {
          priority: 1,
        },
      },
    });
    setVatRules(response.data.items);
    setTotalNumberOfVatRulesElements(response.data.totalNumberOfElements);
  }, [searchPlatform, searchVat, vatRulesPage, vatRulesPageSize]);

  const getVatSystems = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.vatSystem}/filter`,
      query: {
        pageNumber: vatSystemsPage + 1,
        pageSize: 10000,
        q: {},
        p: {},
        populate: {},
        sortings: {},
      },
    });
    setVatSystems(response.data.items);
    setTotalNumberOfVatSystemsElements(response.data.totalNumberOfElements);
  }, [vatSystemsPage]);

  const getPlatforms = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.platform}/filter`,
      query: {
        pageNumber: platformsPage + 1,
        pageSize: 10000,
        q: {},
        p: {},
        populate: [{ path: "vatSystem" }],
        sortings: {},
      },
    });
    setPlatforms(response.data.items);
    setTotalNumberOfPlatformsElements(response.data.totalNumberOfElements);
  }, [platformsPage]);

  useEffect(() => {
    getVatRules().then();
    getVatSystems().then();
    getPlatforms().then();
  }, [getPlatforms, getVatRules, getVatSystems]);
  return {
    tabIndex,
    handleChange,
    vatRulesPage,
    vatSystemsPage,
    platformsPage,
    vatRulesPageSize,
    searchPlatform,
    searchVat,
    vatRules,
    vatSystems,
    platforms,
    totalNumberOfVatRulesElements,
    totalNumberOfVatSystemsElements,
    totalNumberOfPlatformsElements,
    setVatRulesPage,
    setVatSystemsPage,
    setPlatformsPage,
    setVatRulesPageSize,
    getVatRules,
    getVatSystems,
    getPlatforms,
    setSearchPlatform,
    setSearchVat,
  };
};

export default useVatManagement;
