import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { actions, reducers, selectors, sagas } from "./slices";

const rootSaga = function* () {
  yield all(sagas.map((s) => s()));
};

const sagaMiddleware = createSagaMiddleware();

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });
const history = createBrowserHistory();
const appReducer = createRootReducer(history);
const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    storage.removeItem("persist:router");
    storage.removeItem("persist:user");
    storage.removeItem("persist:ajax");
    storage.removeItem("persist:feedback");
    storage.removeItem("persist:platformData");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["router", "user", "ajax", "feedback", "platformData"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware, routerMiddleware(history)],
});
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export default store;
export { actions, persistor, selectors, history };
