const useStyles = {
  mainContainer: {
    padding: " 116px 16px",
    transition: "margin-left 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    maxWidth: "4000px !important",
  },
  table: {
    marginTop: "10px",
    boxShadow: "none",
    border: "1px solid #ddd8",
    th: {
      backgroundColor: "#e7ebf0",
      border: "1px solid #ddd8",
    },
    td: {
      padding: "6px 16px",
      border: "1px solid #ddd8",
    },
    tr: {
      backgroundColor: "#fff",
      "&:nth-child(even)": {
        backgroundColor: "#f6f6f6",
      },
    },
  },
} as const;

export default useStyles;
