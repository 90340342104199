import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

import useVatRules from "./index.hooks";
import style from "./index.style";
import EditModalContent from "./EditModalContent";

type VatRulesProps = {
  page: number;
  pageSize: number;
  vatRules: any[];
  vatSystems: any[];
  platforms: any[];
  totalNumberOfElements: number;
  setPage: any;
  setPageSize: any;
  getVatRules: any;
  searchPlatform: any;
  searchVat: any;
  setSearchPlatform: any;
  setSearchVat: any;
};

const VatRules = memo(
  ({
    page,
    pageSize,
    vatRules,
    vatSystems,
    platforms,
    totalNumberOfElements,
    setPage,
    setPageSize,
    getVatRules,
    searchPlatform,
    searchVat,
    setSearchPlatform,
    setSearchVat,
  }: VatRulesProps) => {
    const {
      carriers,
      handleChangePage,
      handlePageSizeChange,
      modalOpen,
      handleOpenModal,
      editingVatRules,
      isEdit,
      handleCloseModal,
      getSelectPlatforms,
      getSelectVats,
      handleChangeSelectPlatformValue,
      handleChangeSelectVatValue,
    } = useVatRules({
      page,
      pageSize,
      vatRules,
      vatSystems,
      platforms,
      totalNumberOfElements,
      setPage,
      setPageSize,
      getVatRules,
      searchPlatform,
      searchVat,
      setSearchVat,
      setSearchPlatform,
    });

    return (
      <>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <EditModalContent
            carrier={editingVatRules}
            closeModal={handleCloseModal}
            getVatRules={getVatRules}
            isEdit={isEdit}
          />
        </Modal>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="platform-label">Platform</InputLabel>
              <Select
                labelId="select-platform"
                id="select-platform"
                label="Platform"
                value={searchPlatform}
                onChange={(ev) =>
                  handleChangeSelectPlatformValue(ev.target.value)
                }
              >
                {getSelectPlatforms.map((platform: any) => (
                  <MenuItem
                    key={platform.platformId}
                    value={platform.platformId}
                  >
                    {platform.platformName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="vat-label">Vat</InputLabel>
              <Select
                labelId="select-vat"
                id="select-vat"
                label="Vat"
                value={searchVat}
                onChange={(ev) => handleChangeSelectVatValue(ev.target.value)}
              >
                {getSelectVats.map((vat: any) => (
                  <MenuItem key={vat.vatId} value={vat.vatId}>
                    {vat.vatCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="large" onClick={handleOpenModal}>
              <AddIcon />
              Create
            </Button>
          </Grid>
        </Grid>
        <Box sx={style.user.wrapper}>
          <TableContainer sx={style.table} component={Paper}>
            <Table aria-label="users-table">
              <TableHead>
                <TableRow>
                  <TableCell>Validate Vat</TableCell>
                  <TableCell>Billing Region</TableCell>
                  <TableCell>Shipping Region</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Vat Class</TableCell>
                  <TableCell>Vat System</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vatRules?.map((vatRule) => (
                  <TableRow key={vatRule?._id}>
                    <TableCell>{vatRule?.validatedVat?.toString()}</TableCell>
                    <TableCell>
                      {vatRule?.billingRegion ? vatRule?.billingRegion : "ALL"}
                    </TableCell>
                    <TableCell>
                      {vatRule?.shippingRegion
                        ? vatRule?.shippingRegion
                        : "ALL"}
                    </TableCell>
                    <TableCell>
                      {vatRule?.username ? vatRule?.username : "ALL"}
                    </TableCell>
                    <TableCell>{vatRule?.vatClass}</TableCell>
                    <TableCell>{vatRule?.vat?.code}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleOpenModal(vatRule, true)}
                      >
                        <EditIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalNumberOfElements}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handlePageSizeChange}
            />
          </TableContainer>
        </Box>
      </>
    );
  }
);

export default VatRules;
