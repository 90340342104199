import CustomTabs from "components/CustomTabs";
import React, { memo } from "react";

import usePlans from "./index.hooks";
import Plan from "./Plan";

type PlansProps = {};

const Service = memo(({}: PlansProps) => {
  const { plans, handleChange, getTabs, tab, onlineTab, setOnlineTab } =
    usePlans();

  return (
    <CustomTabs
      value={onlineTab}
      handleChange={(_: any, v: any) => setOnlineTab(v)}
      tabs={["Online", "Offline"]}
      items={[
        <CustomTabs
          value={tab}
          handleChange={handleChange}
          tabs={getTabs}
          items={plans.map((plan: any) => (
            <Plan plan={plan} />
          ))}
        />,
        <CustomTabs
          value={tab}
          handleChange={handleChange}
          tabs={getTabs}
          items={plans.map((plan: any) => (
            <Plan plan={plan} />
          ))}
        />,
      ]}
    />
  );
});

export default Service;
