import {
  Box,
  Modal,
  Typography,
  Divider,
  Button,
  IconButton,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { memo } from "react";
import { Cached, Download } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import useOrderActions from "./index.hooks";
import style from "./index.style";

type OrderActionsProps = {
  order: any;
  updateOrders: () => void;
  closeParentModal: () => void;
  openPopover: any;
  closePopover: any;
  popoverOpen: any;
  anchorEl: any;
  index: number;
};

const OrderActions = memo(
  ({
    order,
    updateOrders,
    closeParentModal,
    openPopover,
    closePopover,
    popoverOpen,
    anchorEl,
    index,
  }: OrderActionsProps) => {
    const {
      modalOpen,
      setModalOpen,
      handleCloseModal,
      hasInvoice,
      downloadInvoice,
      editingItem,
      setEditingItem,
      statuses,
      updateStatus,
    } = useOrderActions({ order, updateOrders, closeParentModal });

    return (
      <Box>
        <IconButton
          aria-label="edit"
          size="small"
          onClick={(ev) => openPopover(ev, index)}
        >
          <EditIcon sx={{ fontSize: 20 }} />
        </IconButton>

        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style.modal}>
            <Typography sx={{ mb: 2 }}>Update status</Typography>
            <FormControl fullWidth>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="select-country"
                id="select-country"
                label="Country"
                value={editingItem}
                onChange={(ev) => setEditingItem(ev.target.value)}
              >
                {Object.keys(statuses).map((key) => (
                  <MenuItem key={key} value={statuses[key]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={style.buttons}>
              <Button
                variant="outlined"
                size="large"
                onClick={handleCloseModal}
                sx={{ marginRight: 4 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={async () => {
                  closeParentModal();
                  await updateStatus();
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
        <Popover
          elevation={1}
          open={popoverOpen}
          onClose={closePopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={style.container}>
            <>
              <Box sx={style.row} onClick={() => setModalOpen(true)}>
                <Cached sx={style.icon} />
                <Typography>Update status</Typography>
              </Box>
              <Divider sx={style.divider} />
            </>
            {hasInvoice && (
              <Box sx={style.row} onClick={downloadInvoice}>
                <Download sx={style.icon} />{" "}
                <Typography>Download invoice</Typography>
              </Box>
            )}
          </Box>
        </Popover>
      </Box>
    );
  }
);

export default OrderActions;
