import { useCallback, useState } from "react";
import request, { HttpMethod } from "../../../redux/slices/ajax/request";
import ENDPOINTS from "../../../constants/endpoints";

type useInvoiceActionsProps = {
  invoice: any;
  updateInvoices: () => void;
  closeParentModal: () => void;
};

export enum InvoiceAction {
  GENERATE = "generate",
  REBUILD = "rebuild",
}

const useInvoiceActions = ({
  invoice,
  updateInvoices,
  closeParentModal,
}: useInvoiceActionsProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalValues, setModalValues] = useState<any>(null);
  const canGenerateCreditNote = !invoice?.isRefund;
  const canRebuildInvoice =
    !invoice?.isRefund &&
    invoice?.type === "S" &&
    invoice?.orders?.[0] &&
    !invoice.isInvoice;

  const downloadInvoice = useCallback(async () => {
    const response: any = await request({
      path: ENDPOINTS.downloadInvoice,
      method: HttpMethod.GET,
      additionalHeaders: {
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      responseType: "blob",
      pathParams: {
        invoiceId: invoice._id,
      },
    });
    const filename =
      response?.headers?.["content-disposition"]
        ?.split("filename=")?.[1]
        .replace(/"/g, "") || "Bill.pdf";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    closeParentModal();
  }, [closeParentModal, invoice._id]);

  const generateCreditNote = useCallback(async () => {
    await request({
      path: ENDPOINTS.createCreditNote,
      pathParams: {
        invoiceId: invoice?._id,
      },
    });
    updateInvoices();
  }, [invoice?._id, updateInvoices]);

  const rebuildInvoice = useCallback(async () => {
    await request({
      path: ENDPOINTS.rebuildInvoice,
      pathParams: {
        invoiceId: invoice?._id,
      },
    });
    updateInvoices();
  }, [invoice?._id, updateInvoices]);

  const handleOpenModal = (type?: string) => {
    switch (type) {
      case InvoiceAction.GENERATE:
        setModalValues({
          label:
            "You are going to create negative receipt/credit note for the invoice",
          function: generateCreditNote,
        });
        break;
      case InvoiceAction.REBUILD:
        setModalValues({
          label: "You are going rebuild this receipt as invoice",
          function: rebuildInvoice,
        });
        break;
      default:
        console.log("ERROR");
    }
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalValues(null);
    setModalOpen(false);
    closeParentModal();
  };

  return {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    canGenerateCreditNote,
    canRebuildInvoice,
    downloadInvoice,
    modalValues,
  };
};

export default useInvoiceActions;
