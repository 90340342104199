import { createTheme } from '@mui/material/styles';

// https://mui.com/customization/theming/
// https://bareynol.github.io/mui-theme-creator/
// https://mui.com/system/basics/
// https://mui.com/system/the-sx-prop/
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#0F45FF',
      },
      secondary: {
        main: '#00e4b8',
      },
      error: {
        main: '#FF3F35',
      },
      warning: {
        main: '#FFC014',
      },
      info: {
        main: '#33A9F7',
      },
      success: {
        main: '#37CC5C',
      },
      type: 'light',
      background: {
        default: '#F2F6F6',
      },
    },
    typography: {
      h1: {
        fontFamily: 'Public Sans',
        fontSize: 48,
        fontWeight: 700,
      },
      h2: {
        fontSize: 40,
        fontWeight: 600,
      },
      fontFamily: 'Public Sans',
      h3: {
        fontSize: 26,
        fontWeight: 500,
      },
      h4: {
        fontSize: 20,
      },
      h5: {
        fontSize: 18,
      },
      h6: {
        fontSize: 16,
      },
      subtitle1: {
        fontSize: 18,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 15,
      },
      body1: {
        fontSize: 16,
        fontWeight:500,
      },
      span: {
        fontSize: 16,
        fontWeight:500,
        color:'red',
      },
      button: {
        fontSize: 16,
      },
      caption: {
        fontSize: 14,
        fontWeight: 500,
      },
      overline: {
        fontSize: 13,
      },
      p:{
        fontSize:14,
      }
    },
    shape: {
      borderRadius: 4,
    },
  }
);

export default theme;
