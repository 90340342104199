import React, { memo, useCallback, useMemo } from "react";
import { Box } from "@mui/system";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { getFileAddress } from "../../../../utils";

type ProductProps = {
  product: any;
  quantity: any;
  setQuantity: any;
  onClickRemove: any;
  model: any;
  setModel: any;
};

const Product = memo(
  ({
    product,
    quantity,
    setQuantity,
    onClickRemove,
    model,
    setModel,
  }: ProductProps) => {
    const getModels = useMemo(
      () =>
        product?.models?.map((model: any) => ({
          id: model?.refId,
          size: model?.size,
          disabled: model?.availability === 0,
        })),
      [product?.models]
    );
    return (
      <Grid container spacing={3} sx={{ width: "100%" }} alignItems="center">
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: 80, width: 80 }}
              src={getFileAddress(product.pictures?.[0]?.url)}
              alt=""
            />
            <Box>
              <Typography>{product?.brand}</Typography>
              <Typography>{product?.code}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={5} sm={5}>
          <FormControl fullWidth>
            <InputLabel id="timezone-label">Model</InputLabel>
            <Select
              labelId="model"
              id="model"
              label="Model"
              size="small"
              value={model?.id}
              onChange={(ev) =>
                setModel(
                  getModels.find((a: any) => a.id === ev.target.value)?.size,
                  ev.target.value
                )
              }
            >
              {getModels.map((model: any) => (
                <MenuItem
                  key={model?.id}
                  value={model?.id}
                  disabled={model?.disabled}
                >
                  {model?.size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5} sm={5}>
          <TextField
            label="Quantity"
            value={quantity}
            onChange={(ev) => setQuantity(ev.target.value)}
            size="small"
          />
          <Box sx={{ position: "absolute" }}>
            Availability: {product?.availability}
          </Box>
        </Grid>
        <Grid item xs={2} sm={2}>
          <IconButton aria-label="cancel" size="small" onClick={onClickRemove}>
            <Delete sx={{ fontSize: 20, color: "rgba(0,0,0,0.87)" }} />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
);

type ProductsProps = {
  order: any;
  setOrder: any;
};

const Products = memo(({ order, setOrder }: ProductsProps) => {
  const updateQuantity = useCallback(
    (quantity: number, index: number) => {
      const tempOrder = { ...order };
      tempOrder.items[index].quantity = quantity;
      setOrder(tempOrder);
    },
    [order, setOrder]
  );

  const handleRemove = useCallback(
    (index: number) => {
      const tempOrder = { ...order };
      tempOrder.items.splice(index, 1);
      setOrder(tempOrder);
    },
    [order, setOrder]
  );

  const handleSetModel = useCallback(
    (index: number, size: string, id: number) => {
      const tempOrder = { ...order };
      tempOrder.items[index].size = size;
      tempOrder.items[index].stockId = id;
      tempOrder.items[index].quantity = 1;
      setOrder(tempOrder);
    },
    [order, setOrder]
  );
  return (
    <Box sx={{ marginBottom: "30px" }}>
      {order?.items.map((item: any, index: number) => (
        <>
          <Product
            product={item?.product}
            quantity={item?.quantity}
            setQuantity={(quantity: number) => updateQuantity(quantity, index)}
            onClickRemove={() => handleRemove(index)}
            model={{
              size: item?.size,
              id: item?.stockId,
            }}
            setModel={(size: string, id: number) =>
              handleSetModel(index, size, id)
            }
          />
          {index !== order.items.length - 1 && (
            <Divider sx={{ marginTop: "40px" }} />
          )}
        </>
      ))}
    </Box>
  );
});

export default Products;
