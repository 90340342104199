import globalStyle from "index.style";

const useStyles = {
  button: {
    color: "secondary.main",
  },
  login: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    padding: 10,
    width: 500,
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
  },
} as const;

export default { ...globalStyle, ...useStyles };
