import { useCallback, useEffect, useMemo, useState } from "react";
import ENDPOINTS from "constants/endpoints";
import request, { HttpMethod } from "redux/slices/ajax/request";
import { useMediaQuery } from "@mui/material";
import theme from "style/theme";
import { useSelector } from "react-redux";
import { getCountries } from "../../../../redux/slices/platform-data/platform-data.selectors";

type useEditModalContentProps = {
  item: any;
  getItems: any;
  closeModal: () => void;
};

const useEditModalContent = ({
  item,
  getItems,
  closeModal,
}: useEditModalContentProps) => {
  const [editingItem, setEditingItem] = useState<any>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const countries = useSelector(getCountries);

  const getSelectCountries = useMemo(
    () =>
      countries?.map((country: any) => ({
        countryName: country?.country,
        countryCode: country?.countrycode,
      })),
    [countries]
  );

  useEffect(() => {
    setEditingItem(item);
  }, [item]);
  const handleChangeValue = useCallback(
    (value: any, key: string) => {
      const tempItem = { ...editingItem };
      tempItem[key] = value;
      setEditingItem(tempItem);
    },
    [editingItem]
  );

  const handleChangeSelectValue = useCallback(
    (value: string, key: string) => {
      const tempItem = { ...editingItem };
      tempItem[key] = value;
      setEditingItem(tempItem);
    },
    [editingItem]
  );

  const updateItem = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: `${ENDPOINTS.brand}/${editingItem?._id}`,
      method: HttpMethod.PUT,
      body: {
        name: editingItem?.name,
        restrictions: editingItem?.restrictions,
        hidden: editingItem?.hidden,
        active: editingItem?.active,
      },
    });
    if (response.status === 200) {
      // Apro feedback success
      closeModal();
    }
    await getItems();
    console.log("response", response);
  }, [
    closeModal,
    editingItem?._id,
    editingItem?.active,
    editingItem?.hidden,
    editingItem?.name,
    editingItem?.restrictions,
    getItems,
  ]);
  return {
    handleChangeValue,
    editingItem,
    updateItem,
    getSelectCountries,
    handleChangeSelectValue,
  };
};

export default useEditModalContent;
