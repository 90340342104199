import { useDispatch } from "react-redux";
import { useState, useCallback, useEffect } from "react";

type UseReturnModalContent = {
  product: any;
};

const useReturnModalContent = ({ product }: UseReturnModalContent) => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  useEffect(() => {
    const tempArray: boolean[] = [];
    product?.[0]?.items?.forEach(() => tempArray.push(false));
    setSelectedItems(tempArray);
  }, [product]);
  const handleSelectItem = useCallback(
    (index: number) => {
      const tempSelectedItems = [...selectedItems];
      tempSelectedItems[index] = !tempSelectedItems[index];
      setSelectedItems(tempSelectedItems);
    },
    [selectedItems]
  );

  const [shippingEnabled, setShippingEnabled] = useState(false);
  const [extraLineEnabled, setExtraLineEnabled] = useState(false);
  const [manualEcreditEnabled, setManualEcreditEnabled] = useState(false);
  const handleShippingEnabledCheck = useCallback(() => {
    setShippingEnabled((prevState) => !prevState);
  }, []);
  const handleEnableExtraLineCheck = useCallback(() => {
    setExtraLineEnabled((prevState) => !prevState);
  }, []);
  const handleManualEcreditCheck = useCallback(() => {
    setManualEcreditEnabled((prevState) => !prevState);
  }, []);
  const [values, setValues] = useState<any>({});
  const handleChangeValue = useCallback(
    (value: string, key: string) => {
      const tempValues = { ...values };
      tempValues[key] = value;
      setValues(tempValues);
    },
    [values]
  );

  useEffect(() => {
    const tempValues = {
      shippingCost: product?.order?.shippingCost?.total,
      extraLineAmount: 0,
      manualEcreditValue: "0",
    };
    setValues(tempValues);
  }, [product]);

  return {
    selectedItems,
    handleSelectItem,
    shippingEnabled,
    handleShippingEnabledCheck,
    values,
    handleChangeValue,
    extraLineEnabled,
    handleEnableExtraLineCheck,
    manualEcreditEnabled,
    handleManualEcreditCheck,
  };
};

export default useReturnModalContent;
