export function getFormattedDate(date: string) {
  try {
    return Intl.DateTimeFormat(navigator?.language, {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(new Date(date));
  } catch (error) {
    return "Invalid time";
  }
}

export function getFormattedDateWithTime(date: string) {
  try {
    return Intl.DateTimeFormat(navigator?.language, {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(new Date(date));
  } catch (error) {
    return "Invalid time";
  }
}

export const getObjectFromDeepKeyValues = (
  deepKeysValues: { [index: string]: any } = {}
) => {
  const newObject = {};
  Object.keys(deepKeysValues).forEach((deepKey) => {
    const keys = deepKey.split(".");
    const lastKey: any = keys.pop();
    let value: { [index: string]: any } = newObject;
    for (const key of keys) {
      if (!value[key]) value[key] = {};

      value = value[key];
    }
    value[lastKey] = deepKeysValues[deepKey];
  });
  return newObject;
};

export const getValueFromDeepKey = (obj: any, deepKey: string) => {
  let value = { ...obj };
  const keys = deepKey.split(".");
  for (const key of keys) {
    if (value?.[key]) {
      value = value?.[key];
    }
  }
  return value;
};

export const getDeepKeys = (keysArray: any, obj: any, value: any) => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object") {
        getDeepKeys(keysArray, obj[key], [...value, key]);
      } else {
        keysArray.push([...value, key].join("."));
      }
    });
  }
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const roundTo = (n: number, digits = 2) => {
  if (digits === undefined) {
    digits = 0;
  }
  const multiplicator = 10 ** digits;
  n = parseFloat((n * multiplicator).toFixed(11));
  const test = Math.round(n) / multiplicator;
  return +test.toFixed(digits);
};

export function generateBD(id: string) {
  return id && ` BD-${`${id}`.padStart(5, "0")}`;
}
export function generateBDR(id: string) {
  return id && ` BDR-${`${id}`.padStart(5, "0")}`;
}

export const mapReturnData = (returnItems: any) => {
  const pendingStatuses = [0];
  const processingStatuses = [1, 2];
  const completedStatuses = [3, 4, 5, 7, 9];
  const refundableStatuses = [3, 9];
  let isRefundable = false;
  let creditNoteId = null;
  let status = "pending";
  const { returnCode, pdfLink } =
    returnItems?.find((item: any) => item.returnCode && item.pdfLink) || {};

  if (returnItems.every((item: any) => pendingStatuses.includes(item.status))) {
    status = "pending";
  }

  if (
    returnItems.every((item: any) => processingStatuses.includes(item.status))
  ) {
    status = "processing";
  }

  if (
    returnItems.every(
      (item: any) =>
        completedStatuses.includes(item.status) || item?.creditNote?._id
    )
  ) {
    status = "completed";
    creditNoteId = returnItems?.find((item: any) => item?.creditNote?._id)
      ?.creditNote?._id;
    if (
      returnItems.some(
        (item: any) =>
          refundableStatuses.includes(item.status) && !item?.creditNote?._id
      )
    ) {
      isRefundable = true;
    }
  }

  return { status, isRefundable, creditNoteId, pdfLink, returnCode };
};

export function getQueryString(params: any) {
  let queryString = "";
  const queryParams: any = [];
  if (params) {
    Object.keys(params).forEach((key) => {
      queryParams.push(
        `${key}=${
          typeof params[key] !== "string"
            ? JSON.stringify(params[key])
            : params[key]
        }`
      );
    });

    queryString = `?${queryParams.join("&")}`;
  }

  return queryString;
}

export function getFileAddress(url: string) {
  return `${process.env.REACT_APP_PHOTO_ADDRESS}/${url}`;
}

export function generateString(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function getFormattedAmount(number: number) {
  return Number(number).toLocaleString("it-IT", {
    style: "currency",
    currency: "EUR",
  });
}
