import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import useEcreditModalContent from "./index.hooks";
import style from "./index.style";

type EcreditModalContentProps = {
  user: any;
  closeModal: () => void;
};

const EcreditModalContent = memo(
  ({ user, closeModal }: EcreditModalContentProps) => {
    const {
      handleChangeValue,
      editingUser,
      addEcredit,
      addCashback,
      handleAddCashback,
    } = useEcreditModalContent({ user, closeModal });
    return (
      <Box sx={style.modal}>
        <Grid container spacing={3}>
          {editingUser && (
            <>
              <IconButton sx={style.modal.closeButton} onClick={closeModal}>
                <CloseIcon />
              </IconButton>
              <Grid item xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Adding ecredit to user {user?.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Amount"
                  type="number"
                  value={editingUser?.amount}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "amount")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Invoice number"
                  value={editingUser?.fullNumber}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "fullNumber")
                  }
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  value={editingUser?.description}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "description")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addCashback}
                      onChange={handleAddCashback}
                    />
                  }
                  label="Add cashback"
                />
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" size="large" onClick={addEcredit}>
                  Create ecredit
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  }
);

export default EcreditModalContent;
