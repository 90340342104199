import ENDPOINTS from "constants/endpoints";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import request from "redux/slices/ajax/request";
import { useDebounce } from "utils/useDebounce";
import { useSelector } from "react-redux";
import { getCountries } from "../../redux/slices/platform-data/platform-data.selectors";

const initialState = {
  status: "",
  role: "",
  subscriptionStartDate: null,
  subscriptionEndDate: null,
  catalogNumber: "",
  country: "",
  adhoc: "",
  service: "",
  siteName: "",
};

const useUserManagement = () => {
  const [page, setPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [users, setUsers] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const debouncedSearchTextValue = useDebounce(searchValue, 500);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalOpenEcredit, setModalOpenEcredit] = useState<boolean>(false);
  const [editingUser, setEditingUser] = useState<any>(null);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const handleOpenModal = useCallback((user?: any) => {
    setModalOpen((prevState) => !prevState);
    setEditingUser(user);
  }, []);
  const handleOpenModalEcredit = useCallback((user?: any) => {
    setModalOpenEcredit((prevState) => !prevState);
    setEditingUser(user);
  }, []);
  const [shouldFilter, setShouldFilter] = useState(true);
  const countries = useSelector(getCountries);

  const filtersReducer = (state: any, action: any) => {
    switch (action.type) {
      case "status":
        return { ...state, status: action.payload };
      case "siteName":
        return { ...state, siteName: action.payload };
      case "role":
        return { ...state, role: action.payload };
      case "subscriptionDate":
        return {
          ...state,
          subscriptionStartDate: action.payload[0],
          subscriptionEndDate: action.payload[1],
        };
      case "catalogNumber":
        return { ...state, catalogNumber: action.payload };
      case "country":
        return { ...state, country: action.payload };
      case "adhoc":
        return { ...state, adhoc: action.payload };
      case "service":
        return { ...state, service: action.payload };
      case "reset":
        return initialState;
      default:
        return { [action.type]: action.payload };
    }
  };
  const [filters, dispatchFilters] = useReducer(filtersReducer, initialState);

  const getUsers = useCallback(async () => {
    let response: any = {};
    const $or: any = [];
    const q: any = {};
    if (debouncedSearchTextValue) {
      q.email = { $regex: debouncedSearchTextValue };
    }
    if ($or.length) {
      q.$or = $or;
    }
    if (filters.status) {
      q.status = filters.status;
    }
    if (filters.role) {
      q["roles.name"] = filters.role;
    }
    if (filters.catalogNumber) {
      q.catalogNumber = Number(filters.catalogNumber);
    }
    if (filters.country) {
      q.countryCode = filters.country;
    }
    if (filters.adhoc) {
      q.adhoc = filters.adhoc;
    }
    if (filters.service) {
      q["catalogs.connections.component"] = filters.service;
    }
    if (filters.siteName) {
      q["catalogs.connections.shopify.shop"] = filters.siteName;
    }
    if (filters?.subscriptionStartDate && filters?.subscriptionEndDate) {
      q.subscriptionDate = {
        $gt: filters.subscriptionStartDate.toISOString(),
        $lt: filters.subscriptionEndDate.toISOString(),
      };
    }
    response = await request({
      path:
        filters?.service || filters.siteName
          ? ENDPOINTS.getUsersConnectionService
          : ENDPOINTS.getUsers,
      query: {
        pageNumber: page + 1,
        pageSize,
        sortings: { subscriptionDate: -1 },
        q,
        populate: {},
        p: {},
      },
    });
    setUsers(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [
    debouncedSearchTextValue,
    filters.adhoc,
    filters.catalogNumber,
    filters.country,
    filters.role,
    filters.service,
    filters.siteName,
    filters.status,
    filters.subscriptionEndDate,
    filters.subscriptionStartDate,
    page,
    pageSize,
  ]);

  useEffect(() => {
    if (shouldFilter) {
      getUsers().then();
    }
  }, [getUsers, shouldFilter]);
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const handleFiltersModal = useCallback(() => {
    setFiltersModalOpen((prev) => !prev);
  }, []);

  const resetFilters = useCallback(() => {
    dispatchFilters({ type: "reset" });
  }, []);

  const getSelectCountries = useMemo(
    () =>
      countries?.map((country: any) => ({
        countryName: country?.country,
        countryCode: country?.countrycode,
      })),
    [countries]
  );

  return {
    page,
    searchValue,
    users,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    handleChangeSearchValue,
    modalOpen,
    handleOpenModal,
    editingUser,
    filtersModalOpen,
    handleFiltersModal,
    filters,
    setShouldFilter,
    resetFilters,
    dispatchFilters,
    getUsers,
    getSelectCountries,
    modalOpenEcredit,
    handleOpenModalEcredit,
  };
};

export default useUserManagement;
