import React, { memo } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import PhotoAlbum from "react-photo-album";
import usePhotoDownload from "./index.hooks";
import style from "./index.style";

type TranslationsProps = {};

const PhotoDownload = memo(({}: TranslationsProps) => {
  const {
    renderCategories,
    getNextCategory,
    checkHasMore,
    selectedBrand,
    onChangeBrandsSelect,
    getBrandsSelectOptions,
    getGalleryImages,
    openImage,
    getTotalPages,
    getCurrentPage,
    changePage,
    uploadImage,
    modalOpen,
    handleCloseModal,
    selectedImg,
    deleteImage,
  } = usePhotoDownload();

  return (
    <>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={style.modal}>
          <Box>
            <img
              style={{ height: "100%", width: "100%" }}
              src={selectedImg?.m}
              alt=""
            />
          </Box>
          <Button
            variant="contained"
            size="large"
            color="error"
            onClick={deleteImage}
          >
            Delete
          </Button>
        </Box>
      </Modal>
      <Box sx={style.wrapper}>
        <FormControl fullWidth>
          <InputLabel id="brand-label">Brand</InputLabel>
          <Select
            labelId="select-brand"
            id="select-brand"
            label="Brand"
            value={selectedBrand}
            onChange={(ev) => onChangeBrandsSelect(ev.target.value)}
          >
            {getBrandsSelectOptions.map((brand: any) => (
              <MenuItem key={brand.label} value={brand.value}>
                {brand.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <InfiniteScroll
        dataLength={renderCategories.length}
        next={getNextCategory}
        hasMore={checkHasMore}
        loader={null}
      >
        <Box className="pd-container">
          {renderCategories?.map((c: any) => (
            <Box sx={style.wrapper}>
              <Box key={`${c?.brand}`} sx={style.content}>
                <Box sx={style.imageContent}>
                  <Box sx={style.brandTitleContainer}>
                    <span>{c?.brand}</span>
                    <Button variant="outlined" component="label" sx={{ ml: 2 }}>
                      Upload
                      <input
                        type="file"
                        multiple
                        onChange={(e) => {
                          uploadImage(e, c?.brand);
                        }}
                        accept="image/*, video/*"
                        id="uploadImage"
                        autoComplete="off"
                        value=""
                        hidden
                      />
                    </Button>
                  </Box>
                  <PhotoAlbum
                    layout="rows"
                    photos={getGalleryImages(c)}
                    onClick={(e, image) => openImage(image, c?.brand)}
                  />
                  <Pagination
                    count={getTotalPages(c.brand)}
                    page={getCurrentPage(c.brand)}
                    onChange={(event, page) => changePage(page, c.brand)}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </InfiniteScroll>
    </>
  );
});

export default PhotoDownload;
