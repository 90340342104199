import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Modal,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import { Visibility } from "@mui/icons-material";
import style from "./index.style";
import useCatalogs from "./index.hooks";
import EditModalContent from "./EditModalContent";

type CatalogsProps = {};

const Catalogs = memo(({}: CatalogsProps) => {
  const {
    page,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    searchValue,
    handleChangeSearchValue,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    editingItem,
  } = useCatalogs();

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditModalContent
          item={editingItem}
          closeModal={handleCloseModal}
          getItems={getItems}
        />
      </Modal>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search"
              value={searchValue}
              onChange={handleChangeSearchValue}
              size="medium"
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Catalog</TableCell>
                <TableCell>Connection</TableCell>
                <TableCell>Markup</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item) => (
                <TableRow key={item?._id}>
                  <TableCell>{item?.user?.email}</TableCell>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>
                    {item?.connections?.[0]?.component || "---"}
                  </TableCell>
                  <TableCell>{item?.markup}%</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleOpenModal(item)}
                      >
                        <Visibility sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default Catalogs;
