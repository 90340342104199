import globalStyle from "index.style";

const useStyles = {
  header: {
    backgroundColor: "white",
    boxShadow: "none",
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "rgba(0,0,0,0.12)",
    borderBottomWidth: "thin",
    flexDirection: "row",
    justifyContent: "space-between",
  },
} as const;

export default { ...globalStyle, ...useStyles };
