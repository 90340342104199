import React, { memo } from "react";
import CustomTabs from "components/CustomTabs";
import { Box } from "@mui/system";
import useEditModalContent from "./index.hooks";
import EditUser from "./EditUser";
import EditReferral from "./EditReferral";
import style from "./EditUser/index.style";

type EditModalContentProps = {
  user: any;
  closeModal: () => void;
  getUsers: () => void;
};

const EditModalContent = memo(
  ({ user, closeModal, getUsers }: EditModalContentProps) => {
    const { tabIndex, handleChange } = useEditModalContent();

    return (
      <Box sx={style.modal}>
        <CustomTabs
          value={tabIndex}
          handleChange={handleChange}
          tabs={["Edit user", "Edit Referral"]}
          items={[
            <EditUser
              user={user}
              closeModal={closeModal}
              getUsers={getUsers}
            />,
            <EditReferral
              user={user}
              closeModal={closeModal}
              getUsers={getUsers}
            />,
          ]}
        />
      </Box>
    );
  }
);

export default EditModalContent;
