import { useCallback, useEffect, useState } from "react";
import request, { HttpMethod } from "../../../redux/slices/ajax/request";
import ENDPOINTS from "../../../constants/endpoints";

type useOrderActionsProps = {
  order: any;
  updateOrders: () => void;
  closeParentModal: () => void;
};

export enum OrderAction {
  CONFIRM = "confirm",
  CANCEL = "cancel",
  RETURN = "return",
  EDIT = "edit",
  SEND_WITH_COD = "send_with_cod",
}

const useOrderActions = ({
  order,
  updateOrders,
  closeParentModal,
}: useOrderActionsProps) => {
  const { status, paid, sent } = order;
  const isCancellable =
    (["0"].includes(status) ||
      (status === "5" && order?.shippingCost?.carrierCOD)) &&
    !paid;
  const isReturnableByAdmin =
    ["2", "3", "3001", "3002"].includes(status) ||
    paid ||
    (status === "5" && !order?.shippingCost?.carrierCOD);
  const canForceOrder =
    (paid && !order?.ids?.source) || (!sent && order?.source === "miravia");
  const isEditable =
    (["5"].includes(status) && !paid && order?.shippingCost?.carrierCOD) ||
    (status === "3001" &&
      order?.shippingCost?.carrierCOD &&
      order?.shippypro?.[0]?.ErrorMessage);
  const onlyAddressEditable =
    status === "3001" &&
    order?.shippingCost?.carrierCOD &&
    order?.shippypro?.[0]?.ErrorMessage;
  const canSendWithCOD =
    ["5"].includes(status) &&
    !paid &&
    !sent &&
    !order?.ids?.source &&
    order?.shippingCost?.carrierCOD;
  const hasInvoice = !!paid;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalValues, setModalValues] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = useCallback((_, newValue: number) => {
    setTabIndex(newValue);
  }, []);
  const [editOrder, setEditOrder] = useState<any>(null);

  useEffect(() => {
    (async () => {
      if (order?.items && editModalOpen) {
        const tempOrder = { ...order };
        const items = tempOrder?.items || [];
        let response: any;
        await Promise.allSettled(
          items.map(async (item: any, index: number) => {
            response = await request({
              path: `${ENDPOINTS.products}/${item.id || item._id}`,
              method: HttpMethod.GET,
            });
            items[index].product = response.data;
          })
        );
        tempOrder.items.forEach((item: any, index: number) => {
          tempOrder.items[index] = items[index];
        });
        setEditOrder(tempOrder);
      }
    })();
  }, [editModalOpen, order]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setModalValues(null);
    closeParentModal();
  }, [closeParentModal]);

  const handleCloseEditModal = useCallback(() => {
    setEditModalOpen(false);
    setEditOrder({ ...order });
  }, [order]);

  const handleSaveEdit = useCallback(async () => {
    await request({
      path: `${ENDPOINTS.order}/${editOrder?._id}`,
      method: HttpMethod.PUT,
      body: {
        ...editOrder,
      },
    });
    updateOrders();
    handleCloseEditModal();
  }, [editOrder, handleCloseEditModal, updateOrders]);

  const cancelOrder = useCallback(async () => {
    await request({
      path: `${ENDPOINTS.orders}/${order._id}`,
      method: HttpMethod.PUT,
      body: {
        status: "2000",
      },
    });
    updateOrders();
    handleCloseModal();
  }, [handleCloseModal, order._id, updateOrders]);

  const returnOrder = useCallback(async () => {
    await request({
      path: `${ENDPOINTS.returnOrder}/${order._id}`,
      method: HttpMethod.POST,
      body: {
        orderId: order?._id,
      },
    });
    updateOrders();
    handleCloseModal();
  }, [handleCloseModal, order._id, updateOrders]);

  const sendWithCOD = useCallback(async () => {
    const res = await request({
      path: `${ENDPOINTS.order}/confirm/cod?orderId=${order?._id}`,
      method: HttpMethod.PUT,
    });

    updateOrders();
    handleCloseModal();
  }, [handleCloseModal, order._id, updateOrders]);

  const confirmOrder = useCallback(async () => {
    const orderBody = {
      ...order,
      orderId: order._id,
      singleRecipient: order.isRecipient !== false,
    };
    if (order?.father && !order?.virtual && !order.isRecipient) {
      orderBody.orderId = order?.father;
      orderBody.singleRecipient = !orderBody.singleRecipient;
    }
    await request({
      path: ENDPOINTS.confirmOrder,
      method: HttpMethod.POST,
      body: [orderBody],
    });
    updateOrders();
    handleCloseModal();
  }, [handleCloseModal, order, updateOrders]);

  const downloadInvoice = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.downloadOrderInvoice,
      method: HttpMethod.GET,
      additionalHeaders: {
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      responseType: "blob",
      pathParams: {
        orderId: order._id,
      },
    });
    const filename =
      response?.headers?.["content-disposition"]
        ?.split("filename=")?.[1]
        .replace(/"/g, "") || "Bill.pdf";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    closeParentModal();
    handleCloseModal();
  }, [closeParentModal, handleCloseModal, order._id]);

  const handleOpenModal = (type?: string) => {
    switch (type) {
      case OrderAction.CONFIRM:
        setModalValues({
          label: "Are you sure you want to confirm the order?",
          function: confirmOrder,
        });
        setModalOpen(true);
        break;
      case OrderAction.CANCEL:
        setModalValues({
          label: "Are you sure you want to cancel the order?",
          function: cancelOrder,
        });
        setModalOpen(true);
        break;
      case OrderAction.RETURN:
        setModalValues({
          label: "Are you sure you want to return the order?",
          function: returnOrder,
        });
        setModalOpen(true);
        break;
      case OrderAction.SEND_WITH_COD:
        setModalValues({
          label: `Are you sure you want to send with cod?${
            !order.user.username.includes("customer_")
              ? `You should check if user has courier on Brandsdistribution`
              : ""
          }`,
          function: sendWithCOD,
        });
        setModalOpen(true);
        break;
      case OrderAction.EDIT:
        setEditModalOpen(true);
        break;
      default:
        console.log("ERROR");
    }
  };

  return {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    isCancellable,
    isReturnableByAdmin,
    canForceOrder,
    hasInvoice,
    downloadInvoice,
    modalValues,
    isEditable,
    onlyAddressEditable,
    editModalOpen,
    handleCloseEditModal,
    tabIndex,
    handleChange,
    editOrder,
    setEditOrder,
    handleSaveEdit,
    canSendWithCOD,
  };
};

export default useOrderActions;
