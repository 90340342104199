import { useCallback, useEffect, useMemo, useState } from "react";
import request, { HttpMethod } from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { FORCED_PAGE_MAPPING, LANGUAGES } from "constants/contants";
import {
  capitalizeFirstLetter,
  getDeepKeys,
  getObjectFromDeepKeyValues,
  getValueFromDeepKey,
} from "utils";

const useTranslations = () => {
  const [langsTranslations, setLangsTranslations] = useState<any>({});
  const [langsIdsTranslations, setLangsIdsTranslations] = useState<any>({});
  const [translationTree, setTranslationTree] = useState([""]);
  const [platformPages, setPlatformPages] = useState([]);
  const locales: string[] = useMemo(() => Object.keys(LANGUAGES), []);
  const [dashboardTranslations, setDashboardTranslations] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState<any>({});
  const getPages = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.platformPages,
    });
    setPlatformPages(response?.data);
  }, []);
  const defaultTranslations = useMemo(
    () => dashboardTranslations || langsTranslations[locales[0]],
    [dashboardTranslations, langsTranslations, locales]
  );

  const getDefaultTranslations = useCallback(async () => {
    let response: any = {};
    try {
      response = await request({
        absolutePath: true,
        path: `${process.env.REACT_APP_DASH_URL}/api/contents`,
      });
      setDashboardTranslations(response?.data);
    } catch (e) {
      console.log("e", e);
    }
  }, []);

  const refreshLanguages = useCallback(async () => {
    locales.map(async (locale) => {
      let response: any = {};
      response = await request({
        path: `${ENDPOINTS.getLocaleTranslations}/${locale}`,
      });
      const contentsLang: any[] = response?.data;
      let translations = {};
      let idsTranslations = {};
      const contentsLangObj: { [index: string]: any } = {};
      const idsLangObj: { [index: string]: any } = {};
      contentsLang.forEach((contentLang) => {
        contentsLangObj[contentLang.code] = contentLang.content;
        idsLangObj[contentLang.code] = contentLang._id;
      });
      translations = getObjectFromDeepKeyValues(contentsLangObj);
      idsTranslations = getObjectFromDeepKeyValues(idsLangObj);
      setLangsTranslations((prev: any) => ({
        ...prev,
        [locale]: translations,
      }));
      setLangsIdsTranslations((prev: any) => ({
        ...prev,
        [locale]: idsTranslations,
      }));
      setIsLoading(false);
    });
  }, [locales]);

  useEffect(() => {
    refreshLanguages().then(() => {
      getPages().then(() => {
        getDefaultTranslations().then();
      });
    });
  }, [getDefaultTranslations, getPages, refreshLanguages]);

  const notChangedTranslations = useMemo(() => {
    const platformTranslationsKeys: any[] = [];
    getDeepKeys(platformTranslationsKeys, defaultTranslations, []);
    const notChangedTranslations: any[] = [];

    const locales = Object.keys(LANGUAGES);
    for (const platformTranslationsKey of platformTranslationsKeys) {
      for (const locale of locales) {
        if (
          locale !== "en_US" &&
          !notChangedTranslations.includes(platformTranslationsKey)
        ) {
          const mappedTranslationsDefault = getValueFromDeepKey(
            defaultTranslations,
            platformTranslationsKey
          );
          const mappedTranslationsLanguage = getValueFromDeepKey(
            langsTranslations[locale],
            platformTranslationsKey
          );
          if (
            mappedTranslationsDefault === mappedTranslationsLanguage ||
            typeof mappedTranslationsLanguage === "object"
          ) {
            const keys = platformTranslationsKey.split(".");
            for (let i = 0; i < keys.length; i++) {
              const keyGeneration = keys.slice(0, i + 1).join(".");
              if (!notChangedTranslations.includes(keyGeneration)) {
                notChangedTranslations.push(keyGeneration);
              }
            }
          }
        }
      }
    }

    return notChangedTranslations;
  }, [defaultTranslations, langsTranslations]);

  const handleSaveTranslation = useCallback(async () => {
    let pageId: any = null;
    let pageKey = "";
    if (translationTree.length > 3) {
      pageKey = `${translationTree[1]}.${translationTree[2]}`;
    } else {
      // eslint-disable-next-line prefer-destructuring
      pageKey = translationTree[1];
    }
    const forcedValue = getValueFromDeepKey(FORCED_PAGE_MAPPING, pageKey);
    if (typeof forcedValue === "string") pageKey = forcedValue;
    const platformPage: any = platformPages.find(
      (page: any) => page.code === pageKey
    );
    if (!platformPage) {
      const pageName = pageKey
        .split(".")
        .map((key) => capitalizeFirstLetter(key))
        .join(" ");
      let response: any = {};
      response = await request({
        path: ENDPOINTS.platformPages,
        method: HttpMethod.POST,
        body: {
          code: pageKey,
          name: pageName,
        },
      });
      pageId = response?.data?._id;
      await getPages();
    } else {
      pageId = platformPage._id;
    }
    Object.keys(LANGUAGES)?.map(async (language) => {
      const langTranlationTree = [...translationTree];
      langTranlationTree[0] = language;
      const _id = getValueFromDeepKey(
        langsIdsTranslations,
        langTranlationTree.join(".")
      );

      if (typeof _id !== "object") {
        let response: any = {};
        response = await request({
          path: `${ENDPOINTS.contentTranslation}/${_id}`,
          method: HttpMethod.PUT,
          body: {
            content: values[language],
            page: {
              _id: pageId,
              code: pageKey,
            },
          },
        });
        console.log("response", response);
      } else {
        const missingTranslation = langTranlationTree
          .slice(1, langTranlationTree.length)
          .join(".");
        let response: any = {};
        response = await request({
          path: ENDPOINTS.contentTranslation,
          method: HttpMethod.POST,
          body: {
            type: "label",
            code: missingTranslation,
            lang: language,
            content: values[language],
            page: {
              _id: pageId,
              code: pageKey,
            },
          },
        });
        console.log("response", response);
      }
    });
    await refreshLanguages();
    setValues({});
  }, [
    getPages,
    langsIdsTranslations,
    platformPages,
    refreshLanguages,
    translationTree,
    values,
  ]);

  const handleChangeValue = useCallback(
    (value: string, lang: string) => {
      const tempValues = { ...values };
      tempValues[lang] = value;
      setValues(tempValues);
    },
    [values]
  );

  const [translationsFilter, setTranslationsFilter] = useState("");

  const filteredTranslations = useMemo(() => {
    const platformTranslationsKeys: any = [];
    getDeepKeys(platformTranslationsKeys, defaultTranslations, []);
    const filteredTranslations: any = [];

    const locales = Object.keys(LANGUAGES);
    for (const platformTranslationsKey of platformTranslationsKeys) {
      for (const locale of locales) {
        if (!filteredTranslations.includes(platformTranslationsKey)) {
          const mappedTranslationsDefault = getValueFromDeepKey(
            defaultTranslations,
            platformTranslationsKey
          );
          const mappedTranslationsLanguage = getValueFromDeepKey(
            langsTranslations[locale],
            platformTranslationsKey
          );
          if (
            platformTranslationsKey
              .toLowerCase()
              .includes(translationsFilter.toLowerCase()) ||
            (typeof mappedTranslationsDefault !== "object" &&
              mappedTranslationsDefault
                .toLowerCase()
                .includes(translationsFilter.toLowerCase())) ||
            (typeof mappedTranslationsLanguage !== "object" &&
              mappedTranslationsLanguage
                .toLowerCase()
                .includes(translationsFilter.toLowerCase()))
          ) {
            const keys = platformTranslationsKey.split(".");
            for (let i = 0; i < keys.length; i++) {
              const keyGeneration = keys.slice(0, i + 1).join(".");
              if (!filteredTranslations.includes(keyGeneration)) {
                filteredTranslations.push(keyGeneration);
              }
            }
          }
        }
      }
    }

    return filteredTranslations;
  }, [defaultTranslations, langsTranslations, translationsFilter]);
  return {
    defaultTranslations,
    translationTree,
    notChangedTranslations,
    setTranslationTree,
    langsTranslations,
    LANGUAGES,
    isLoading,
    handleSaveTranslation,
    values,
    handleChangeValue,
    filteredTranslations,
    setTranslationsFilter,
    translationsFilter,
  };
};

export default useTranslations;
