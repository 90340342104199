import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import request, { HttpMethod } from "../../../../redux/slices/ajax/request";
import ENDPOINTS from "../../../../constants/endpoints";

type ShippingProps = {
  order: any;
  setOrder: any;
};

const Shipping = memo(({ order, setOrder }: ShippingProps) => {
  const [carriers, setCarriers] = useState<any>(null);
  const handleChangeValue = useCallback(
    (value: any) => {
      const tempOrder = { ...order };
      tempOrder.carrierId = value;
      setOrder(tempOrder);
    },
    [order, setOrder]
  );
  const orderTotal = useMemo(
    () =>
      order?.items?.reduce(
        (a: any, b: any) => a + b.bestTaxable * b.quantity,
        0
      ),
    [order?.items]
  );
  useEffect(() => {
    (async () => {
      let totalWeight = 0;
      order?.items?.forEach((product: any) => {
        totalWeight += product.product.weight * product.quantity;
      });
      if (order?.recipient?.address?.countrycode) {
        let response: any = {};
        response = await request({
          path: `${ENDPOINTS.carrierFee}/country/${order?.recipient?.address?.countrycode}/to/${totalWeight}`,
          method: HttpMethod.GET,
        });
        const { data } = response;
        if (data && Array.isArray(data)) {
          setCarriers(
            data.sort((a: any, b: any) => {
              if (a?.carrierData?.[0]?.name > b?.carrierData?.[0]?.name)
                return 1;
              if (a?.carrierData?.[0]?.name < b?.carrierData?.[0]?.name)
                return -1;
              return 0;
            })
          );
        }
      }
    })();
  }, [order?.items, order?.recipient?.address?.countrycode]);

  return (
    <Box>
      {carriers?.length ? (
        <FormControl>
          <FormLabel id="carriers-radio-group">Select carrier</FormLabel>
          <RadioGroup
            aria-labelledby="carriers-radio-group"
            row={false}
            name="carriers-radio-group"
            value={order?.carrierId}
            onChange={(ev) => handleChangeValue(ev.target.value)}
          >
            {carriers?.map((carrier: any) => (
              <FormControlLabel
                value={carrier?.carrierData?.[0]?.refId.toString()}
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    <Typography>{carrier.carrierData?.[0]?.name}</Typography>
                    {carrier.freeShippingFrom &&
                    orderTotal > carrier.freeShippingFrom ? (
                      <Typography>Free shipping</Typography>
                    ) : (
                      <Typography>{carrier.fee} €</Typography>
                    )}
                    <Typography>
                      ({carrier?.carrierData?.[0].daysLiteral})
                    </Typography>
                  </Box>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      ) : (
        <Box>No carriers</Box>
      )}
    </Box>
  );
});

export default Shipping;
