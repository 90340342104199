import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

import useVouchers from "./index.hooks";
import style from "./index.style";
import EditModalContent from "./EditModalContent";

export enum VOUCHER_TYPE {
  PRODUCT_TAXABLE = "product_taxable", // disabilitato
  ORDER_TAXABLE = "order_taxable",
}

export const VOUCHER_TYPES = [
  {
    value: VOUCHER_TYPE.ORDER_TAXABLE,
    label: "Order taxable",
    disabled: false,
  },
  {
    value: VOUCHER_TYPE.PRODUCT_TAXABLE,
    label: "Product taxable",
    disabled: true,
  },
];

export enum VOUCHER_VALUE_TYPE {
  FLAT = "flat", // disabilitato
  PERCENTAGE = "percentage",
}

export const VOUCHER_VALUE_TYPES = [
  {
    value: VOUCHER_VALUE_TYPE.PERCENTAGE,
    label: "Percentuale",
    disabled: false,
  },
  {
    value: VOUCHER_VALUE_TYPE.FLAT,
    label: "Flat",
    disabled: true,
  },
];

export enum VOUCHER_USABILITY {
  ONCE = "once",
  ONCE_PER_USER = "once_per_user",
  INDEFINITE = "indefinite",
}

export const VOUCHER_USABILITIES = [
  {
    value: VOUCHER_USABILITY.ONCE,
    label: "Una volta",
    disabled: false,
  },
  {
    value: VOUCHER_USABILITY.ONCE_PER_USER,
    label: "Una per utente",
    disabled: false,
  },
  {
    value: VOUCHER_USABILITY.INDEFINITE,
    label: "Infinito",
    disabled: false,
  },
];

export enum VOUCHER_USERS_TYPOLOGY {
  ALL = "all",
  NEW_BUYERS = "new_buyers",
}

export const VOUCHER_USERS_TYPOLOGIES = [
  {
    value: VOUCHER_USERS_TYPOLOGY.ALL,
    label: "Tutti",
    disabled: false,
  },
  {
    value: VOUCHER_USERS_TYPOLOGY.NEW_BUYERS,
    label: "Nuovi utenti",
    disabled: false,
  },
];

export enum VOUCHER_STATUS {
  ACTIVE = "active",
  CANCELED = "canceled",
  EXPIRED = "EXPIRED",
}

export const VOUCHER_STATUSES = [
  {
    value: VOUCHER_STATUS.ACTIVE,
    label: "Attivo",
    disabled: false,
  },
  {
    value: VOUCHER_STATUS.CANCELED,
    label: "Cancellato",
    disabled: false,
  },
  {
    value: VOUCHER_STATUS.EXPIRED,
    label: "Scaduto",
    disabled: false,
  },
];

type VouchersProps = {};

const Vouchers = memo(({}: VouchersProps) => {
  const {
    page,
    vouchers,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    modalOpen,
    handleOpenModal,
    editingVoucher,
    getVouchers,
    isEdit,
    handleCloseModal,
  } = useVouchers();

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditModalContent
          voucher={editingVoucher}
          closeModal={handleCloseModal}
          getVouchers={getVouchers}
          isEdit={isEdit}
        />
      </Modal>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 2 }}
      >
        <Button variant="contained" size="large" onClick={handleOpenModal}>
          <AddIcon />
          Create
        </Button>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Valore</TableCell>
                <TableCell>Tipo Valore</TableCell>
                <TableCell>Minimo d&apos;ordine</TableCell>
                <TableCell>Tipo di utilizzo</TableCell>
                <TableCell>Cumulabile</TableCell>
                <TableCell>Tipologia utenti</TableCell>
                <TableCell>Stato</TableCell>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vouchers?.map((voucher) => (
                <TableRow key={voucher?._id}>
                  <TableCell>{voucher?.code}</TableCell>
                  <TableCell>
                    {
                      VOUCHER_TYPES.find((v) => v?.value === voucher?.type)
                        ?.label
                    }
                  </TableCell>
                  <TableCell>{voucher?.value}</TableCell>
                  <TableCell>
                    {
                      VOUCHER_VALUE_TYPES.find(
                        (v) => v?.value === voucher?.value_type
                      )?.label
                    }
                  </TableCell>
                  <TableCell>{voucher?.orderTotal} €</TableCell>
                  <TableCell>
                    {
                      VOUCHER_USABILITIES.find(
                        (v) => v?.value === voucher?.usability
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {voucher?.usable_with_other_vouchers ? "Si" : "No"}
                  </TableCell>
                  <TableCell>
                    {
                      VOUCHER_USERS_TYPOLOGIES.find(
                        (v) => v?.value === voucher?.users_typology
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {
                      VOUCHER_STATUSES.find((v) => v?.value === voucher?.status)
                        ?.label
                    }
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleOpenModal(voucher, true)}
                    >
                      <EditIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default Vouchers;
