import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

import useCarrier from "./index.hooks";
import style from "./index.style";
import EditModalContent from "./EditModalContent";

type CarrierProps = {};

const Carrier = memo(({}: CarrierProps) => {
  const {
    page,
    carriers,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    modalOpen,
    handleOpenModal,
    editingCarrier,
    getCarriers,
    isEdit,
    handleCloseModal,
  } = useCarrier();

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditModalContent
          carrier={editingCarrier}
          closeModal={handleCloseModal}
          getCarriers={getCarriers}
          isEdit={isEdit}
        />
      </Modal>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 2 }}
      >
        <Button variant="contained" size="large" onClick={handleOpenModal}>
          <AddIcon />
          Create
        </Button>
      </Box>
      <Box sx={style.user.wrapper}>
        <TableContainer sx={style.table} component={Paper}>
          <Table aria-label="users-table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Logo</TableCell>
                <TableCell>Note</TableCell>
                <TableCell>Days literal</TableCell>
                <TableCell>Days</TableCell>
                <TableCell>Online</TableCell>
                <TableCell>Red ID</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carriers?.map((carrier) => (
                <TableRow key={carrier?._id}>
                  <TableCell>{carrier?.name}</TableCell>
                  <TableCell>{carrier?.logo}</TableCell>
                  <TableCell>{carrier?.note}</TableCell>
                  <TableCell>{carrier?.daysLiteral}</TableCell>
                  <TableCell>{carrier?.days}</TableCell>
                  <TableCell>{carrier?.online?.toString()}</TableCell>
                  <TableCell>{carrier?.refId}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleOpenModal(carrier, true)}
                    >
                      <EditIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalNumberOfElements}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </TableContainer>
      </Box>
    </>
  );
});

export default Carrier;
