import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useState } from "react";
import request from "redux/slices/ajax/request";
import { sub } from "date-fns";
import { useDebounce } from "../../../utils/useDebounce";

const usePaypal = () => {
  const [page, setPage] = useState<number>(0);
  const [logs, setLogs] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const today = new Date();
  const lastYear = sub(today, { years: 1 });
  const [startDate, setStartDate] = useState<Date>(lastYear);
  const [endDate, setEndDate] = useState<Date>(today);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTextValue = useDebounce(searchValue, 500);

  const getLogs = useCallback(async () => {
    let response: any = {};
    let $or;
    if (debouncedSearchTextValue?.length >= 1) {
      $or = {
        "res.metadata.userEmail": { $regex: debouncedSearchTextValue },
        "res.receipt_email": { $regex: debouncedSearchTextValue },
      };
    }
    response = await request({
      path: `${ENDPOINTS.paypalLogs}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q: {
          "orderData.orderType": "S",
          ...$or,
          resTime: { $gt: startDate.toISOString(), $lt: endDate.toISOString() },
        },
        sortings: {
          _id: -1,
        },
      },
    });
    setLogs(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [debouncedSearchTextValue, endDate, page, pageSize, startDate]);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    getLogs().then();
  }, [getLogs]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeDate = useCallback((newDate) => {
    setStartDate(newDate[0]);
    setEndDate(newDate[1]);
  }, []);

  return {
    page,
    searchValue,
    logs,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getLogs,
    handleChangeSearchValue,
    startDate,
    endDate,
    onChangeDate,
    today,
    lastYear,
  };
};

export default usePaypal;
