import ENDPOINTS from "constants/endpoints";
import React, { useCallback, useEffect, useState } from "react";
import request from "redux/slices/ajax/request";
import { useDebounce } from "../../utils/useDebounce";

const useInvoicesManagement = () => {
  const [page, setPage] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [totalNumberOfElements, setTotalNumberOfElements] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTextValue = useDebounce(searchValue, 500);
  const [modalOpen, setModalOpen] = useState<number>(-1);
  const handleCloseModal = useCallback(() => {
    setModalOpen(-1);
  }, []);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenModal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, index) => {
      setAnchorEl(event.currentTarget);
      setModalOpen(index);
    },
    []
  );

  const getItems = useCallback(async () => {
    let response: any = {};
    let $or;
    if (debouncedSearchTextValue?.length >= 1) {
      $or = [
        { fullNumber: { $regex: debouncedSearchTextValue } },
        { "paymentGW.transactionId": { $regex: debouncedSearchTextValue } },
        { "paymentGW.chargeId": { $regex: debouncedSearchTextValue } },
        { "user.email": { $regex: debouncedSearchTextValue } },
      ];
    }
    const userEmail = debouncedSearchTextValue;
    const populate = [{ path: "user", select: ["email"] }];
    response = await request({
      path: `${ENDPOINTS.invoices}/filter`,
      query: {
        pageNumber: page + 1,
        pageSize,
        q: {
          $or,
        },
        userEmail,
        populate,
        sortings: { date: -1 },
      },
    });
    setItems(response.data.items);
    setTotalNumberOfElements(response.data.totalNumberOfElements);
  }, [debouncedSearchTextValue, page, pageSize]);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    getItems().then();
  }, [getItems]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    page,
    searchValue,
    items,
    totalNumberOfElements,
    handleChangePage,
    pageSize,
    handlePageSizeChange,
    getItems,
    handleChangeSearchValue,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    anchorEl,
  };
};

export default useInvoicesManagement;
