import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import InfoTables from "./InfoTables";
import useStatisticsTable from "./index.hooks";

type StatisticsTableProps = {};

const StatisticsTable = memo(({}: StatisticsTableProps) => {
  const {
    data,
    startDate,
    endDate,
    onChangeDate,
    downloadCsv,
    downloadFounderPath,
  } = useStatisticsTable();

  return (
    <Box sx={{ gap: 4, display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="Start date"
            endText="End date"
            value={[startDate, endDate]}
            onChange={onChangeDate}
            inputFormat="dd-MM-yyyy"
            mask="__-__-____"
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
        <Box sx={{ gap: 2, display: "flex" }}>
          <Button variant="contained" onClick={downloadFounderPath}>
            Download founderpath
          </Button>
          <Button variant="contained" onClick={downloadCsv}>
            Download csv
          </Button>
        </Box>
      </Box>
      <InfoTables data={data} />
    </Box>
  );
});

export default StatisticsTable;
