import globalStyle from 'index.style';

const useStyles = {
  sidebar: {
    width: 250,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 250,
      zIndex:'1000',
      boxSizing: "border-box",
    },
    display: { xs: "none", sm: "block" },
    iconToggle: {
      position: "absolute",
      right: 5,
      top: 10,
      zIndex: 2,
    },
    icon: {
      color: "primary",
    },
  },
  image: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 100,
  },
} as const;

export default { ...globalStyle, ...useStyles };
