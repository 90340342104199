import {
  Box,
  Modal,
  Typography,
  Divider,
  Button,
  IconButton,
  Popover,
} from "@mui/material";
import React, { memo } from "react";
import { HighlightOff, Download, Repeat } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import useInvoiceActions, { InvoiceAction } from "./index.hooks";
import style from "./index.style";

type InvoiceActionsProps = {
  invoice: any;
  updateInvoices: () => void;
  closeParentModal: () => void;
  openPopover: any;
  closePopover: any;
  popoverOpen: any;
  anchorEl: any;
  index: number;
};

const InvoiceActions = memo(
  ({
    invoice,
    updateInvoices,
    closeParentModal,
    openPopover,
    closePopover,
    popoverOpen,
    anchorEl,
    index,
  }: InvoiceActionsProps) => {
    const {
      modalOpen,
      handleOpenModal,
      handleCloseModal,
      canRebuildInvoice,
      canGenerateCreditNote,
      downloadInvoice,
      modalValues,
    } = useInvoiceActions({ invoice, updateInvoices, closeParentModal });
    return (
      <Box>
        <IconButton
          aria-label="edit"
          size="small"
          onClick={(ev) => openPopover(ev, index)}
        >
          <EditIcon sx={{ fontSize: 20 }} />
        </IconButton>
        <Popover
          elevation={1}
          open={popoverOpen}
          onClose={closePopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style.modal}>
              <Typography sx={{ mb: 2 }}>{modalValues?.label}</Typography>
              <Box sx={style.buttons}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleCloseModal}
                  sx={{ marginRight: 4 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    closeParentModal();
                    modalValues?.function();
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
          <Box sx={style.container}>
            {canGenerateCreditNote && (
              <>
                <Box
                  sx={style.row}
                  onClick={() => handleOpenModal(InvoiceAction.GENERATE)}
                >
                  <HighlightOff sx={style.icon} />{" "}
                  <Typography>
                    {invoice?.isInvoice
                      ? "Generate credit note"
                      : "Generate negative receipt"}{" "}
                  </Typography>
                </Box>
                <Divider sx={style.divider} />
              </>
            )}
            {canRebuildInvoice && (
              <>
                <Box
                  sx={style.row}
                  onClick={() => handleOpenModal(InvoiceAction.REBUILD)}
                >
                  <Repeat sx={style.icon} />{" "}
                  <Typography>Rebuild receipt as invoice</Typography>
                </Box>
                <Divider sx={style.divider} />
              </>
            )}
            <Box sx={style.row} onClick={downloadInvoice}>
              <Download sx={style.icon} />{" "}
              <Typography>Download invoice</Typography>
            </Box>
          </Box>
        </Popover>
      </Box>
    );
  }
);

export default InvoiceActions;
