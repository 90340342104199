import { useCallback, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import request from "redux/slices/ajax/request";
import ENDPOINTS from "constants/endpoints";
import { format } from "date-fns";

const useBills = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [invoiceClass, setInvoiceClass] = useState<string>("C");
  const [invoiceType, setInvoiceType] = useState<string>("P");
  const [creditNote, setCreditNote] = useState(false);

  const invoiceTypes = useMemo(
    () => [
      {
        label: "Prodotti",
        value: "P",
      },
      {
        label: "Servizi",
        value: "S",
      },
    ],
    []
  );

  const invoiceClasses = useMemo(
    () => [
      {
        label: "Corrispettivi",
        value: "C",
      },
      {
        label: "Fatture",
        value: "F",
      },
    ],
    []
  );
  const onChangeDate = useCallback((newDate) => {
    setStartDate(newDate[0]);
    setEndDate(newDate[1]);
  }, []);

  const handleChangeInvoiceType = useCallback((event: SelectChangeEvent) => {
    setInvoiceType(event.target.value as string);
  }, []);
  const handleChangeInvoiceClass = useCallback((event: SelectChangeEvent) => {
    setInvoiceClass(event.target.value as string);
  }, []);
  const onSubmit = useCallback(async () => {
    let response: any = {};
    response = await request({
      path: ENDPOINTS.downloadBills,
      query: {
        startTime: format(startDate, "yyyy-MM-dd"),
        endTime: format(endDate, "yyyy-MM-dd"),
      },
      pathParams: {
        invoiceType,
        invoiceClass,
        creditNote,
      },
      responseType: "blob",
    });
    const filename =
      response?.headers?.["content-disposition"]
        .split("filename=")?.[1]
        .replace(/"/g, "") || "Bills.xlsx";
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }, [creditNote, endDate, invoiceClass, invoiceType, startDate]);

  const handleCreditNoteCheck = useCallback(() => {
    setCreditNote((prevState) => !prevState);
  }, []);

  return {
    invoiceTypes,
    invoiceClasses,
    startDate,
    endDate,
    onChangeDate,
    handleChangeInvoiceType,
    handleChangeInvoiceClass,
    invoiceType,
    invoiceClass,
    onSubmit,
    handleCreditNoteCheck,
    creditNote,
  };
};

export default useBills;
