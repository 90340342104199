import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import useEditModalContent from "./index.hooks";
import style from "./index.style";
import {
  VOUCHER_STATUSES,
  VOUCHER_TYPES,
  VOUCHER_USABILITIES,
  VOUCHER_USERS_TYPOLOGIES,
  VOUCHER_VALUE_TYPES,
} from "../index";

type EditModalContentProps = {
  voucher: any;
  closeModal: () => void;
  getVouchers: any;
  isEdit: boolean;
};

const EditModalContent = memo(
  ({ voucher, closeModal, getVouchers, isEdit }: EditModalContentProps) => {
    const { handleChangeValue, editingVoucher, updateVoucher, deleteVoucher } =
      useEditModalContent({ voucher, getVouchers, closeModal, isEdit });

    console.log("asdasd", editingVoucher);
    return (
      <Box sx={style.modal}>
        {editingVoucher && (
          <Grid container spacing={3}>
            <>
              <IconButton sx={style.modal.closeButton} onClick={closeModal}>
                <CloseIcon />
              </IconButton>
              <Grid item xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit voucher
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Nome"
                  value={editingVoucher?.code}
                  onChange={(ev) => handleChangeValue(ev.target.value, "code")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="voucherType-label">Tipo</InputLabel>
                  <Select
                    labelId="select-voucherType"
                    id="select-voucherType"
                    label="Tipo"
                    value={editingVoucher?.type}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "type")
                    }
                  >
                    {VOUCHER_TYPES.map((type: any) => (
                      <MenuItem
                        key={type.value}
                        value={type.value}
                        disabled={type.disabled}
                      >
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Valore"
                  value={editingVoucher?.value}
                  onChange={(ev) => handleChangeValue(ev.target.value, "value")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="voucherValueType-label">
                    Tipo valore
                  </InputLabel>
                  <Select
                    labelId="select-voucherValueType"
                    id="select-voucherValueType"
                    label="value_type"
                    value={editingVoucher?.value_type}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "value_type")
                    }
                  >
                    {VOUCHER_VALUE_TYPES.map((type: any) => (
                      <MenuItem
                        key={type.value}
                        value={type.value}
                        disabled={type.disabled}
                      >
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Minimo d'ordine"
                  value={editingVoucher?.orderTotal}
                  onChange={(ev) =>
                    handleChangeValue(ev.target.value, "orderTotal")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="voucherUsability-label">
                    Tipo di utilizzo
                  </InputLabel>
                  <Select
                    labelId="select-voucherUsability"
                    id="select-voucherUsability"
                    label="usability"
                    value={editingVoucher?.usability}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "usability")
                    }
                  >
                    {VOUCHER_USABILITIES.map((type: any) => (
                      <MenuItem
                        key={type.value}
                        value={type.value}
                        disabled={type.disabled}
                      >
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="voucherCumulabile-label">
                    Cumulabile
                  </InputLabel>
                  <Select
                    labelId="select-voucherCumulabile"
                    id="select-voucherCumulabile"
                    label="usable_with_other_vouchers"
                    value={editingVoucher?.usable_with_other_vouchers?.toString()}
                    onChange={(ev) =>
                      handleChangeValue(
                        ev.target.value === "true",
                        "usable_with_other_vouchers"
                      )
                    }
                  >
                    <MenuItem key="Si" value="true">
                      Si
                    </MenuItem>
                    <MenuItem key="No" value="false">
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="voucherUsersTypology-label">
                    Tipologia utenti
                  </InputLabel>
                  <Select
                    labelId="select-voucherUsersTypology"
                    id="select-voucherUsersTypology"
                    label="users_typology"
                    value={editingVoucher?.users_typology}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "users_typology")
                    }
                  >
                    {VOUCHER_USERS_TYPOLOGIES.map((type: any) => (
                      <MenuItem
                        key={type.value}
                        value={type.value}
                        disabled={type.disabled}
                      >
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="voucherStatus-label">Stato</InputLabel>
                  <Select
                    labelId="select-voucherStatus"
                    id="select-voucherStatus"
                    label="status"
                    value={editingVoucher?.status}
                    onChange={(ev) =>
                      handleChangeValue(ev.target.value, "status")
                    }
                  >
                    {VOUCHER_STATUSES.map((type: any) => (
                      <MenuItem
                        key={type.value}
                        value={type.value}
                        disabled={type.disabled}
                      >
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={updateVoucher}
                >
                  {isEdit ? "Save" : "Create"}
                </Button>
              </Grid>
              <Grid item xs={3}>
                {isEdit && (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={deleteVoucher}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
            </>
          </Grid>
        )}
      </Box>
    );
  }
);

export default EditModalContent;
