import React, { memo } from "react";
import {
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { getFileAddress } from "utils";
import { ORDER_RETURN_STATUSES } from "constants/contants";
import useReturnModalContent from "./index.hooks";
import style from "./index.style";

type ReturnModalContentProps = {
  product: any;
  closeModal: () => void;
  onConfirmReturn: (
    values: any[],
    selectedItems: boolean[],
    shippingEnabled: boolean,
    extraLineEnabled: boolean,
    manualEcreditEnabled: boolean
  ) => void;
};

const ReturnModalContent = memo(
  ({ product, closeModal, onConfirmReturn }: ReturnModalContentProps) => {
    const {
      selectedItems,
      handleSelectItem,
      shippingEnabled,
      handleShippingEnabledCheck,
      values,
      handleChangeValue,
      extraLineEnabled,
      manualEcreditEnabled,
      handleEnableExtraLineCheck,
      handleManualEcreditCheck,
    } = useReturnModalContent({
      product,
    });

    return (
      <Box sx={style.modal}>
        <Box sx={{ marginBottom: "20px" }}>
          {product?.items?.map((item: any, index: number) => (
            <Box key={item?._id} sx={style.itemRow}>
              <Box sx={style.itemRowItem}>
                <Checkbox
                  checked={selectedItems[index]}
                  onChange={() => handleSelectItem(index)}
                />
                <img
                  style={{ height: 80, width: 80 }}
                  src={getFileAddress(item.product.pictures?.[0]?.url)}
                  alt=""
                />
                <Box sx={style.itemRowText}>
                  <Typography>
                    {item?.product?.code} -{" "}
                    {
                      item?.product?.models?.find(
                        (model: any) => model.refId === item?.modelRefId
                      )?.size
                    }
                  </Typography>
                  <Typography>
                    {ORDER_RETURN_STATUSES[item.status]?.label}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography>
                  Prezzo: {item.product.bestTaxable.toFixed(2)}€
                </Typography>
                <Typography>
                  Cashback: {item.ecreditCashback?.toFixed(2)}€
                </Typography>
                <Typography>IVA: {product.order.vatAmount} %</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            Total order cashback: {product.ecreditCashback}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            Vouchers:{" "}
            {product.vouchers?.map((v: any) => `${v.code} : ${v.amount}`)}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shippingEnabled}
                  onChange={handleShippingEnabledCheck}
                />
              }
              label="Shipping fee"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Shipping Fee"
              value={values.shippingCost}
              onChange={(event) =>
                handleChangeValue(event.target.value, "shippingCost")
              }
              fullWidth
              disabled={!shippingEnabled}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={extraLineEnabled}
                  onChange={handleEnableExtraLineCheck}
                />
              }
              label="Enable extra line"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Extra line note"
              value={values.extraLineNote}
              onChange={(event) =>
                handleChangeValue(event.target.value, "extraLineNote")
              }
              fullWidth
              disabled={!extraLineEnabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Extra line amount"
              value={values.extraLineAmount}
              onChange={(event) =>
                handleChangeValue(event.target.value, "extraLineAmount")
              }
              fullWidth
              disabled={!extraLineEnabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={manualEcreditEnabled}
                  onChange={handleManualEcreditCheck}
                />
              }
              label="Sovrascrivi importo ecredit"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Ecredit manuale"
              value={values.manualEcreditValue}
              onChange={(event) =>
                handleChangeValue(event.target.value, "manualEcreditValue")
              }
              fullWidth
              disabled={!manualEcreditEnabled}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              value={values.note}
              fullWidth
              onChange={(event) =>
                handleChangeValue(event.target.value, "note")
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() =>
                onConfirmReturn(
                  values,
                  selectedItems,
                  shippingEnabled,
                  extraLineEnabled,
                  manualEcreditEnabled
                )
              }
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default ReturnModalContent;
