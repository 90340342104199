import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";
import useEditModalContent from "./index.hooks";
import style from "./index.style";
import { TURNKEY_STATUSES } from "../../../constants/contants";

type EditModalContentProps = {
  item: any;
  closeModal: () => void;
  getItems: any;
};

const EditModalContent = memo(
  ({ item, closeModal, getItems }: EditModalContentProps) => {
    const { handleChangeValue, editingItem, updateItem } = useEditModalContent({
      item,
      getItems,
      closeModal,
    });

    return (
      <div>
        {editingItem && (
          <Box sx={style.modal}>
            <Grid container spacing={3}>
              <>
                <IconButton sx={style.modal.closeButton} onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="active-label">Status</InputLabel>
                    <Select
                      labelId="select-active"
                      id="select-active"
                      label="Active"
                      value={editingItem?.status}
                      onChange={(ev) =>
                        handleChangeValue(ev.target.value, "status")
                      }
                    >
                      {TURNKEY_STATUSES.map((value: any) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" size="large" onClick={updateItem}>
                    Save
                  </Button>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
      </div>
    );
  }
);

export default EditModalContent;
